/* REACT */
import React from 'react'
import ShowIf from '../Generic/ShowIf'
import ReactTooltip from 'react-tooltip'
import { formatValue } from '../../helpers/ReportHelpers'
import moment from 'moment'
class WidgetToolTip extends React.Component {
    render() {
        const { index, value, widget, direction } = this.props

        const differencePrecedingPlus = widget.difference > 0 ? "+" : ""
        const first_value = widget.data_points && widget.data_points[widget.data_points.length - 2] ? widget.data_points[widget.data_points.length - 2] : null
        const last_value = widget.last_value
        
        const formattedFirstVal = first_value || first_value === 0 ? formatValue(true, first_value, widget.decimal) : "N/A"
        const formattedLastVal = last_value || last_value === 0 ? formatValue(true, last_value, widget.decimal) : "N/A"
        
        return (
            <ShowIf if={value !== 'N/A'} >
                <ReactTooltip id={`tooltip-${widget.row_id}-${widget.kpi_key}-${index}-${widget.type}`} type='dark' effect='solid' place={"bottom"} className="dashboard-table-cell-info opaque">
                    <p>
                        <i className={`fa fa-long-arrow-${direction === 1 ? 'up' : direction === -1 ? 'down' : ""} margin-right-5px`}></i>
                        Dev. last {moment.unix(widget.last_data_time).diff(moment.unix(widget.first_data_time), "days")} days
                        <i className={`fa fa-long-arrow-${direction === 1 ? 'up' : direction === -1 ? 'down' : ""} margin-left-5px`}></i>
                    </p>
                    <p className="height-10px">
                        <span className="float-left">
                            Last month
                        </span>
                        <span className="float-right">
                            {formattedFirstVal}
                        </span>
                    </p>
                    <p className="height-10px">
                        <span className="float-left">
                            Today
                        </span>
                        <span className="float-right">
                            {formattedLastVal}
                        </span>
                    </p>
                    <hr className="margin-bottom-5px bg-white" />
                    <span className="dashboard-table-cell-info-main-number">
                        {/* <ShowIf if={widget.difference < 0}><span className="fa fa-arrow-down" />&nbsp;</ShowIf>
                        <ShowIf if={widget.difference > 0}><span className="fa fa-arrow-up" />&nbsp;</ShowIf> */}
                        <span className="float-left">
                            <b>{differencePrecedingPlus + formatValue(true, widget.difference_percent, true)}%</b>
                        </span>
                        <span className="float-right">
                            <b>{differencePrecedingPlus + formatValue(true, widget.difference, widget.decimal)}</b>
                        </span>
                    </span>
                </ReactTooltip>
            </ShowIf>
        )
    }
}

export default WidgetToolTip
