import React, { useState, useEffect, useMemo } from "react"

import Select from '../Generic/CustomSelect'
import { canUseChecklists, canUseDashboards, canUseReports } from "../../helpers/PermissionHelpers"

export default function OrganizationAdministrationEditHomePageSetup(props) {

    const [selectedDashboard, setSelectedDashboard] = useState(() => {
        const dashboard = props.user.dashboards?.find((d) => d.id === props.user.home_dashboard)
        return dashboard ? { label: dashboard.title, value: dashboard.id } : null
    })
    const [selectedFavouriteReports, setSelectedFavouriteReports] = useState(props.favReports)
    const reportsOptions = useMemo(
        () =>
            props.user.reports
                ?.filter((i) => selectedFavouriteReports.find((si) => i.report_id === si.value) == null)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map((r) => {
                    return { label: r.name, value: r.report_id }
                }),
        [props.user.reports, selectedFavouriteReports]
    )
    const dashboardOptions = useMemo(
        () =>
            props.user.dashboards
                ?.filter((i) => selectedDashboard?.value !== i.id)
                .map((d) => {
                    return { label: d.title, value: d.id }
                }),
        [props.user.dashboards, selectedDashboard]
    )

    useEffect(() => {
        if (!selectedDashboard && !!props.user.home_dashboard) setSelectedDashboard(props.user.dashboards?.find((d) => d.id === props.user.home_dashboard))
        if (!selectedFavouriteReports && !!props.user.favourite_reports)
            setSelectedFavouriteReports(
                props.user.favourite_reports?.map((fr) => {
                    return props.user.reports.find((r) => r.report_id === fr.report_id)
                })
            )
    }, [])

    useEffect(() => {
        props.homepageSetupChangeHandler(selectedDashboard, "dashboard")
    }, [selectedDashboard])

    useEffect(() => {
        props.homepageSetupChangeHandler(selectedFavouriteReports, "favouriteReports")
    }, [selectedFavouriteReports])

    function changeFavouriteReportHandler(e) {
        const fr = [...selectedFavouriteReports]
        fr.push(e)
        setSelectedFavouriteReports(fr)
    }

    function removeFavouriteReportHandler(value) {
        const fr = [...selectedFavouriteReports].filter((fr) => fr.value != value.value)
        setSelectedFavouriteReports(fr)
    }

    function changeDashboardHandler(value) {
        setSelectedDashboard(value)
    }
    function removeDashboardHandler(value) {
        setSelectedDashboard(null)
    }

    return (
        <div>
            <section className="mb-3 card">
                <div className="card-body p-3 d-flex flex-column">
                    <div className="">
                        <Select className="basic-single mb-2 " classNamePrefix="select" isSearchable={true} name="dashboards" isDisabled={!canUseDashboards(props.user.permissions)} value={null} options={dashboardOptions} onChange={changeDashboardHandler} placeholder="Home Dashboard" />
                    </div>

                    <div className="flex-fill mw-50">
                        {!canUseDashboards(props.user.permissions) ? (
                            <div className="text-center">
                                <i>The user does not have permission to view dashboards.</i>{" "}
                            </div>
                        ) : !!selectedDashboard ? (
                            <h4 className="font-weight-normal h5 d-flex mt-1 mb-0 p-0 align-items-center position-relative">
                                <span className="badge bg-light fw-normal border p-0  glyphicon-info-gray bottom-0 font-weight-medium p-1 px-3 m-0 d-flex align-items-center ">
                                    <i className="fa fa-tachometer mr-2"></i>
                                    <span>{selectedDashboard?.label} </span>
                                    <button onClick={() => removeDashboardHandler(selectedDashboard)} className=" btn btn-text p-0 m-0 ml-3 lh-0 ">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </span>
                            </h4>
                        ) : (
                            <div className="text-center">
                                <i>No Dashboard selected</i>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <section className="card p-3 d-flex flex-column mb-3">
                <div className="">
                    <Select className="basic-single mb-1" classNamePrefix="select" isSearchable={true} name="reports" value={null} options={reportsOptions} isDisabled={!canUseReports(props.user.permissions)} onChange={changeFavouriteReportHandler} placeholder="Favourite Insights" />
                </div>

                {!canUseReports(props.user.permissions) ? (
                    <div className="text-center">
                        <i>The user does not have permission to view Insights.</i>{" "}
                    </div>
                ) : selectedFavouriteReports?.length > 0 ? (
                    <section className="">
                        <ul className="list-group list-group-flush p-0 m-0">
                            {selectedFavouriteReports.map((r) => {
                                return (
                                    <li className="list-group-item border-0 p-0 mt-1" key={r.value}>
                                        <h4 className="font-weight-normal h5 d-flex align-items-center position-relative m-0 mt-1">
                                            <span className="badge bg-light fw-normal border glyphicon-info-gray  bottom-0 font-weight-medium p-1 px-3 d-flex align-items-center ">
                                                <i className="fa fa-file mr-2 "></i>
                                                <span>{r.label} </span>
                                                <button onClick={() => removeFavouriteReportHandler(r)} className=" btn btn-text p-0 m-0 ml-3 lh-0 ">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </span>
                                        </h4>
                                    </li>
                                )
                            })}
                        </ul>
                    </section>
                ) : (
                    <div className="text-center">
                        <i>No Favourite Reports selected</i>
                    </div>
                )}
            </section>
            {props.mode === "edit" && (
                <>
                    <section className="mb-3 py-1 card">
                        <span style={{color: "hsl(0,0%,50%)", marginLeft:"23px"}}>Assigned Action lists</span>
                        {!canUseChecklists(props.user.permissions) ? (
                            <div className="text-center">
                                <i>The user does not have permission to view Action lists.</i>{" "}
                            </div>
                        ) : props.user.checklists?.length > 0 ? (
                            <ul className="list-group list-group-flush mb-1 ml-2" style={{borderTop:"none"}}>
                                {props.user.checklists.map((c) => {
                                    return (
                                        <li key={c.id} className="list-group-item border-0 pl-0 pt-2 pb-0 my-0">
                                            <h4 className="font-weight-normal h5 d-flex align-items-center position-relative p-0 m-0">
                                                <span className="badge  fw-normal   bottom-0 text-muted font-weight-medium p-1 px-3 d-flex align-items-center ">
                                                    <i className="fa fa-tasks mr-2 text-muted"></i>
                                                    <span>{c.name} </span>
                                                </span>
                                            </h4>
                                        </li>
                                    )
                                })}
                            </ul>
                        ) : (
                            <div className="text-center">
                                <i>No Action lists assigned</i>
                            </div>
                        )}
                    </section>
                </>
            )}
        </div>
    )
}
