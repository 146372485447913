import React from 'react'
import ShowIf from '../Generic/ShowIf'

const GenericDialog = (
    props: {
        show: boolean,
        children: React.ReactNode,
        title: string,
        onClose: () => void,
        getButtons?: () => JSX.Element,
        className?: string,
        style?: React.CSSProperties,
        disableBackdropClick?: boolean
    }
) => {
    let disableBackdropClick = props.disableBackdropClick ?? true

    let onBackdropClose = () => {
        if (!disableBackdropClick) {
            props.onClose()
        }
    }

    return (
        <ShowIf if={props.show}>
            <div className="generic-modal-backdrop" onClick={onBackdropClose}>
                <div className={`generic-modal-popup ${props.className ?? ''}`} style={props.style ?? {}} onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header sticky-modal-header" >
                        <h4 className="modal-title">{props.title}</h4>
                        <button type="button" className="close" onClick={props.onClose} ><span >&times;</span></button>
                    </div>
                    <div className="modal-body" style={{ height: "100%", overflow: "initial" }}>
                        {props.children}
                    </div>
                    <div className="modal-footer sticky-modal-footer">
                        {props.getButtons != null ?
                            props.getButtons()
                            :
                            <div>
                                <button onClick={props.onClose} type="button" className="btn btn-default margin-right-10px" >OK</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ShowIf>
    )
}

export default GenericDialog
