import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Trend from '../components/Report/Trend'
import { apiGet } from '../helpers/ApiHelpers'
import { SingleKeyMap } from "../helpers/Collections"

const TrendViewSecret = () => {
    const { secret, rId, orgId, sHash} = useParams()
    const [data, setData] = useState([])

    let trend = React.createRef()

    let getWidthForPlaywright = () => {
        return 1600 //fixed @ 1600
    };

    useEffect(() => {
        apiGet(`singletrendview/${rId}/${orgId}/${secret}/${sHash}`, data => {
            setData(data)
        }, error => console.log(error))


    }, [orgId, rId, sHash, secret])
    if (!secret || !rId || !sHash) return null;
    if (data.length === 0) return null;

    let historydata = new SingleKeyMap()
    historydata.set(data.report_data.info.id, data.history_data)

    const viewSettings = JSON.parse(data.view_settings.settings)

    const pdfDate = new Date().toLocaleString('da-DK').split(' ')[0].replaceAll('.', '/')

    return <div className="mt-2">
        <div style={{ display: "none" }} id={`playwrightWidth`}>{getWidthForPlaywright()}</div>
                
        <div style={{ opacity: "50%"}}>
            <div className='pt-2' style={{ float:"right", marginRight:"50px"}}>
                <img src="./img/inact_now_logo_black.svg" alt="Inact Now" style={{height: "25px"}} />
            </div>

            <div style={{ float:"left", marginLeft:"50px" }} >
                <div id="dashboardTitle" style={{ fontSize: 25 }}>{data.report_data.report.name}</div>
                <div className=''style={{fontSize:"smaller"}}><span className=' text-muted'></span> {pdfDate}</div>
            </div>
            <div style={{ clear: "both"}}></div>
        </div>
        
        <div className='mb-4' style={{ borderTop: "1px solid rgb(229 231 235)", marginLeft: 50, marginRight:50 }}>
            <Trend
                    show={true}
                    enabled={true}
                    wrappedComponentRef={trend}
                    settingsParent={this}
                    section={"trend"}
                    reportdata={data.report_data}
                    slicers={[]}
                    clickReport={null}
                    addSlicer={null}
                    changeView={null}
                    removeSlicer={null}
                    editMode={null}
                    setData={null}
                    hideRow={null}
                    clickableCategories={null}
                    setSelectedItems={null}
                    selectedItemsLength={null}
                    historydata={historydata}
                    viewSettings={viewSettings.report.children.content.children.trend}
                    forPrint={true}
                />


            </div>

    </div>
}

export default TrendViewSecret
