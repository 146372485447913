import React from 'react'
import { connect } from 'react-redux'
import * as ActionTypes from '../actions/ActionTypes'
import { packAction } from '../actions/ActionTypes'
import { getUsers } from '../actions/SystemActions'
import { getFullName } from '../helpers/UserHelpers'
import { fromNow, formatDate, formatDateTime, ItemTooOld } from '../helpers/TimeHelpers'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'
import { canUseChecklists, is, isChecklistAdmin } from '../helpers/PermissionHelpers'
import SaveDialog from '../components/Dialogs/SaveDialog'
import SaveDeleteDialog from '../components/Dialogs/SaveDeleteDialog'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ChecklistPanel from '../components/SingleChecklist/ChecklistPanel'
import EditChecklistLayoutDialog from '../components/Dialogs/EditChecklistLayoutDialog'
import ShowIf from '../components/Generic/ShowIf'
import HideIf from '../components/Generic/HideIf'
import UserOptionDialog from '../components/Dialogs/UserOptionDialog'
import TextInputDialog from '../components/Dialogs/TextInputDialog'
import TextareaDialog from '../components/Dialogs/TextareaDialog'
import DeleteChecklistDialog from '../components/Dialogs/DeleteChecklistDialog'
import AssignUserDialog from '../components/Dialogs/AssignUserDialog'
import ScrollTopSticker from '../components/ScrollTopSticker'
import DatePicker from 'react-datepicker'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { checklistColumnToDataGridColumn, checklistModelColumnToDataGridColumn, intColTypeToStringColType } from '../helpers/ChecklistHelpers'
import DataGrid from '../components/DataGrid'
import ColumnSelectionDialog from '../components/Dialogs/ColumnSelectionDialog'
import DataColumnSelectionDialog from '../components/Dialogs/DataColumnSelectionDialog'
import { copyToClipboard } from '../helpers/GeneralHelpers'
import { notifySuccess } from '../helpers/NotificationManager'
import { withSettingsStorage, withSettingsPropagation } from '../helpers/SettingsService'
import RequirePermission from '../components/RequirePermission'
import { getAndRemoveSetting } from '../helpers/SingleSettingService'
import { duplicateChecklist, getChecklistCategoryItems, reactivateChecklist, resetChecklistCategoryItems, updateChecklistItems, downloadChecklistData } from '../actions/ChecklistActions'
import WarningDescriptionDialog from '../components/Dialogs/WarningDescriptionDialog'
import { getDataDescriptionDialogDescription} from '../helpers/ReportHelpers'
import LayoutColorPicker from '../components/LayoutColorPicker'
import NewLayoutCategorySlider from '../components/Animation/NewLayoutCategorySlider'
import { getBucketInfo, getBucketUsers } from '../actions/BucketActions'
import { followItem } from '../actions/UserActions'
import LoadingAnimation from '../components/LoadingAnimation'
import EditChecklistDialog from '../components/Dialogs/EditChecklistDialog'

/*CSS*/
import '../css/singleChecklist.css'
import 'react-datepicker/dist/react-datepicker.css'
import InfiniteScrollOnScreen from '../components/Generic/InfiniteScrollOnScreen'
import DataDescriptionDialog2 from '../components/Dialogs/DataDescriptionDialog2'
import { compareObjects } from '../helpers/TypeHelpers'

const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.info,
        userInfo: state.User.info,
        users: state.System.users,
        checklists: state.Checklist.checklists,
        items: state.Checklist.items,
        itemsList: state.Checklist.itemsList,
        totals: state.Checklist.totals,
        checklistExists: state.Checklist.checklistExists,
        checklistAccessible: state.Checklist.checklistAccessible,
        comments: state.Item.comments,
        pastedChecklistComments: state.Checklist.pastedChecklistComments,
        layouts: state.Checklist.layouts,
        buckets: state.Bucket.buckets,
        bucketUsers: state.Bucket.bucketUsers,
        followings: state.User.followings,

        slicerValues: state.Report.slicerValues,
        slicerAvailableValues: state.Report.slicerAvailableValues,
    }
}

class SingleChecklist extends React.Component {
    constructor(props) {
        super()
        this.state = this.getDefaultState(props, this.getChecklistId())

        this.addSlicerRef = React.createRef()
        this.warnRemoveSlicerRef = React.createRef()
        this.downloadDialogExcel = React.createRef()
        this.downloadDialog = React.createRef()
    }

    getDefaultState(props) {
        return {
            checklist: {},
            selectedItems: [],
            previousSelectedItems: [],
            selectedStatus: [],
            deleteButtonClasses: this.deleteButtonClassBase,
            shownColumns: [],
            showDecimals: false,
            createdFromReport: parseInt(getAndRemoveSetting("createdFromReport", -1), 10),
            wrapHeaderText: true,
            addCategory: false,
            tmpLayoutName: "",
            tmpLayoutColor: "btn-primary",
            loading: false,
            offsets: [],
            sortIndex: 0,
            sortDirection: "desc",

            showEditChecklist: false,
            showDataDescription: false
        }
    }

    getChecklistId() {
        return this.props?.match?.params?.checklistID ? parseInt(this.props.match.params.checklistID) : -1
    }

    checklist() {
        return this.props.checklists ? this.props.checklists.get(this.getChecklistId()) : null
    }

    componentDidMount() {
        let checklistID = this.props.match.params.checklistID

        this.setState({
            checklistID: checklistID,
        })

        this.props.dispatch(packAction(ActionTypes.GET_CHECKLIST, checklistID))
        this.props.dispatch(packAction(ActionTypes.GET_LAYOUTS))
        if(!this.props.users)
            this.props.dispatch(getUsers())
    }

    checklistHolder = {}

    componentDidUpdate(prevProps, prevState) {
        let checklistID = this.props.match.params.checklistID
        let prevChecklistID = prevProps.match.params.checklistID
        if (checklistID !== prevChecklistID) {
            this.setState(this.getDefaultState(this.props))
            this.props.dispatch(packAction(ActionTypes.GET_CHECKLIST, checklistID))
        }

        const checklist = this.checklist()

        //If shown columns is defined, set state to those, otherwise choose the 8 first columns
        let shownColumns = []
        if (checklist && checklist !== this.checklistHolder && this.state.shownColumns.length === 0) {
            if (checklist.bucket) {
                this.props.dispatch(getBucketInfo(checklist.bucket))
            }

            if (this.state.shownColumns.length === 0) {
                let dataColumns = JSON.parse(checklist.data_columns)
                try {
                    let columns = JSON.parse(checklist.shown_columns)
                    if (columns && Array.isArray(columns)) {
                        shownColumns = columns.map(c => dataColumns.find(dc => dc.Name === c))
                    } else {
                        shownColumns = dataColumns.slice(0, 8)
                    }
                } catch (e) {
                    shownColumns = dataColumns.slice(0, 8)
                }
                this.setState({ shownColumns: checklistColumnToDataGridColumn(shownColumns, checklist) })
            }
        }

        if (checklist && checklist !== this.checklistHolder && checklist.bucket){
            if(!this.props.bucketUsers.contains(checklist.bucket))
                this.props.dispatch(getBucketUsers(checklist.bucket))
        }

        if (checklist && !compareObjects(checklist, this.checklistHolder)){
            let data = JSON.parse(checklist.data_columns)
            let index = data.findIndex((e) => e.Name == checklist.sort_column)
            this.setState({sortIndex: index, sortDirection: checklist.sort_direction}, () => {
                this.getAllItems()
            })

            let layout = JSON.parse(checklist.layout)
            let offsets = layout.map(l => {
                return 0
            })
            let slicers = checklist.filter ? JSON.parse(checklist.filter).filter : []
            this.setState({tmpChecklistName: checklist.name, tmpChecklistLimit: checklist.limit, tmpChecklistSortDirection: checklist.sort_direction, tmpChecklistSortColumn: checklist.sort_column, offsets: offsets, tmpChecklistHasLimit: checklist.limit != -1, slicers: slicers})
        }

        this.checklistHolder = checklist
        
        if(prevProps.checklists != undefined && this.props.checklists != undefined){
            let doneLoading = JSON.stringify(prevProps.checklists.get(this.getChecklistId())) != JSON.stringify(this.props.checklists.get(this.getChecklistId()))
            if (doneLoading && this.state.loading) setTimeout(() => this.setState({loading: false}), 500) 
        }
    }

    //Get a max of 100 items from every category
    getAllItems = (reset = false) => {
        let checklist = this.checklist()
        let layout = JSON.parse(checklist.layout)
        let sortIndex = this.state.sortIndex
        let sortDirection = this.state.sortDirection
        let offsets = this.state.offsets
        if(reset){
            offsets = layout.map(l => {
                return 0
            })
        }
        this.setState({offsets: offsets}, () => {
            for(let i = 0; i < layout.length; i++){
                this.props.dispatch(getChecklistCategoryItems(checklist.id, 0, 100, i, sortIndex, sortDirection, reset))
            }
        })
    }

    toggleShowDecimals() {
        this.setState({ showDecimals: !this.state.showDecimals })
    }

    onItemSelect = (items, index) => {
        let selectedItems = this.state.selectedItems
        selectedItems[index] = items
        this.setState({ selectedItems: selectedItems })
        if (this.getSelectedItems().length === 1) {
            this.setState({ previousSelectedItems: [] })
        }
    }

    getSelectedItems = () => {
        let filteredItemArrays = this.state.selectedItems.filter(array => Array.isArray(array)) //filtering out empty/undefined array indexes
        return Array.prototype.concat.apply([], filteredItemArrays).filter(i => i.id !== "sum-row") //flattening array and filtering out sum row
    }

    getPreviousSelectedItems() {
        let filteredItemArrays = this.state.previousSelectedItems.filter(array => Array.isArray(array)) //filtering out empty/undefined array indexes
        return Array.prototype.concat.apply([], filteredItemArrays).filter(i => i.id !== "sum-row") //flattening array and filtering out sum row
    }

    isPastedChecklist = () => {
        return this.checklist().bucket === null || this.checklist().bucket === "" || this.checklist().bucket === undefined
    }

    deselectAll() {
        this.setState({ previousSelectedItems: this.state.selectedItems.slice() })
        this.refs.panels.deselectAll()
    }

    reselectPrevious() {
        this.refs.panels.reselect(this.state.previousSelectedItems.slice())
        this.setState({ previousSelectedItems: [] })
    }

    getBucket() {
        if (this.props.checklists && this.checklist()) {
            return this.checklist().bucket
        } else {
            return undefined
        }
    }

    hasComment = (item) => {
        if (this.isPastedChecklist()) {
            return this.props.pastedChecklistComments && this.props.pastedChecklistComments.get(item.id, undefined) && this.props.pastedChecklistComments.get(item.id, undefined).length > 0
        } else {
            return this.props.comments && this.props.comments.get(this.getBucket(), item.key, undefined) && this.props.comments.get(this.getBucket(), item.key, undefined).length > 0
        }
    }

    getLatestComment = (item) => {
        if (this.hasComment(item)) {
            if (this.isPastedChecklist()) {
                let comments = this.props.pastedChecklistComments.get(item.id, [])
                return comments && comments.length > 0 ? {comment: comments[0].comment, count: comments.length} : null
            } else {
                let comments = this.props.comments.get(this.getBucket(), item.key, undefined)
                return comments && comments.length > 0 ? {comment: comments[0].comment, count: comments.length} : null
            }
        } else {
            return null
        }
    }

    changeStatus(statusId) {
        // brug herfra!
        let idArray = this.getSelectedItems().map(item => item.id)
        this.props.dispatch(packAction(ActionTypes.CHANGE_STATUS, { items: idArray, status: statusId, skip_layout: false, checklist_id: this.getChecklistId(), cb: () => {
            this.resetItems()
            this.getAllItems()
        } }))
        this.deselectAll()
        this.setState({ previousSelectedItems: [] })
    }

    resetItems() {
        let checklist = this.checklist()
        let layout = JSON.parse(checklist.layout)
        let offsets = layout.map(l => {
            return 0
        })
        this.setState({offsets: offsets})
        this.props.dispatch(resetChecklistCategoryItems())
    }

    deleteItems() {
        let idArray = this.getSelectedItems().map(item => item.id)
        this.props.dispatch(packAction(ActionTypes.DELETE_CHECKLIST_ITEMS, { item_ids: idArray, checklist_id: this.getChecklistId() }))
        this.deselectAll()
        this.setState({ previousSelectedItems: [] })
    }

    deleteButtonClassBase = "btn btn-sm btn-default margin-right-10px"
    deleteButtonExtraClass = 'btn-danger'

    endangerDeleteButton() {
        if (this.getSelectedItems().length !== 0) this.setState({ deleteButtonClasses: this.deleteButtonClassBase + " " + this.deleteButtonExtraClass })
    }

    normaliseDeleteButton() {
        this.setState({ deleteButtonClasses: this.deleteButtonClassBase })
    }

    openEditNameDialog() {
        this.refs.changeNameDialog.show(this.checklist().name)
    }

    openEditChecklistDialog = () => {
        this.setState({showEditChecklist: true})
    }

    openEditDescriptionDialog() {
        this.refs.changeDescriptionDialog.show(this.checklist().description)
    }

    saveChecklistName(name) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { name: name, id: this.getChecklistId() }))
    }

    saveDescription(desc) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { description: desc, id: this.getChecklistId() }))
    }

    openDeleteDialog() {
        this.refs.deleteChecklistDialog.show()
    }

    deleteChecklist() {
        this.props.dispatch(packAction(ActionTypes.DELETE_CHECKLIST, { id: this.getChecklistId() }))
        window.location.hash = "/"
    }

    openAssignDialog() {
        this.refs.assigneeDialog.show(this.checklist().assignee)
    }

    openAuthorDialog() {
        this.refs.authorDialog.show(this.checklist().user_id)
    }

    openArchiveDialog() {
        this.refs.archiveChecklistDialog.show()
    }

    openUnarchiveDialog() {
        this.refs.unarchiveChecklistDialog.show()
    }

    openDeadlineDialog() {
        this.refs.deadlineDialog.show(moment(this.checklist().deadline))
    }

    openLayoutDialog() {
        this.refs.layoutDialog.show(JSON.parse(this.checklist().layout))
    }

    openReactivateDialog() {
        this.refs.reactivateDialog.show()
    }

    openCompleteDialog() {
        this.refs.completeDialog.show()
    }

    completeChecklist(message) {
        this.props.dispatch(packAction(ActionTypes.COMPLETE_CHECKLIST, { message: message ? message : '', id: this.getChecklistId() }))
    }

    reactivateChecklist() {
        this.props.dispatch(reactivateChecklist(this.getChecklistId()))
    }

    saveDeadline(deadline) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { has_deadline: true, deadline: moment(deadline).format('YYYY-MM-DD'), id: this.getChecklistId() }))
    }

    removeDeadline() {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { has_deadline: false, deadline: '', id: this.getChecklistId() }))
    }

    saveChecklistAssignee(userId, message, useDeadline, deadline) {
        let data = { assignee: parseInt(userId, 10), id: this.getChecklistId() }

        data.message = message
        data.description = message

        if (useDeadline) {
            data.has_deadline = true
            data.deadline = moment(deadline).format('YYYY-MM-DD')
        } 
        else {
            data.has_deadline = false
        }

        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, data))
    }

    saveChecklistAuthor(userId) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { user_id: parseInt(userId, 10), id: this.getChecklistId() }))
    }

    saveShownColumns = (sc) => {
        this.setState({ shownColumns: sc })
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { shown_columns: JSON.stringify(sc.map(c => c.name)), id: this.getChecklistId() }))
    }

    saveDataColumns = (sc) => {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { data_columns: JSON.stringify(sc.map(c => c.name)), id: this.getChecklistId() }, () => this.getAllItems(true)))
    }

    saveLayout(layout, deletedIndexes, hasOrderChanged, layoutChanged) {
        let checklist = this.checklist()
        let changedStatuses = []
        const currentLayout = JSON.parse(this.checklist().layout)

        if(layoutChanged) {
            for(let i = 1; i < currentLayout.length; i++) {
                const idArray = Object.values(this.props.itemsList.get2(checklist.id, i).data).map(item => item.id)
                changedStatuses.push(JSON.stringify({ items: idArray, status: 0, skip_layout: true, checklist_id: this.getChecklistId() })) 
            }
        } else if (hasOrderChanged) { // if drag n drop has been used
            currentLayout.forEach((cl, i) => {
                const newIndex = layout.findIndex(l => l.name === cl.name) // finding the new index of the category if moved
                if (i !== newIndex) { // if category has been moved
                    const idArray = Object.values(this.props.itemsList.get2(checklist.id, i).data).map(item => item.id)

                    if(idArray.length > 0){
                        // moving all items from the old index to the new index aka "status". Status and index in the array are the same.
                       changedStatuses.push(JSON.stringify({ items: idArray, status: newIndex, skip_layout: true, checklist_id: this.getChecklistId() })) 
                    }
                }
            })
        }
        this.setState({loading: true})
        this.props.dispatch(packAction(ActionTypes.SAVE_CHECKLIST_LAYOUT, { layout: JSON.stringify(layout), deleted_indexes: deletedIndexes, id: this.getChecklistId(), changed_statuses: changedStatuses, cb: () => {
            this.resetItems()
            this.getAllItems()
        } }))
    }

    hideDeleteButtonPredicate(i, l) {
        let layout = JSON.parse(this.checklist().layout)
        return l[i] && layout[i] && i === 0
    }

    archiveChecklist() {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { archived: true, id: this.getChecklistId() }))
    }

    unarchiveChecklist() {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { archived: false, id: this.getChecklistId() }))
    }

    disableAutomaticUpdate() {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { automatic_update: false, id: this.getChecklistId() }))
    }

    enableAutomaticUpdate() {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, { automatic_update: true, id: this.getChecklistId() }))
    }

    openColumnSelection() {
        let descriptions = []
        if(this.checklist().bucket){
            const bucket = this.props.buckets.get(this.checklist().bucket, null)
            if(bucket){
                descriptions = bucket.setup.descriptions
            }
        }
        this.refs.columnSelect.show(this.state.shownColumns, checklistColumnToDataGridColumn(JSON.parse(this.checklist().data_columns), this.checklist()), descriptions)
    }

    openDataColumnSelection() {
        if (this.props.buckets?.get(this.checklist().bucket) !== undefined){
            let allColumns = this.props.buckets?.get(this.checklist().bucket).model.columns

            this.refs.dataColumnSelect.show(
                 checklistColumnToDataGridColumn(JSON.parse(this.checklist().data_columns), this.checklist()),
                 checklistModelColumnToDataGridColumn(allColumns)
             )
        }
    }

    getAssignUsers() {
        if(this.checklist() && this.checklist().bucket && this.props.bucketUsers.contains(this.checklist().bucket)) {
            return this.props.bucketUsers.get(this.checklist().bucket)
        }
        else {
            let res = []
            this.props.users.forEach((user) => {
                if (!user.deleted) {
                    res.push(user)
                }
            })
            return res
        }
    }

    addLayoutCategory = () => {
        if (this.state.addCategory) {
            this.cancelLayoutCategory()
        } else {
            this.setState({ addCategory: true })
        }
    }

    cancelLayoutCategory = () => {
        this.setState({ addCategory: false, tmpLayoutColor: "btn-primary", tmpLayoutName: "" })
    }

    saveLayoutCategory = () => {
        const layout = JSON.parse(this.checklist().layout)
        layout.push({ name: this.state.tmpLayoutName, color: this.state.tmpLayoutColor, donecount: true })
        this.saveLayout(layout, [])
        this.cancelLayoutCategory()
    }

    followChecklist = follow => () => {
        this.props.dispatch(followItem(this.getChecklistId(), "checklist", follow))
    }

    fetchData = (category, limit = 100) => {
        let tmpOffsets = this.state.offsets
        tmpOffsets[category] += 100
        let sortIndex = this.state.sortIndex
        let sortDirection = this.state.sortDirection
        this.props.dispatch(getChecklistCategoryItems(this.checklist().id, tmpOffsets[category], limit, category, sortIndex, sortDirection))
        this.setState({offsets: tmpOffsets})
    }

    getFilteredItems(category){
        let checklist = this.checklist()
        return Object.values(this.props.itemsList.get2(checklist.id, category).data)
    }

    onSort = (index, direction) => {
        this.setState({sortIndex: index, sortDirection: direction}, () => {
            let checklist = this.checklist()
            if (checklist.limit == -1)
                this.getAllItems(true)
        })
    }

    duplicateChecklist() {
        let checklist = this.checklist()
        this.props.dispatch(duplicateChecklist(checklist.id))
    }

    onEditClose = () => {
        this.setState({showEditChecklist: false})
    }

    downloadChecklistDataCommon(type) {
        const { dispatch } = this.props;
        const actionData = Object.values(this.props.checklists.data)[0];
        const selectedItems = this.getSelectedItems();
        
        let slicer;
        //if any rows are selected, uses this slicer to filter query
        if(selectedItems.length !== 0){
            const columns = JSON.parse(actionData.data_columns)
            //columntype 2 seems to correspond to id columns
            const idColumn = columns.find(c => c.ColumnType == 2)
            slicer = {
                column: idColumn.Name, 
                target_values: selectedItems.map(i => i.key),
                //1 is compare type Equal
                compare_type: 1
            }
        }

        const query = JSON.parse(actionData.filter)
        query.column_filter = JSON.parse(actionData.shown_columns);
        query.filter = [slicer];

        const data = {
            name: actionData.name,
            type: type,
            query: query,
            include_comments: true
        }

        dispatch(downloadChecklistData(actionData.id, data))
    }

    downloadChecklistDataExcel = () => {
        this.downloadChecklistDataCommon('excel')
    }

    downloadChecklistData = () => {
        this.downloadChecklistDataCommon('csv')
    }

    promptDownloadChecklistData = () => {
        this.downloadDialog.current.show()
    }

    promptDownloadChecklistDataExcel = () => {
        this.downloadDialogExcel.current.show()
    }

    render() {
        if (!this.props.checklistExists) return (
            <div className="container-fluid">
                <div className="alert alert-success margin-top-10px fix-link-success">
                    <b>The Action list is no longer available.</b> Most likely someone has deleted it, go to Home to see if you have any other Action lists. <Link to="/"><i>Go to Home</i></Link>.
                </div>
            </div>
        )
        if (!this.props.checklistAccessible) return (
            <div className="container-fluid">
                <div className="alert alert-success margin-top-10px fix-link">
                    <b>Sorry, you do not have permission to access this Action list.</b> Continue to <Link to="/"><i>Go to Home</i></Link> to see all your Action lists.
                </div>
            </div>
        )

        if (!this.props.checklists || !this.props.users || !this.props.items) return null
        let checklist = this.checklist()
        if (!checklist) return null

        setPageTitle(checklist.name)

        let checklistFilter = checklist.filter ? JSON.parse(checklist.filter).filter : []
        const columns = JSON.parse(this.checklist().data_columns).map(c => c.Name)
        let selectedItemsCount = this.getSelectedItems().length
        let layout = JSON.parse(checklist.layout)
        let filteredItems = []
        layout.forEach((l, i) => {
            filteredItems.push(this.getFilteredItems(i))
        })

        //fix for checklists without "donecount"
        for(let i = 0; i < layout.length; i++){
            if(layout[i].donecount === undefined){
                layout[i].donecount = i > 0
            }
        }

        let checklistCanBeUpdated = (this.props.userInfo.user_id === this.checklist().user_id || this.props.userInfo.user_id === this.checklist().assignee) && this.checklist().limit && this.checklist().bucket && !this.checklist().not_updatable
        checklistCanBeUpdated = checklistCanBeUpdated || isChecklistAdmin(this.props.userInfo.access ? this.props.userInfo.access : [])


        let undoneCount = 0
        for (var key in layout) {
            let count = this.props.totals.get1(checklist.id).get(key)
            if(count === undefined)
                count = 0
            if(!layout[key].donecount)
                undoneCount += count
        }

        // const followsChecklist = !!this.props.followings.find(f => f.item_type === "checklist" && f.item_id === checklist.id)

        const bucket = this.props.buckets.get(this.checklist().bucket, null)
        let notDoneLayout = layout.filter(l => !l.donecount)
        let doneLayout = layout.filter(l => l.donecount)

        const totalItemCount = layout
            .map((_, i) => this.props.totals.get(checklist.id, i))
            .reduce((prev, acc) => acc + prev,0);  

        return (
            <div className="container-fluid padding-top-15px">
                <ShowIf if={this.props.userInfo.user_id !== this.checklist().assignee && !this.checklist().archived}>
                    <div className="alert alert-warning">
                        Beware, this is not your list! It belongs to {getFullName(this.props.users, this.checklist().assignee)}. You&#39;re logged in as an Admin, that&#39;s
                        why you can see it.
                    </div>
                </ShowIf>
                <ShowIf if={this.checklist().archived}>
                    <div className="alert grey-striped-background ">
                        This list has been archived! Click here to unarchive <button onClick={() => this.openUnarchiveDialog()}
                            className="btn btn-default btn-xs">Restore</button>
                    </div>
                </ShowIf>
                <ShowIf if={this.checklist().completed}>
                    <div className="alert alert-success">
                        <div className="center">
                            <img alt="Checklist complete" src="./img/done.svg" />
                            <p className="align-center">Action list has been completed</p>
                        </div>
                    </div>
                </ShowIf>

                <div>
                    <HideIf if={this.checklist().archived}>
                        <div className="float-right margin-top-10px">
                            <RequirePermission perms={is.reportUser}>
                                <ShowIf if={this.state.createdFromReport > -1}>
                                    <Link to={"/report/" + this.state.createdFromReport}>
                                        <button className="btn btn-default btn-sm margin-right-10px">
                                            <i className="fa fa-arrow-left"></i> Back to Insight
                                        </button>
                                    </Link>
                                </ShowIf>
                            </RequirePermission>

                            <HideIf if={this.checklist().completed || checklist.assignee === checklist.user_id || this.checklist().automatic_update}>
                                <button type="button" className="btn btn-sm btn-default checklist-completed-button margin-right-5"
                                    onClick={() => this.openCompleteDialog()}>
                                    &nbsp;&nbsp;&nbsp;Complete Action list &nbsp;&nbsp;<span className="fa fa-hand-o-right"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                </button>
                            </HideIf>

                            <ShowIf if={this.checklist().completed}>
                                <button type="button" className="btn btn-sm btn-default margin-right-5 inline-block" onClick={() => this.openReactivateDialog()}>
                                    &nbsp;&nbsp;&nbsp;Reactivate Action list &nbsp;&nbsp;&nbsp;
                                </button>
                            </ShowIf>

                            <RequirePermission perms={is.checklistsCreator}>
                                <div className="btn-group inline-block ">
                                    <button type="button" className="btn btn-sm btn-default dropdown-toggle" data-toggle="dropdown">
                                        <span className="fa fa-cog" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right" role="menu">
                                        <ShowIf if={this.checklist().bucket}>
                                            <li className="dropdown-item abc-clickable" onClick={() => this.openEditChecklistDialog()} title="Edit Action list">Edit Action list</li>
                                        </ShowIf>
                                        <li className="dropdown-item abc-clickable" onClick={() => this.openDeadlineDialog()} title="Edit deadline">Edit deadline</li>
                                        <li className="dropdown-item abc-clickable" onClick={() => this.openEditDescriptionDialog()} title="Edit description">Edit description</li>
                                        <li className="dropdown-item abc-clickable" onClick={() => this.openAssignDialog()} title="Assign">Assign</li>
                                        <li className="dropdown-item abc-clickable" onClick={() => this.openAuthorDialog()} title="Change author">Change author</li>
                                        <div className="dropdown-divider"></div>
                                        <li className="dropdown-item abc-clickable no-padding"><Link to={`/split/${this.getChecklistId()}`} className="single-checklist-split-edit abc-clickable cancel-a-style" title="Split list">Split list</Link></li>
                                        <ShowIf if={checklistCanBeUpdated && this.checklist().automatic_update}>
                                            <li className="dropdown-item abc-clickable" onClick={() => this.disableAutomaticUpdate()} title="Disable automatic update">Disable automatic update</li>
                                        </ShowIf>
                                        <ShowIf if={checklistCanBeUpdated && !this.checklist().automatic_update}>
                                            <li className="dropdown-item abc-clickable" onClick={() => this.enableAutomaticUpdate()} title="Enable automatic update">Enable automatic update</li>
                                        </ShowIf>
                                        {/* <ShowIf if={!followsChecklist && checklistCanBeUpdated}>
                                            <li className="dropdown-item abc-clickable" onClick={this.followChecklist(true)}>Follow</li>
                                        </ShowIf>
                                        <ShowIf if={followsChecklist && checklistCanBeUpdated}>
                                            <li className="dropdown-item abc-clickable" onClick={this.followChecklist(false)}>Unfollow</li>
                                        </ShowIf> */}
                                        <ShowIf if={this.checklist().limit && this.checklist().bucket && !this.checklist().not_updatable}>
                                            <RequirePermission perms={is.checklistsAdmin}>
                                                <li className="dropdown-item abc-clickable" onClick={() => this.openDataColumnSelection()} title="Set up data columns">Data setup</li>
                                            </RequirePermission>
                                        </ShowIf>
                                        <li className='dropdown-item abc-clickable' onClick={() => this.duplicateChecklist()} title="Copy this checklist">Copy this Action list</li>
                                        <li className="dropdown-divider"></li>
                                        <ShowIf if={this.checklist().archived}>
                                            <li className="dropdown-item abc-clickable" onClick={() => this.openUnarchiveDialog()} title="Unarchive">Restore</li>
                                        </ShowIf>
                                        <HideIf if={this.checklist().archived}>
                                            <li className="dropdown-item abc-clickable" onClick={() => this.openArchiveDialog()} title="Delete">Delete</li>
                                        </HideIf>
                                    </ul>
                                </div>
                            </RequirePermission>
                        </div>
                    </HideIf>

                    <div className="inline-block">
                        <span className={`badge home-checklist-count font-size-14px margin-left-8px ${undoneCount === 0 ? "green-target" : "custom-badge"}`}
                            style={{position: "relative", bottom: "8px"}}>
                            {undoneCount}
                        </span>
                    </div>
                    <h1 className="inline-block checklist-name-hover" onClick={() => this.openEditNameDialog()}>
                        {checklist.name}
                    </h1>
                    <div className="inline-block margin-right-10px" style={{position:"relative", bottom:"1px"}}>
                        <i className="fa fa-info-circle margin-left-10px glyphicon-info-gray abc-click" onClick={() => this.setState({showDataDescription: true})} />
                    </div>
                    <ShowIf if={checklistCanBeUpdated && ItemTooOld(checklist.upload_time)} >
                        <button className="btn btn-xs btn-danger vertical-align-text-bottom" onClick={() => this.refs.itemTooOldDialog.show()}>
                            <img className="refresh_data_image" alt="Data" src="./img/data_refresh_white.svg" />
                                Updated {fromNow(checklist.upload_time)}
                        </button>
                    </ShowIf>
                    <ShowIf if={!checklistCanBeUpdated || !ItemTooOld(checklist.upload_time)} >
                        <button className="btn btn-xs btn-default vertical-align-text-bottom disabled" title={checklistCanBeUpdated && this.checklist().automatic_update ? "Checklist updates automatically" : ""}>
                            <img className="refresh_data_image" alt="Data" src="./img/data_refresh_grey.svg" />
                                Updated {fromNow(checklist.upload_time)}
                        </button>
                    </ShowIf>
                    <small className="font-size-14px inline-block">
                        &emsp;
                        {this.checklist().has_deadline && moment(this.checklist().deadline).isValid() ? <span>Deadline: {formatDate(this.checklist().deadline)}&emsp;</span> : null}
                    </small>
                    <ShowIf if={checklistCanBeUpdated && !this.checklist().automatic_update}>
                        <button className="btn btn-xs btn-default vertical-align-text-bottom" onClick={() => this.props.dispatch(updateChecklistItems(this.checklist().id))}>Update Action list</button>
                    </ShowIf>
                </div>

                <hr className="margin-top-0 full-width-hr hr-color margin-bottom-10px" />
                <ScrollTopSticker id="topBar">
                    <div className="single-checklist-top-bar">
                        <section className="single-checklist-category-wrapper">
                            <div>
                                <span title="Category counts towards not completed" className="fa fa-clock-o hover-cursor vertical-align-middle" style={{marginLeft:"12px", marginRight:"12px"}}></span>
                                {
                                    notDoneLayout.map((l, i) => {
                                        let name = l.name !== '' ? l.name : <React.Fragment>&nbsp;</React.Fragment>
                                        return (
                                            <div className="inline-block margin-right-5px" key={i}>
                                                <button className={`btn btn-sm ${l.color === 'black' ? 'btn-dark' : l.color === 'btn-primary' ? 'btn-old-primary' : l.color}`} onClick={() => this.changeStatus(i)} id="btn-single-checklist-filter-flag">
                                                    {l.color === 'black' ? <span className="white">{name}</span> : <span>{name}</span>}
                                                </button>
                                            </div>
                                        )
                                    })
                                   
                                }
                                 <div className="inline-block" style={{borderRight:"1px solid grey", float:"right", height:"30px"}}>
                                        &nbsp;
                                </div>
                            </div>
                            <div style={{marginLeft:"3px",paddingLeft:"5px"}}>
                                <span title="Category counts towards completed" className="fa fa-check hover-cursor vertical-align-middle" style={{marginLeft:"5px", marginRight:"12px"}}></span>
                                {
                                    doneLayout.map((l, i) => {
                                        let name = l.name !== '' ? l.name : <React.Fragment>&nbsp;</React.Fragment>
                                        return (
                                            <div className="inline-block margin-right-5px" key={i}>
                                                <button className={`btn btn-sm ${l.color === 'black' ? 'btn-dark' : l.color === 'btn-primary' ? 'btn-old-primary' : l.color}`} onClick={() => this.changeStatus(notDoneLayout.length + i)} id="btn-single-checklist-filter-flag">
                                                    {l.color === 'black' ? <span className="white">{name}</span> : <span>{name}</span>}
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <NewLayoutCategorySlider className="add-category-group" expand={this.state.addCategory}>
                                <LayoutColorPicker color={this.state.tmpLayoutColor} onColorChange={c => this.setState({ tmpLayoutColor: c })} />
                                <input className="form-control form-control-sm" value={this.state.tmpLayoutName} placeholder="Category name" onChange={e => this.setState({ tmpLayoutName: e.target.value })} />
                                <div className="btn-group">
                                    <button className="btn btn-sm btn-default inline-block" onClick={() => this.cancelLayoutCategory()} title="Cancel category"><span className="fa fa-times red" /></button>
                                    <button className="btn btn-sm btn-default inline-block" onClick={() => this.saveLayoutCategory()} title="Save category"><span className="fa fa-check green" /></button>
                                </div>
                            </NewLayoutCategorySlider>

                            <div className="btn-group margin-right-10px">
                                <button className="btn btn-sm btn-default " title="Add category" onClick={() => this.addLayoutCategory()}>
                                    <i className="fa fa-plus"></i>
                                </button>
                                <button className="btn btn-sm btn-default" title="Edit" onClick={() => this.openLayoutDialog()}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </div>

                            <ShowIf if={this.getPreviousSelectedItems().length > 0 && selectedItemsCount === 0}>
                                <button className="btn btn-sm btn-default margin-right-10px" onClick={() => this.reselectPrevious()}>
                                    <i className="fa fa-check"></i> Select again
                                </button>
                            </ShowIf>
                        </section>
                     
                        <div className="float-right single-checklist-action-buttons">
                            <ShowIf if={selectedItemsCount > 0}>
                                <button className="btn btn-sm btn-default margin-right-10px" onClick={() => this.deselectAll()}>
                                    Deselect all ({selectedItemsCount})
                                </button>
                            </ShowIf>

                            <RequirePermission perms={is.checklistsAdmin}>
                                <ShowIf if={this.props.userInfo.user_id === this.checklist().user_id && selectedItemsCount > 0}>
                                    <button className={this.state.deleteButtonClasses} onMouseEnter={() => this.endangerDeleteButton()} onMouseLeave={() => this.normaliseDeleteButton()} disabled={this.getSelectedItems().length === 0} onClick={() => this.refs.deleteSelectedItemsDialog.show()}>
                                        <i className="fa fa-trash"></i> Delete ({selectedItemsCount})
                                    </button>
                                </ShowIf>
                            </RequirePermission>
                          
                            <button title="Wrap text" className={`btn btn-default btn-sm margin-right-10px ${this.state.wrapHeaderText ? 'active' : ''}`} onClick={() => this.setState({ wrapHeaderText: !this.state.wrapHeaderText })}><i className="fa fa-expand"></i></button>
                            <button className={`btn btn-sm btn-default ${this.state.showDecimals ? 'active' : ''} margin-right-10px`}
                                onClick={() => this.toggleShowDecimals()} title="Show decimals">
                                0.0
                            </button>
                            <div className="btn-group inline-block">
                                <button title={selectedItemsCount === 0 ? "Copy all tables to clipboard":"Copy selected rows to clipboard"} className="btn btn-default btn-sm" onClick={() => this.refs.panels.copyToClipboard('html', this.getSelectedItems())}><span className="fa fa-copy" /> {selectedItemsCount != 0 ? `(${selectedItemsCount})` : ""}</button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <ShowIf if={totalItemCount * this.state.shownColumns.length < 1000000}>
                                        <li className="abc-click" onClick={this.promptDownloadChecklistDataExcel}><span>Download data (Excel)</span></li>
                                    </ShowIf>
                                    <ShowIf if={totalItemCount * this.state.shownColumns.length >= 1000000}>
                                        <li><span title="Data set is too big"><i>Download data (Excel)</i></span></li>
                                    </ShowIf>
                                    <li className="abc-click" onClick={this.promptDownloadChecklistData}><span>Download data (CSV)</span></li>
                                </ul>
                                <button 
                                    title={selectedItemsCount != 0 ?"Download " + selectedItemsCount + " rows" : "Download"} 
                                    type="button" 
                                    className="btn btn-default btn-sm margin-right-10px" 
                                    data-toggle="dropdown" 
                                    aria-haspopup="true" 
                                    aria-expanded="false"
                                >
                                    <i className="fa fa-download"></i>
                                    <span className="sr-only">Toggle Dropdown</span> {selectedItemsCount != 0 ? `(${selectedItemsCount})` : ""}
                                </button>
                            </div>
                            <button className="btn btn-default btn-sm" title="Edit Column layout" onClick={() => this.openColumnSelection()}><i className="fa fa-columns" /></button>
                        </div>
                    </div>
                </ScrollTopSticker>

                <WarningAcceptDialog accept={() => this.deleteItems()} acceptText="Delete" ref="deleteSelectedItemsDialog" title="Delete item">
                    <p>Do you really want to delete these {this.getSelectedItems().length} item(s)?</p>
                </WarningAcceptDialog>

                <WarningDescriptionDialog ref="itemTooOldDialog" title="Data is not up to date" okText="I understand">
                    <p>
                        Be aware that the data, you are browsing has not been updated since
                        <br />
                        {formatDateTime(checklist.upload_time)} ({fromNow(checklist.upload_time)})
                    </p>
                    <p> 
                        Contact the responsible person for this or contact Inact for help. 
                        <br />
                        <a href="mailto:support@inact.io">support@inact.io</a>
                    </p>
                </WarningDescriptionDialog>

                <TextInputDialog ref="changeNameDialog" title="Change Action list name" saveHandler={(name) => this.saveChecklistName(name)} />

                <TextareaDialog ref="changeDescriptionDialog" title="Change description" saveHandler={(desc) => this.saveDescription(desc)} />

                <AssignUserDialog ref="assigneeDialog" title="Change assignee" description={this.checklist().description} defaultDate={this.checklist().deadline ? moment(this.checklist().deadline) : null} defaultUseDeadline={!!this.checklist().deadline} saveHandler={(u, m, ud, d) => this.saveChecklistAssignee(u, m, ud, d)} users={this.getAssignUsers()} showUserPredicate={u => (canUseChecklists(u.access) && !u.is_consultant) || (u.id === this.props.userInfo.user_id && this.props.userInfo.is_consultant_login)} sort={{sortBy: "firstname", order: "asc"}} />

                <UserOptionDialog ref="authorDialog" title="Change author" saveHandler={(u) => this.saveChecklistAuthor(u)} users={this.getAssignUsers()} showUserPredicate={u => (canUseChecklists(u.access) && !u.is_consultant) || (u.id === this.props.userInfo.user_id && this.props.userInfo.is_consultant_login)} sort={{sortBy: "firstname", order: "asc"}} />

                <DataDescriptionDialog2 
                    show={this.state.showDataDescription} 
                    onClose={() => this.setState({showDataDescription: false})} 
                    title="About this Action list" filter={checklistFilter} 
                    columns={columns} 
                    showColumnDescription={false} 
                    columnDescription={bucket ? bucket.setup.descriptions : {}} 
                    description={this.checklist().description} 
                    filterDescription={getDataDescriptionDialogDescription("Action list")(checklist.limit, checklist.sort_direction, checklist.sort_column, checklistFilter.length)} 
                    datasourceName={bucket?.name}
                    isAutomaticUpdate={checklistCanBeUpdated && this.checklist().automatic_update} />

                <WarningAcceptDialog ref="archiveChecklistDialog" title="Delete Action list" acceptText="Delete" accept={() => this.archiveChecklist()} >
                    <p>Do you want to delete this Action list?</p>
                </WarningAcceptDialog>

                <SaveDialog ref="unarchiveChecklistDialog" title="Restore Action list" saveHandler={() => this.unarchiveChecklist()}>
                    <p>Do you want to restore this Action list?</p>
                </SaveDialog>

                <SaveDialog ref="reactivateDialog" title="Reactivate Action list" saveHandler={() => this.reactivateChecklist()} saveText="Yes, reactivate">
                    <p>Does the Action list require extra work?</p>
                </SaveDialog>

                <CompleteChecklistDialog ref="completeDialog" title="Complete Action list" name={getFullName(this.props.users, this.checklist().user_id)} saveHandler={(m) => this.completeChecklist(m)} />

                <DeadlinePickerDialog ref="deadlineDialog" title="Edit deadline" defaultDate={this.checklist().deadline ? moment(this.checklist().deadline) : moment()} saveHandler={(d) => this.saveDeadline(d)} deleteHandler={() => this.removeDeadline()} deleteText="Remove deadline" />

                <EditChecklistLayoutDialog ref="layoutDialog" layouts={this.props.layouts} layout={this.state.tempLayout} hideDeleteButtonPredicate={(i, l) => this.hideDeleteButtonPredicate(i, l)} saveHandler={(l, d, b, layout) => this.saveLayout(l, d, b, layout)} />

                <DeleteChecklistDialog ref="deleteChecklistDialog" accept={() => this.deleteChecklist()} />

                {this.state.showEditChecklist &&
                    <EditChecklistDialog checklist={checklist} onClose={this.onEditClose} redirect={true} getAllItems={() => this.getAllItems(true)}/>
                }
                
                {this.state.loading ?
                <div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <LoadingAnimation style={{marginTop: "100px"}} />
                    </div>
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <h2>Loading</h2>
                    </div>
                </div>
                :
                <ChecklistPanels
                    ref="panels"
                    settingsParent={this}
                    layout={layout}
                    getSelectedItems={this.getSelectedItems}
                    shownColumns={this.state.shownColumns}
                    onSelect={this.onItemSelect}
                    showDecimals={this.state.showDecimals}
                    isPastedChecklist={this.isPastedChecklist}
                    checklist={checklist}
                    getLatestComment={this.getLatestComment}
                    wrapHeaderText={this.state.wrapHeaderText}
                    columnDescriptions={bucket ? bucket.setup.descriptions : {}}
                    fetchData={this.fetchData}
                    itemsList={this.props.itemsList}
                    totals={this.props.totals}
                    onSort={this.onSort}
                    bucket={bucket}
                />
                }

                <ColumnSelectionDialog ref="columnSelect" saveHandler={this.saveShownColumns} activeCategoryColumn={bucket ? bucket.model.categorization_name: ""} />
                <SaveDialog ref={this.downloadDialog} title="Download data (CSV)" saveText="OK" closeText="Cancel" saveHandler={this.downloadChecklistData}>
                    Your data is being prepared for download - you will get a notification when it is ready!
                </SaveDialog>
                <SaveDialog ref={this.downloadDialogExcel} title="Download data (Excel)" saveText="OK" closeText="Cancel" saveHandler={this.downloadChecklistDataExcel}>
                    Your data is being prepared for download - you will get a notification when it is ready!
                </SaveDialog>
                <DataColumnSelectionDialog ref="dataColumnSelect" saveHandler={this.saveDataColumns} />
            </div>
        )
    }
}

class ChecklistPanels extends React.PureComponent {
    constructor(props) {
        super(props)
        let checklist = this.props.checklist

        //If shown columns are available, the sorting column exist and is shown, then set the sorting to this
        if (checklist.sort_column && checklist.sort_direction && props.shownColumns.length > 0 && this.props.shownColumns.find(sc => sc.name === checklist.sort_column)) {
            let dataColumns = JSON.parse(checklist.data_columns)
            let col = dataColumns.find(dc => dc.Name === checklist.sort_column)
            let colIndex = dataColumns.findIndex(dc => dc.Name === checklist.sort_column)
            this.state = {
                sortColumn: { name: col.Name, type: intColTypeToStringColType(col.ColumnType), index: colIndex },
                sortDirection: checklist.sort_direction === 'desc',
                autoFitArr: [],
                firstLoad: true,
            }
        } else {
            //Default sorting is the first column descending
            let initialSortcolumn = JSON.parse(checklist.data_columns)[0]
            this.state = {
                sortColumn: { name: initialSortcolumn.Name, type: intColTypeToStringColType(initialSortcolumn.ColumnType), index: 0 },
                sortDirection: true,
                autoFitArr: [],
                firstLoad: true,
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let checklist = this.props.checklist
        let dataColumns = JSON.parse(checklist.data_columns)

        if ( //If the shownColumns were not available at the time of construction, the sorting is set here, if there's any
            checklist.sort_column &&
            checklist.sort_direction &&
            this.props.shownColumns.find(sc => sc.name === checklist.sort_column) &&
            prevProps.shownColumns.length === 0 &&
            this.props.shownColumns.length > 0
        ) {
            let col = dataColumns.find(dc => dc.Name === checklist.sort_column)
            let colIndex = dataColumns.findIndex(dc => dc.Name === checklist.sort_column)
            let sortColumn = { name: col.Name, type: intColTypeToStringColType(col.ColumnType), index: colIndex }
            let sortDirection = checklist.sort_direction === 'desc'
            this.setSortColumn(sortColumn, sortDirection)
        }

        if (this.state.firstLoad) this.setState({ firstLoad: false })
    }

    totalItemCount(items) {
        return items.reduce((acc, i) => acc + i.length, 0)
    }

    getFilteredItems(category){
        let checklist = this.props.checklist

        return Object.values(this.props.itemsList.get2(checklist.id, category).data)
    }

    copyToClipboard(format, selectedItems) {
        let text = ''
        let rowCount = 0
        let columnsPresented = false

        for (let i = 0; i < this.props.layout.length; i++) {
            let filteredItems = this.getFilteredItems(i)
            if(selectedItems.length !== 0){
                const selectedIds = selectedItems.map(item => item.key);
                filteredItems = filteredItems.filter(item => selectedIds.includes(item.key));
            }
            if (format === 'html' && filteredItems.length > 0) text += this.refs['dataGrid-' + i].getHTML(true, !columnsPresented)
            if (format === 'csv' && filteredItems.length > 0) text += this.refs['dataGrid-' + i].getCSV(true, !columnsPresented)
            
            columnsPresented = columnsPresented || filteredItems.length > 0
            rowCount += filteredItems.length
        }
        copyToClipboard(text)
        notifySuccess(rowCount + ' rows has been copied to clipboard')
    }

    copyTableToClipboard(index) {
        copyToClipboard(this.refs['dataGrid-' + index].getHTML(true, true))
        let filteredItems = this.getFilteredItems(index);
        const selectedItems = this.props.getSelectedItems();
        if(selectedItems.length !== 0){
            const selectedIds = selectedItems.map(item => item.key);
            filteredItems = filteredItems.filter(item => selectedIds.includes(item.key));
        }
        notifySuccess(filteredItems.length + ' rows has been copied to clipboard')
    }

    setSortColumn = (col, dir) => {
        this.setState({ sortColumn: col, sortDirection: dir }, () => {
            this.props.onSort(col.index, dir ? "desc" : "asc")
        })
        
        for (let i = 0; i < this.props.layout.length; i++) {
            if (this.refs["dataGrid-" + i]) this.refs["dataGrid-" + i].setSorting(dir, col.name)
        }
    }

    sortRows = (rows, columns, sortColumn, direction) => {
        return rows.sort((a, b) => {
            if (sortColumn.type === 'decimal') {
                let aVal = parseFloat(a.data[sortColumn.index], 10)
                let bVal = parseFloat(b.data[sortColumn.index], 10)
                return direction ? (aVal < bVal ? 1 : (aVal > bVal ? -1 : 0)) : (aVal > bVal ? 1 : (aVal < bVal ? -1 : 0))
            } else {
                let aVal = a.data[sortColumn.index]
                let bVal = b.data[sortColumn.index]
                return direction ? bVal.localeCompare(aVal) : aVal.localeCompare(bVal)
            }
        })
    }

    deselectAll() {
        for (let i = 0; i < this.props.layout.length; i++) {
            if (this.refs["dataGrid-" + i]) this.refs["dataGrid-" + i].deselectAll()
        }
    }

    reselect(items) {
        for (let i = 0; i < this.props.layout.length; i++) {
            if (items[i] && items[i].length > 0) this.refs["dataGrid-" + i].reselect(items[i])
        }
    }

    setAutoFitArr(arr) {
        this.setState({ autoFitArr: arr })
    }

    select = (key) => {
        this.props.layout.forEach((l, i) => {
            let filteredItems = this.getFilteredItems(i)
            let index = filteredItems.findIndex(item => item.key === key)
            if (index > -1 && this.refs["dataGrid-" + i]) {
                let item = { id: filteredItems[index].id, key: filteredItems[index].key }
                this.refs["dataGrid-" + i].forceToggleSelection(item)
                this.refs["dataGrid-" + i].focus(item)
            }
        })
    }

    fetchMoreData = (category) => {
        this.props.fetchData(category)
    }

    render() {
        let numberOfColumns = JSON.parse(this.props.checklist.data_columns).length 
        return (
        <>
            {this.props.layout.map((l, k) => {
                let columns = this.props.shownColumns.slice()
                //Add hidden column with the current status of the item (TO DO, DONE...). Used when copying table. The last column of the table is hidden with CSS.
                let items = Object.values(this.props.itemsList.get2(this.props.checklist.id, k).data)
                let sumRow = new Array(numberOfColumns).fill(0) 
                let rows = items.map(i => {
                    let data = JSON.parse(i.data)
                    columns.forEach((column, nope) => {
                        switch(column.type) {
                            case ('date') : 
                                sumRow[column.index] = null;
                                break
                            case 'text':
                                sumRow[column.index] = null;
                                break
                            case 'id' : 
                                sumRow[column.index] += 1;
                                break
                            case 'categorization' : 
                                sumRow[column.index] = null;
                                break
                            case ('decimal' || 'calculated'): 
                                sumRow[column.index] += JSON.parse(data[column.index]);
                                break
                            default :
                                sumRow[column.index] += data[column.index];
                                break
                        }
                    });
                    data.push(l.name)
                    return { data, id: { key: i.key, id: i.id } }
                })
                
                columns.push({ name: "Category", type: "text", index: numberOfColumns, colgroupClassName: "display-none" })
                let total = this.props.totals.get(this.props.checklist.id, k)
                rows = this.sortRows(rows, columns, this.state.sortColumn, this.state.sortDirection)

                if (rows.length > 0){
                    rows.push({data : sumRow, id: {key: "sum", id: "sum-row"}})
                }
                return (
                    <div key={k}>
                        <ChecklistPanel color={l.color} title={l.name} number={total} copyToClipboard={() => this.copyTableToClipboard(k)}>
                            <ShowIf if={rows.length > 0}>
                                
                                <DataGrid
                                    className="no-margin checklist-panel-datagrid"
                                    ref={"dataGrid-" + k}
                                    id={"dataGrid-" + k}
                                    bucketId={this.props.checklist.bucket}
                                    enableCheckboxes={true}
                                    columns={columns}
                                    rows={rows}
                                    model={this.props.bucket ? this.props.bucket.model : undefined}
                                    showDecimals={this.props.showDecimals}
                                    getLatestComment={this.props.getLatestComment}
                                    onSort={this.setSortColumn}
                                    defaultSortColumn={this.state.sortColumn.name}
                                    defaultSortDirection={this.state.sortDirection}
                                    onSelect={items => this.props.onSelect(items, k)}
                                    autoFitArr={this.state.autoFitArr}
                                    setAutoFitArr={(arr) => this.setAutoFitArr(arr)}
                                    fromChecklist={true}
                                    sticky={108}
                                    wrapHeaderText={this.props.wrapHeaderText}
                                    showOpenButton={!this.props.isPastedChecklist()}
                                    columnDescriptions={this.props.columnDescriptions}
                                    hastotalrow={true}
                                /> 
                            </ShowIf>
                            <InfiniteScrollOnScreen hasMore={total > items.length && this.props.checklist.limit == -1} next={() => {this.fetchMoreData(k)}} loadingText={""}/>
                        </ChecklistPanel>
                    </div>
                )
            })}
            </>
        )
    }
}

const shouldComponentUpdateSettingsCP = (prevState, curState) => {
    return (
        prevState.sortDirection !== curState.sortDirection ||
        (JSON.stringify(prevState.sortColumn) !== JSON.stringify(curState.sortColumn)) ||
        prevState.autoFitArr !== curState.autoFitArr 
    )
}

const getSettingsKeysCP = (state) => {
    let { ...settings } = state
    return Object.keys(settings)
}

const settingsDidApplyCP = (_this, prevSettings, invalidSettings) => {
    for (let i = 0; i < _this.props.layout.length; i++) {
        if (_this.refs["dataGrid-" + i]) _this.refs["dataGrid-" + i].setSorting(_this.state.sortDirection, _this.state.sortColumn.name)
        if (_this.refs["dataGrid-" + i]) _this.refs["dataGrid-" + i].setAutoArr(_this.state.autoFitArr)
    }
}

ChecklistPanels = withSettingsPropagation(ChecklistPanels, getSettingsKeysCP, shouldComponentUpdateSettingsCP, null, settingsDidApplyCP, "checklist-panels")

class CompleteChecklistDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = { message: "" }
    }

    show() {
        this.setState({ message: "" })
        this.refs.dialog.show()
    }

    hide() {
        this.refs.dialog.hide()
    }

    updateTempMessage(e) {
        this.setState({ message: e.target.value })
    }

    render() {
        return (
            <SaveDialog ref="dialog" title={this.props.title} saveHandler={() => this.props.saveHandler(this.state.message)} closeHandler={() => this.props.closeHandler ? this.props.closeHandler() : null}>
                <p>Great! You're done with the Action list. </p>
                <p>Return it to {this.props.name} so your fine work will be noticed.</p>
                <label>Add a message: (optional)</label>
                <textarea className="form-control resize-vertical" type="text" value={this.state.message} onChange={e => this.updateTempMessage(e)} />
            </SaveDialog>
        )
    }
}

class DeadlinePickerDialog extends React.Component {
    constructor(props) {
        super(props)
        let date = props.defaultDate
        if (date.isValid()) this.state = { date: date }
        else this.state = { date: moment() }
    }

    show() {
        let date = this.props.defaultDate
        if (date.isValid()) this.setState({ date: date })
        else this.setState({ date: moment() })
        this.refs.dialog.show()
    }

    hide() {
        this.refs.dialog.hide()
    }

    updateDate(m) {
        this.setState({ date: m })
    }

    render() {
        return (
            <SaveDeleteDialog style={{height: 250}} ref="dialog" title={this.props.title} saveHandler={() => this.props.saveHandler(this.state.date)} deleteHandler={() => this.props.deleteHandler()} closeHandler={() => { if (this.props.closeHandler) this.props.closeHandler(); }} deleteText={this.props.deleteText}>
                <div style={{height: 280}}>
                    <DatePicker className="form-control" selected={this.state.date} dateFormat="DD-MM-YYYY" onChange={(m) => this.updateDate(m)} />
                </div>
            </SaveDeleteDialog>
        )
    }
}
const shouldComponentUpdateSettings = (prevState, curState) => {
    return (JSON.stringify(curState.shownColumns) !== JSON.stringify(prevState.shownColumns) ||
        curState.showDecimals !== prevState.showDecimals ||
        prevState.wrapHeaderText !== curState.wrapHeaderText)
}

const getSettingsKeys = (state) => {
    return ["showDecimals", "wrapHeaderText"]
}

const getSessionStoreKey = _this => {
    return "checklist-" + _this.getChecklistId()
}

SingleChecklist = withSettingsStorage(SingleChecklist, getSettingsKeys, shouldComponentUpdateSettings, null, null, getSessionStoreKey, null, null, "checklist", { withSessionStore: true })

SingleChecklist = connect(mapStateToProps)(SingleChecklist)

export default SingleChecklist
