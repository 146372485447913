import * as ActionTypes from '../actions/ActionTypes'
import { DoubleKeyMap, SingleKeyMap, TripleKeyMap } from '../helpers/Collections'

const defaultState = {
    historydata: new SingleKeyMap(),
    historydataProfit: new DoubleKeyMap(),
    aggregationdata: new TripleKeyMap(),
    totaldata: new SingleKeyMap(),
    trendsetsdata: new SingleKeyMap(),
    diffdata: new SingleKeyMap(),
    actualgraphdata: new SingleKeyMap(),
    griddata: new SingleKeyMap(),
    favourites: new SingleKeyMap(),
    toggleFav: new SingleKeyMap(),
    slicerValues: new SingleKeyMap(),
    slicerAvailableValues: new SingleKeyMap(),
    slicerValuesTooBig:  new SingleKeyMap(),
    groups: new SingleKeyMap(),
    buckets: new SingleKeyMap(),
    jumpToCompose: null,
    reportsInDashboards: new SingleKeyMap(),
    reportHasErrors: false,
    isSlicerValueResultSetTooBig: {},
    sumData: new TripleKeyMap(),
}

const Report = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.GET_HISTORY_DATA_DONE:
            let historydata = new SingleKeyMap(state.historydata)
            historydata.set(action.payload.bucket, action.payload.data)
            return Object.assign({}, state, { historydata: historydata })
        case ActionTypes.GET_HISTORY_PROFIT_DATA_DONE:
            let historydataProfit = new DoubleKeyMap(state.historydataProfit)
            action.payload.data.forEach((d, i) => historydataProfit.set(action.payload.bucket, action.payload.columns[i].column, d))
            return Object.assign({}, state, { historydataProfit: historydataProfit })
        case ActionTypes.GET_REPORT_AGGREGATION_DONE:
            let aggrData = new TripleKeyMap(state.aggregationdata)
            aggrData.set(action.payload.key, action.payload.rId, action.payload.column, action.payload.data)
            return Object.assign({}, state, { aggregationdata: aggrData })
        case ActionTypes.GET_BUCKET_TOTAL_DONE:
            let totalData = new SingleKeyMap(state.totaldata)
            totalData.set(action.payload.rId, action.payload.data)
            return Object.assign({}, state, { totaldata: totalData })
        case ActionTypes.GET_REPORT_MULTIPLE_AGGREGATIONS_DONE:
            let aggrDataMap = new TripleKeyMap(state.aggregationdata)
            action.payload.data.aggregations.forEach(item => {
                aggrDataMap.set(action.payload.key, action.payload.rId, action.payload.prefix + item.aggregation_column, item.aggregation)
            })
            return Object.assign({}, state, { aggregationdata: aggrDataMap })
        case ActionTypes.GET_SUM_VIEW_DATA_DONE:
            let sumData = new TripleKeyMap(state.sumData)
            sumData.set(action.payload.key, action.payload.rId, action.payload.column, action.payload.data)
            return Object.assign({}, state, { sumData })
        case ActionTypes.GET_REPORT_TRENDSETS_DONE:
            let trendsetsdata = new SingleKeyMap(state.trendsetsdata)
            trendsetsdata.set(action.payload.rId, action.payload.data)
            return Object.assign({}, state, { trendsetsdata: trendsetsdata })
        case ActionTypes.GET_REPORT_DIFF_DONE:
            let diffdata = new SingleKeyMap(state.diffdata)
            diffdata.set(action.payload.rId, action.payload.data)
            return Object.assign({}, state, { diffdata: diffdata })
        case ActionTypes.GET_ACTUAL_GRAPH_DATA_DONE:
            let actualgraphdata = new SingleKeyMap(state.actualgraphdata)
            actualgraphdata.set("data", action.payload.data)
            return Object.assign({}, state, { actualgraphdata: actualgraphdata })
        case ActionTypes.GET_REPORT_GRID_DATA_DONE:
            let griddata = new SingleKeyMap(state.griddata)
            griddata.set("data", action.payload.data)
            return Object.assign({}, state, { griddata: griddata })
        case ActionTypes.GET_FAVOURITE_REPORTS_INFO_DONE:
            let favourites = new SingleKeyMap(state.favourites)
            favourites.set("data", action.payload)
            return Object.assign({}, state, { favourites: favourites })
        case ActionTypes.TOGGLE_FAVOURITE_DONE:
            let toggleFav = new SingleKeyMap(state.toggleFav)
            let data = action.payload.data
            if (data === null) {
                data = { id: 0 }
            }
            toggleFav.set("data", data)
            return Object.assign({}, state, { toggleFav: toggleFav })
        case ActionTypes.GET_REPORT_SLICER_VALUES_DONE:
            let slicerValues = new SingleKeyMap(state.slicerValues)
            let slicerValuesTooBig = new SingleKeyMap(state.slicerValuesTooBig)
            let isSlicerValueResultSetTooBig = Object.assign({}, state.isSlicerValueResultSetTooBig)
            action.payload.data.values ??= []
            slicerValues.set(action.payload.column, action.payload)
            slicerValuesTooBig.set(action.payload.column, !!action.payload.data.result_set_too_big)
            isSlicerValueResultSetTooBig[action.payload.column] ||= !!action.payload.data.result_set_too_big
            return Object.assign({}, state, { slicerValues, slicerValuesTooBig, isSlicerValueResultSetTooBig })
        case ActionTypes.GET_REPORT_SLICER_AVAILABLE_VALUES_DONE:
            let slicerAvailableValues = new SingleKeyMap(state.slicerAvailableValues)
            action.payload.data.values ??= []
            slicerAvailableValues.set(action.payload.column, action.payload)
            return Object.assign({}, state, {slicerAvailableValues})
        case ActionTypes.GET_REPORT_GROUPS_DONE:
            let groups = new SingleKeyMap(state.groups)
            groups.set("groups", action.payload)
            return Object.assign({}, state, { groups: groups })
        case ActionTypes.GET_ALL_BUCKETS_DONE:
            let buckets = new SingleKeyMap(state.buckets)
            buckets.set("buckets", action.payload)
            return Object.assign({}, state, { buckets: buckets })
        case ActionTypes.JUMP_TO_REPORT_COMPOSE:
            return Object.assign({}, state, { jumpToCompose: action.payload })
        case ActionTypes.JUMPED_TO_REPORT_COMPOSE:
            return Object.assign({}, state, { jumpToCompose: null })
        case ActionTypes.CAN_DELETE_REPORT_DONE:
            let reportsInDashboards = new SingleKeyMap(state.reportsInDashboards)
            reportsInDashboards.set(action.payload.id, action.payload.data)
            return Object.assign({}, state, { reportsInDashboards: reportsInDashboards })
        case ActionTypes.REPORT_HAS_ERRORS:
            return Object.assign({}, state, { reportHasErrors: true })
        case ActionTypes.GET_REPORT_INFO_DONE:
            return Object.assign({}, state, { reportHasErrors: false })
        default:
            return state
    }
}

export default Report