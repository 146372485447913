import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const getReportInfo = id => {
    return packAction(ActionTypes.GET_REPORT_INFO, { id: id })
}

export const gotReportInfo = info => {
    return packAction(ActionTypes.GET_REPORT_INFO_DONE, info)
}

export const getHistoryData = (id, data) => {
    return packAction(ActionTypes.GET_HISTORY_DATA, {id: id, data: data})
}

export const gotHistoryData = (bucket, data) => {
    return packAction(ActionTypes.GET_HISTORY_DATA_DONE, {bucket: bucket, data: data})
}

export const getHistoryProfitData = (id, data, columns) => {
    return packAction(ActionTypes.GET_HISTORY_PROFIT_DATA, {id: id, data: data, columns: columns })
}

export const gotHistoryProfitData = (bucket, data, columns) => {
    return packAction(ActionTypes.GET_HISTORY_PROFIT_DATA_DONE, { bucket: bucket, data: data, columns: columns })
}

export const getAggregationData = (bId, rId, data, column, key) => {
    return packAction(ActionTypes.GET_REPORT_AGGREGATION, {bId: bId, rId: rId, data: data, column: column, key: key})
}

export const getTotalBucketData = (bId, rId, data, column, key) => {
    return packAction(ActionTypes.GET_BUCKET_TOTAL, {bId: bId, rId: rId, data: data, column: column, key: key})
}

export const gotTotalBucketData = (bucket, rId, data, column, key) => {
    return packAction(ActionTypes.GET_BUCKET_TOTAL_DONE, {bucket: bucket, rId: rId, data: data, column: column, key: key})
}

export const getMultipleAggregationsData = (bId, rId, data, prefix, key) => {
    return packAction(ActionTypes.GET_REPORT_MULTIPLE_AGGREGATIONS, {bId: bId, rId: rId, data: data, key: key, prefix: prefix})
}

export const gotAggregationData = (bucket, rId, data, column, key) => {
    return packAction(ActionTypes.GET_REPORT_AGGREGATION_DONE, {bucket: bucket, rId: rId, data: data, column: column, key: key})
}

export const gotMultipleAggregationsData = (data, rId, prefix, key) => {
    return packAction(ActionTypes.GET_REPORT_MULTIPLE_AGGREGATIONS_DONE, {rId: rId, data: data, prefix: prefix, key: key})
}

export const getSumViewData = (bId, rId, data, key) => {
    return packAction(ActionTypes.GET_SUM_VIEW_DATA, {bId, rId, data, key})
}

export const gotSumViewData = (rId, column, key, data) => {
    return packAction(ActionTypes.GET_SUM_VIEW_DATA_DONE, {rId, column, data, key})
}

export const getTrendsetsData = (bId, rId, cb = () => {}) => {
    return packAction(ActionTypes.GET_REPORT_TRENDSETS, {bId: bId, rId: rId}, cb)
}

export const gotTrendsetsData = (data, rId, cb = () => {}) => {
    return packAction(ActionTypes.GET_REPORT_TRENDSETS_DONE, {data: data, rId: rId}, cb)
}
export const downloadReportDiffGridData = (bId, data) => {
    return packAction(ActionTypes.DOWNLOAD_REPORT_DIFF_GRID_DATA, {bId: bId, data: data})
}

export const getDiffData = (bId, rId, data) => {
    return packAction(ActionTypes.GET_REPORT_DIFF, {bId: bId, rId, data: data})
}

export const gotDiffData = (rId, data) => {
    return packAction(ActionTypes.GET_REPORT_DIFF_DONE, {rId, data: data})
}

export const getActualGraphData = (bId, data) => {
    return packAction(ActionTypes.GET_ACTUAL_GRAPH_DATA, {bId: bId, data: data})
}

export const gotActualGraphData = (data) => {
    return packAction(ActionTypes.GET_ACTUAL_GRAPH_DATA_DONE, {data: data})
}

export const downloadReportGridData = (bId, data) => {
    return packAction(ActionTypes.DOWNLOAD_REPORT_GRID_DATA, {bId: bId, data: data})
}

export const getReportGridData = (bId, data) => {
    return packAction(ActionTypes.GET_REPORT_GRID_DATA, {bId: bId, data: data})
}

export const gotReportGridData = (data) => {
    return packAction(ActionTypes.GET_REPORT_GRID_DATA_DONE, {data: data})
}

export const getSlicerValues = (bId, data, filter = []) => {
    return packAction(ActionTypes.GET_REPORT_SLICER_VALUES, {bId: bId, data: data, filter: filter})
}

export const gotSlicerValues = (data, column) => {
    return packAction(ActionTypes.GET_REPORT_SLICER_VALUES_DONE, {data: data, column: column})
}

export const getSlicerAvailableValues = (bId, data) => {
    return packAction(ActionTypes.GET_REPORT_SLICER_AVAILABLE_VALUES, {bId: bId, data: data})
}

export const gotSlicerAvailableValues = (data, column) => {
    return packAction(ActionTypes.GET_REPORT_SLICER_AVAILABLE_VALUES_DONE, {data: data, column: column})
}

export const toggleFavourite = data => {
    return packAction(ActionTypes.TOGGLE_FAVOURITE, {data: data})
}

export const toggleFavouriteDone = data => {
    return packAction(ActionTypes.TOGGLE_FAVOURITE_DONE, {data: data})
}

export const toggleFavouriteReports = data => {
    return packAction(ActionTypes.TOGGLE_FAVOURITE_REPORTS, data)
}
export const toggleFavouriteReportsDone = data => {
    return packAction(ActionTypes.TOGGLE_FAVOURITE_REPORTS_DONE, data)
}

export const getGroups = () => {
    return packAction(ActionTypes.GET_REPORT_GROUPS)
}

export const gotGroups = groups => {
    return packAction(ActionTypes.GET_REPORT_GROUPS_DONE, groups)
}

export const updateReport = (id, data) => {
    return packAction(ActionTypes.UPDATE_REPORT, {id: id, data: data})
}

export const updateReportDone = info => {
    return packAction(ActionTypes.UPDATE_REPORT_DONE, info)
}

export const updateReportKpis = (id, data) => {
    return packAction(ActionTypes.UPDATE_REPORT_KPIS, {id: id, data: data})
}

export const updateReportKpisDone = (info) => {
    return packAction(ActionTypes.UPDATE_REPORT_KPIS_DONE, info)
}

export const copyReport = (data, redirect = true) => {
    return packAction(ActionTypes.COPY_REPORT, {data: data, redirect})
}

export const deleteReport = (id, library) => {
    return packAction(ActionTypes.DELETE_REPORT, {id: id, fromLibrary: library})
}

export const canDeleteReport = (id) => {
    return packAction(ActionTypes.CAN_DELETE_REPORT, {id: id})
}

export const canDeleteReportDone = (id, dashboards) => {
    return packAction(ActionTypes.CAN_DELETE_REPORT_DONE, {id: id, data: dashboards})
}

export const updateViewSettings = (id, data) => {
    return packAction(ActionTypes.UPDATE_VIEWSETTINGS, {id: id, data: data})
}

export const updateKpisViewSettings = (id, data) => {
    return packAction(ActionTypes.UPDATE_KPIS_VIEWSETTINGS, {id: id, data: data})
}

export const updateKpiConfig = (id, data) => {
    return packAction(ActionTypes.UPDATE_REPORT_KPICONFIG, {id: id, data: data})
}

export const getAllBuckets = () => {
    return packAction(ActionTypes.GET_ALL_BUCKETS)
}

export const gotAllBuckets = (buckets) => {
    return packAction(ActionTypes.GET_ALL_BUCKETS_DONE, buckets)
}

export const jumpToCompose = reportId => {
    return packAction(ActionTypes.JUMP_TO_REPORT_COMPOSE, reportId)
}

export const startLoading = () => {
    return packAction(ActionTypes.START_LOADING)
}

export const removeJumpToCompose = () => {
    return packAction(ActionTypes.JUMPED_TO_REPORT_COMPOSE)
}

export const moveGroup = (id, data) => {
    return packAction(ActionTypes.MOVE_GROUP, {id: id, data: data})
}

export const deleteGroup = (id) => {
    return packAction(ActionTypes.DELETE_GROUP, {id: id})
}

export const createGroup = (data) => {
    return packAction(ActionTypes.CREATE_GROUP, {data: data})
}

export const assignGroup = (id, data) => {
    return packAction(ActionTypes.ASSIGN_GROUP, {id: id, data: data})
}

export const editGroupName = (id, data) => {
    return packAction(ActionTypes.EDIT_GROUP, {id: id, data: data})
}

export const removeColumns = (id, data) => {
    return packAction(ActionTypes.REMOVE_COLUMNS, {id: id, data: data})
}

export const updateIsPublic = (id, data) => {
    return packAction(ActionTypes.MAKE_REPORT_PUBLIC, {id: id, data: data})
}

export const updatedIsPublic = (id, data) => {
    return packAction(ActionTypes.MAKE_REPORT_PUBLIC_DONE, {id: id, data: data})
}

export const reportNotFound = () => {
    return packAction(ActionTypes.GET_REPORT_INFO_NOT_FOUND)
}

export const reportNotAccessible = () => {
    return packAction(ActionTypes.GET_REPORT_INFO_NOT_ACCESSIBLE)
}

export const reportHasErrors = () => {
    return packAction(ActionTypes.REPORT_HAS_ERRORS)
}