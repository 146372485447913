import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { SingleKeyMap } from '../helpers/Collections'
import { DashboardFull, DashboardOverview, User, LibraryReport } from '../types/transfertypes'
import { useParams } from 'react-router'
import { getDashboardAllData, getHomeDashboard, getDefaultDashboard, getDashboardOverview, setHomeDashboard, getDashboardReports } from '../actions/DashboardActions'
import { setPageTitle } from '../helpers/DocumentHelpers'
/* CSS */
import '../css/dashboard.css'
import '../css/DashboardAdmin.css'
import DashboardWidgets from '../components/Dashboard/DashboardWidgets'
import ShowIf from '../components/Generic/ShowIf'
import Drawer from '../components/Animation/Drawer'
import DashboardTable2 from '../components/Dashboard/DashboardTable2'
import RequirePermission from '../components/RequirePermission'
import { is } from '../helpers/PermissionHelpers'
// @ts-ignore
import { Link } from 'react-router-dom'
import ResponsiveToggle from '../components/Generic/ResponsiveToggle'
import HomeIcon from '../components/icons/HomeIcon'
import InfoTooltip from '../components/Generic/InfoTooltip'
import LoadingAnimation from '../components/LoadingAnimation'

interface DashboardProps {
    dispatch: any,
    dashboardData: SingleKeyMap<DashboardFull>,
    dashboardOverview: DashboardOverview[],
    homeDashboard: number,
    defaultDashboard: number,
    dashboardNotAccessible: boolean,
    user: User,
    history: any,
    reports: LibraryReport[]

}

let mapStateToProps = (state: any, ownProps: DashboardProps) => {
    return {
        user: state.User.info,
        dashboardOverview: state.Dashboard.overview,
        dashboardData: state.Dashboard.dashboard_data,
        homeDashboard: state.Dashboard.homeDashboard,
        defaultDashboard: state.Dashboard.defaultDashboard,
        dashboardNotAccessible: state.Dashboard.dashboardNotAccessible,
        followings: state.User.followings,
        reports: state.Dashboard.reports,
    }
}

let Dashboard2 = (props: DashboardProps) => {

    const [dashboard, setDashboard] = useState<DashboardFull | undefined>()
    const [hoverRowId, setHoverRowId] = useState<string>("")
    const [hoverKpiKey, setHoverKpiKey] = useState<string>("")
    const [showTable, setShowTable] = useState(false)

    let { dID } = useParams<{ dID: string }>()
    const dashboardID = useMemo(() => Number(dID), [dID])

    useEffect(() => {
        props.dispatch(getDashboardAllData(dashboardID))
        props.dispatch(getHomeDashboard())
        props.dispatch(getDefaultDashboard())
        props.dispatch(getDashboardOverview())
        props.dispatch(getDashboardReports())
        setPageTitle("Dashboard")
    }, [])

    useEffect(() => {
        props.dispatch(getDashboardAllData(dashboardID))
    }, [dashboardID])

    useEffect(() => {
        let dash = props.dashboardData.get(dID)
        setDashboard(dash)
    }, [props.dashboardData, dID])

    const widgetData = useMemo(() => {
        if (dashboard != undefined) {
            return dashboard.widget_data
        }
        return []
    }, [dashboard])

    const updateHomeDashboard = () => {
        props.dispatch(setHomeDashboard(dashboardID))
    }

    const removeHomeDashboard = () => {
        if (props.defaultDashboard !== dashboardID) {
            props.dispatch(setHomeDashboard(0))
        }
    }

    const toggleHomeDashboard = () => {
        if (props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard)) {
            removeHomeDashboard()
        } else {
            updateHomeDashboard()
        }
    }

    const setHoverValues = (id: string, key: string) => {
        setHoverRowId(id)
        setHoverKpiKey(key)
    }

    const noAccessToHomeDashboard = (!props.homeDashboard || (props.dashboardOverview && props.dashboardOverview.every(d => d.id !== props.homeDashboard)))


    return (
        <div className="home-settings padding-top-20px">
            <div className="col-md-12 margin-bottom-20px">
                <div className="row">
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        {
                            !dashboard ? <div className='mt-5'>
                                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                    <LoadingAnimation style={{marginTop: "100px"}} />
                                </div>
                                <h1 className="text-align-center">Getting Dashboard...</h1>
                            </div> :
                                <div>
                                    <div className="d-flex align-items-center" style={{justifyContent: "space-between"}}>
                                            <div>
                                                <select className="form-control mr-2 form-control-lg inline-block auto-width vertical-align-middle" value={dID} onChange={e => props.history.push(`/dashboard/${e.target.value}`)}>
                                                    {
                                                        props.dashboardOverview.map(d => (
                                                            <option key={d.id} value={d.id}>{d.title}</option>
                                                        ))
                                                    }
                                                </select>
                                                <ShowIf if={dashboard.dashboard_data.description != ""}>
                                                    <InfoTooltip text={dashboard.dashboard_data.description} place="bottom"/>
                                                </ShowIf>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <ResponsiveToggle style={{width: "fit-content", padding: "0.375rem 0.75rem", borderColor: "#d9d9d9"}} label="Show on home" onChange={toggleHomeDashboard} checked={props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard)}>
                                                    <HomeIcon width={14} height={14} add={!(props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard))} />
                                                </ResponsiveToggle>
                                                <RequirePermission perms={is.dashboardAdmin}>
                                                    <Link to={`/dashboardadmin/${dID}`}>
                                                        <button className="btn btn-default ml-2 vertical-align-middle d-flex align-items-center"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                                                    </Link>
                                                </RequirePermission>
                                            </div>
                                            {/*<ShowIf if={props.homeDashboard !== dashboardID && !(props.defaultDashboard === dashboardID && noAccessToHomeDashboard)}>
                                                <span className="fa fa-star-o dashboard-star vertical-align-middle" title="Show on home" onClick={updateHomeDashboard}></span>
                                            </ShowIf>
                                            <ShowIf if={props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard)}>
                                                <span className="fa fa-star dashboard-star vertical-align-middle" title={props.defaultDashboard === dashboardID ? "This is a company dashboard. You cannot remove this from home, but you can override it by showing another dashboard." : "Remove from home"} onClick={removeHomeDashboard}></span>
                                            </ShowIf>*/}
                                        {/* <ShowIf if={!followsReport}>
                                            <button className="btn btn-default btn-xs" onClick={this.followDashboard(true)}>Follow</button>
                                        </ShowIf>
                                        <ShowIf if={followsReport}>
                                            <button className="btn btn-default btn-xs" onClick={this.followDashboard(false)}>Unfollow</button>
                                        </ShowIf> */}
                                    </div>
                                    <hr />
                                    <div className="margin-top-30px">
                                        <DashboardWidgets
                                            setHoverValues={(id: string, key: string) => setHoverValues(id, key)}
                                            hoverReportId={hoverRowId}
                                            hoverKpiKey={hoverKpiKey}
                                            widgetData={widgetData}
                                            dashboardId={dashboardID}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                </div>
                <div className="row">
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        {
                            !dashboard || dashboard.dashboard_data.hide_table ? null :
                                <div className="margin-top-20px">
                                    <Drawer expand={showTable} >
                                        {/*<DashboardTable
                                            dashboardId={dashboardID}
                                            dashboardData={dashboard.dashboard_data}
                                            setHoverValues={(id: string, key: string) => setHoverValues(id, key)}
                                            hoverReportId={hoverRowId}
                                            hoverKpiKey={hoverKpiKey}
                                        />
                                        {/* <ShowIf if={hasTargets}>
                                            <p className="margin-top-20px">
                                                <span onClick={() => this.setState({showTargets: !this.state.showTargets})} className="abc-clickable padding-5px-imp">
                                                    {this.state.showTargets ? "Hide" : "Show"} all targets<i className={`margin-left-5px fa ${this.getShownState()} `}></i>
                                                </span>
                                            </p>
                                        </ShowIf>
                                        <Drawer expand={this.state.showTargets} >
                                            <DashboardTargetTables dashboard={dashboardInfo} dashboardData={dashboardData} />
                                        </Drawer> */}
                                        <div className="d-flex justify-content-center">
                                            <div className="dashboard-table-wrapper mb-3">
                                                <DashboardTable2 
                                                    user={props.user}
                                                    dashboard={dashboard}
                                                    setHoverValues={setHoverValues}
                                                    hoverKpi={hoverKpiKey}
                                                    hoverRow={hoverRowId}
                                                    reports={props.reports}
                                                />
                                            </div>
                                        </div>
                                    </Drawer>
                                    <hr className="hr-color zero-margin" />
                                    <div className="width-100-p text-align-center">
                                        <button onFocus={(e) => e.target.blur()} onClick={() => setShowTable(v => !v)} className={`btn btn-default btn-xs expand-button dashboard-expand-button`}>
                                            {showTable ? "Hide" : "Show more"}
                                        </button>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                </div>
            </div>
        </div>
    )

}

// @ts-ignore
Dashboard2 = connect(mapStateToProps)(Dashboard2)

export default Dashboard2
