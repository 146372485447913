import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { getOrganization, getOrganizationItems, getOrganizationLocations, getOrganizationUsers } from "../../actions/OrganizationActions"
import "../../css/organizationAdministration.css"
import OrganizationAdministrationEdit from "./OrganizationAdministrationEdit"
import OrganizationAdministrationCreate from "./OrganizationAdministrationCreate"
import { disableMultipleUsers, saveUpdatedUser, sendWelcomeMail } from "../../actions/UserActions"
import MultiSelectBar, { MultiSelectButtonType } from "../MultiSelectBar"
import BaseDialog from "../Dialogs/BaseDialog"
import OrganizationAdministrationMultiDialog from "./OrganizationAdministrationMultiDialog"
import StandardStatusTable from "../Status/StandardStatusTable"
import { alertTypes } from "../Alert/AlertBanner"
import WarningAcceptDialog from "../Dialogs/WarningAcceptDialog"
import ShowIf from "../Generic/ShowIf"
import { NewIcon } from "../icons/NewIcon"
import { formatDateTime } from "../../helpers/TimeHelpers"
import { isReportingServiceUser, isNewUser, isUnactivatedUser, getLastLogin } from "../../helpers/UserHelpers"
import { getOrgUserHTML } from "../../helpers/OrganizationAdministrationHelpers"
import { copyToClipboard } from "../../helpers/GeneralHelpers"
import { notifySuccess } from "../../helpers/NotificationManager"

const mapStateToProps = (state, ownProps) => {
    return {
        orgID: state.User.info.organization,
        organization: state.Info.organization,
        organizationName: state.User.info.organization_name,
        orgUsers: state.Organization.users,
        orgAdmin: state.User,
        orgItems: state.Organization.items,
        organizationInfo: state.Organization.info,
        orgLocations: state.Organization.locations
    }
}

function OrganizationAdministration(props) {
    const [users, setUsers] = useState([])
    const [checkedRows, setCheckedRows] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [disabledUsers, setDisabledUsers] = useState(!!props.orgUsers ? props.orgUsers.filter((u) => u.deleted) : [])
    const [searchText, setSearchText] = useState("")
    const [filteredUsers, setFilteredUsers] = useState([])
    const [shownUsers, setShownUsers] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(window.location.href.includes("?showCreate"))
    const [showMultiEditModal, setMultiShowEditModal] = useState(false)
    const [userToEdit, setUserToEdit] = useState()
    const [multiSelectDialogProps, setMultiSelectDialogProps] = useState({ title: "", save: null, closeHandler: null, selected: [], type: "" })
    const [sortConfig, setSortConfig] = useState(null);
    const [warningBanner, setWarningBanner] = useState(null)
    const FilterTypes = {
        NOT_RECEIVED_WELCOME_EMAIL : "Unactivated",
        NOW_USERS: "Active Users",
        REPORTING_SERVICE: "Reporting Service",
        NEW_USER : "New",
        NO_FILTER : "All"
    }
    const [selectedFilterType, setSelectedFilterType] = useState(FilterTypes.NO_FILTER)
    const [locationFieldSettings, setLocationFieldSettings] = useState({
        location_field_enabled: false,
        location_field_mandatory: false
    })
    const [locations, setLocations] = useState([]);

    const searchProperties = ["firstname", "lastname", "email", "job_title", "department", "language"]

    const departments = [
        { value: "Procurement", label: "Procurement" },
        { value: "Logistics", label: "Logistics/Supply Chain Management" },
        { value: "Production", label: "Production" },
        { value: "Category management", label: "Category management" },
        { value: "Sourcing", label: "Sourcing" },
        { value: "Sales", label: "Sales" },
        { value: "Customer Service", label: "Customer Service" },
        { value: "Finance", label: "Finance" },
        { value: "IT", label: "IT" },
        { value: "Management", label: "Management" },
        { value: "Other", label: "Other" },
    ]

    let checkedUserText = checkedRows.length > 1 ? "users" : "user"
    const multiSelectOptions = [
        {
            label: (
                <span>
                    Set permissions for <strong>{checkedRows.length}</strong> {checkedUserText}
                </span>
            ),
            onClick: (checkedRows) => multiSelectDialogHandler("permissions", checkedRows),
        },
        {
            label: (
                <span>
                    Send welcome mail to <strong>{checkedRows.length}</strong> {checkedUserText}
                </span>
            ),
            onClick: () => sendMultipleMails(checkedRows),
        },
        {
            label: (
                <span>
                    Disable <strong>{checkedRows.length} </strong> {checkedUserText}
                </span>
            ),
            type: MultiSelectButtonType.WARNING,
            onClick: () => {
                warningDialog.current.show()
            },
        },
    ]

    const deletedUsersDialog = useRef("deletedUsersDialog")
    const warningDialog = useRef("warningDialog")

    const requestSort = (key) => {
        let direction = "asc"
        if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc"
        }
        setSortConfig({ key, direction })
    }

    const getSortDirectionIcon = (key) => {
        if (sortConfig && sortConfig.key === key) {
            return sortConfig.direction === "asc" ? "fa fa-sort-asc" : "fa fa-sort-desc"
        }
        return ""
    }

    const sortedTableData = React.useMemo(() => {
        let sortableTableData = [...shownUsers]
        if (sortConfig !== null) {
            sortableTableData.sort((a, b) => {
                let result = 0
                if (typeof a[sortConfig.key] === "number" && typeof b[sortConfig.key] === "number") {
                    result = a[sortConfig.key] - b[sortConfig.key]
                } else if (a[sortConfig.key] === ""){
                    result = 1
                } else if (b[sortConfig.key] === ""){
                    result = -1
                } else {
                    result = a[sortConfig.key].localeCompare(b[sortConfig.key], undefined, { numeric: true, sensitivity: "base" })
                }
                return sortConfig.direction === "asc" ? result : -result
            })
        }
        return sortableTableData
    }, [sortConfig, shownUsers])
    
    // hooks
    useEffect(() => {
        if (!props.orgUsers && !!props.orgID) {
            props.dispatch(getOrganizationUsers(props.orgID))
        }
        if(!props.orgLocations && !!props.orgID){
            props.dispatch(getOrganizationLocations(props.orgID))
        }
        if(props.organizationInfo){
            const lfs = {
                location_field_enabled: props.organizationInfo.location_field_enabled,
                location_field_mandatory: props.organizationInfo.location_field_mandatory
            }
            setLocationFieldSettings({...lfs});
        }
    }, [props.dispatch, props.organization, props.orgID, props.orgUsers, props.organizationInfo])

    useEffect(() => {
        if (!props.orgItems && !!props.orgID) {
            props.dispatch(getOrganizationItems(props.orgID))
        }

        if (!props.organizationInfo && !!props.orgID) {
            props.dispatch(getOrganization(props.orgID))
        }

        const users = props.orgUsers
        if (users) getUsers(users)
    }, [props.dispatch, props.organization, props.orgID, props.orgUsers, props.organizationInfo])

    useEffect(() => {
        if(!!props.orgLocations && locations.length === 0){
            setLocations(props.orgLocations.map(l => {return {value: l, label: l}}))
        }
    }, [props.orgLocations])
    
    useEffect(() => {
        if (!!props.orgUsers) {
            getUsers(props.orgUsers)
        }
    }, [!!props.orgUsers])

    useEffect(() => {
        setShowEditModal(false)
        setCheckedRows([])

    }, [disabledUsers.length])

    useEffect(() => {
        setShowCreateModal(window.location.href.includes("?showCreate"))
    }, [users.length])

    useEffect(() => {
        filterUsers(users);
    }, [selectedFilterType])

    useEffect(() => {
        if(searchText) userSearch(searchText);
    }, [filteredUsers])

    function getUsers(users) {
        const enabledUsers = users.filter((user) => !user.deleted)
        setUsers(enabledUsers)
        filterUsers(enabledUsers)
        const disabled = props.orgUsers.filter((u) => u.deleted === true)
        setDisabledUsers(disabled)
    }

    function filterUsers(users){
        setCheckedRows([]);
        setSelectAll(false);
        switch(selectedFilterType){
            case FilterTypes.NEW_USER:
                setFilteredUsers(users.filter(u => isNewUser(u)));
                if(!searchText && users.length > 0) setShownUsers(users.filter(u => isNewUser(u)))
                break;
            case FilterTypes.NOT_RECEIVED_WELCOME_EMAIL:
                setFilteredUsers(users.filter(u => isUnactivatedUser(u)));
                if(!searchText && users.length > 0) setShownUsers(users.filter(u => isUnactivatedUser(u)))
                break;
            case FilterTypes.REPORTING_SERVICE:
                setFilteredUsers(users.filter(u => isReportingServiceUser(u)));
                if(!searchText && users.length > 0) setShownUsers(users.filter(u => isReportingServiceUser(u)));
                break;
            case FilterTypes.NOW_USERS:
                setFilteredUsers(users.filter(u => !isReportingServiceUser(u) && !isUnactivatedUser(u)));
                if(!searchText && users.length > 0) setShownUsers(users.filter(u => !isReportingServiceUser(u) && !isUnactivatedUser(u)));
                break;
            case FilterTypes.NO_FILTER:
            default:
                if(users.length > 0) setFilteredUsers(users)
                if(!searchText && users.length > 0) setShownUsers(users)
        }
    }

    function sendMultipleMails(checked) {
        checked.forEach((c) => {
            props.dispatch(sendWelcomeMail(c, users.find((u) => u.id === c).email))
        })
    }

    function enableUserHandler(id) {
        setUserToEdit(id)
        const user = disabledUsers.find((u) => u.id === id)
        user.deleted = false
        props.dispatch(saveUpdatedUser(user))
    }

    function disableUserHandler(id, fromModal) {
        if (id === props.orgAdmin.info.user_id && fromModal) {
            setWarningBanner({
                type: alertTypes.DANGER,
                title: "You cannot disable your own user",
                text: "Please contact support for further information.",
            })
            setTimeout(() => {
                setWarningBanner(null)
            }, 4000)

            return
        } else {
            setWarningBanner({
                type: alertTypes.DANGER,
                title: "Disable user",
                text: "Are you sure you want to disable this user?",
                customButtons: (
                    <>
                        <button className="btn btn-outline-secondary mr-2" onClick={() => setWarningBanner(null)}>
                            Cancel
                        </button>
                        <button onClick={() => disableUser(id)} className="btn btn-danger mr-2">
                            Disable
                        </button>
                    </>
                ),
            })
        }
    }

    function disableMultiUsers() {
        props.dispatch(
            disableMultipleUsers(
                checkedRows.filter((c) => c !== props.orgAdmin.info.user_id),
                props.orgID
            )
        )

        setSelectAll(false)
    }

    function disableUser(id) {
          setUserToEdit(id)
          const user = users.find((u) => u.id === id)
          user.deleted = true
          props.dispatch(saveUpdatedUser(user))
          setWarningBanner(null)
    }
    const userSearch = (query) => {
        if (!query) {
            setShownUsers(filteredUsers)
            return
        }

        if (locationFieldSettings.location_field_enabled && !searchProperties.includes("location")){
            searchProperties.push("location")
        }

        setTimeout(() => {
            const queries = query.split(" ").map(query => query.toLowerCase())
            const filtered = filteredUsers.filter((user) => {
                const values = Object.entries(user).filter(entry => typeof entry[1] === "string" && searchProperties.includes(entry[0])).map(entry => entry[1].toLowerCase())
                return queries.every(query => {
                        return values.some(entry => {
                            return entry.includes(query)
                        })
                    })
            })
            setShownUsers(filtered)
        }, 500)
    }

    const copyUsersToClipboard = () => {
        const usersToCopy = checkedRows.length > 0 ? users.filter(u => checkedRows.includes(u.id)) : shownUsers;
        copyToClipboard(getOrgUserHTML(usersToCopy, locationFieldSettings.location_field_enabled));
        notifySuccess(usersToCopy.length + ' users has been copied to the clipboard')
    }

    // handlers
    const handleCheck = (event, user) => {
        if (event.target.checked) {
            setCheckedRows([...checkedRows, user.id])
        } else {
            setCheckedRows(checkedRows.filter((id) => id !== user.id))
        }
    }

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value)
        userSearch(event.target.value)
    }

    const editUserHandler = (user) => {
        setCheckedRows([])
        setSelectAll(false)
        setUserToEdit(user.id)
        setShowEditModal(true)
    }

    const createUserHandler = () => {
        setShowCreateModal(true)
    }

    function closeEditModal() {
        setShowEditModal(false)
        setShowCreateModal(false)
        setMultiShowEditModal(false)
    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setCheckedRows(shownUsers.map((user) => user.id))
            setSelectAll(true)
        } else {
            setCheckedRows([])
            setSelectAll(false)
        }
    }

    const multiSelectDialogHandler = (option, values) => {
        const selectedUsers = users.filter((user) => values.includes(user.id))
        setMultiShowEditModal(true)
        setMultiSelectDialogProps({ title: `Set ${option}`, type: option, selected: selectedUsers })
    }

    const getSelectedFilterIcon = (filterType) => {
        switch(filterType){
            case FilterTypes.NEW_USER:
                return <NewIcon/>
            case FilterTypes.NOT_RECEIVED_WELCOME_EMAIL:
                return <i className="fa fa-exclamation-triangle z-999 icon-orange" aria-hidden="true"></i>
            case FilterTypes.REPORTING_SERVICE:
                return <i className="fa fa-envelope z-999 icon-green" aria-hidden="true"></i>
            case FilterTypes.NO_FILTER:
            default:
                return null;
        }
    }

    return (
        <>
            <WarningAcceptDialog title="Disable users" acceptText="Disable" accept={() => disableMultiUsers()} shouldConfirm={true} ref={warningDialog} >
                <div>
                    <p>Are you sure you want to disable {checkedRows.length} user(s)?</p>
                </div>
            </WarningAcceptDialog>
            <BaseDialog title="Disabled users" ref={deletedUsersDialog} large>
                <StandardStatusTable>
                    <thead>
                        <tr className="all-white-space-nowrap">
                            <th>Name </th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {disabledUsers.map((u) => (
                            <tr className="" key={u.id}>
                                <td className="org-users-td">
                                    {u.firstname} {u.lastname}
                                </td>
                                <td className="org-users-td">{u.email}</td>
                                <td>
                                    <button className="btn btn-primary btn-xs" onClick={() => enableUserHandler(u.id)} title="Enable user">
                                        <i className="fa fa-undo" aria-hidden="true"></i> Enable
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </StandardStatusTable>

                {disabledUsers.length < 1 ? (
                    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <span style={{ textAlign: "center" }}>No users to show</span>
                    </div>
                ) : null}
            </BaseDialog>
            <OrganizationAdministrationMultiDialog closeModalHandler={closeEditModal} shown={showMultiEditModal} dispatch={props.dispatch} users={users} selected={users.filter((user) => checkedRows.includes(user.id))} {...multiSelectDialogProps} />
            <div className="org-admin-wrapper">
                <div className="d-flex justify-content-between w-100 mb-2">
                    <h1 className="h2 mt-2">
                        Manage {props.organizationName} {!!props.orgUsers && `(${users.length})`}
                    </h1>
                    <div className="d-flex align-items-center">
                        <button 
                            className="btn btn-default btn-sm mr-2" 
                            onClick={() => copyUsersToClipboard()} 
                            title={checkedRows.length > 0 ? ` Copy ${checkedRows.length} users to clipboard`:"Copy to clipboard"}
                        >
                            <i className="fa fa-copy"></i>
                            {checkedRows.length > 0 ? ` (${checkedRows.length})`:""}
                        </button>
                        <button className="btn btn-primary org-admin-add-user" onClick={createUserHandler}>
                        <i className="fa fa-plus"></i> New User
                        </button>
                    </div>
                </div>
                {!props.orgUsers ? (
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    <section className="org-admin-container">
                        <section className="d-flex justify-content-between">
                            <div className="d-flex mb-2 align-items-center w-50">
                                <span>
                                    Filtered by: 
                                </span>
                                <div className=" ml-2">
                                    <div className="btn-group" role="group">
                                        <button 
                                            className="btn btn-default btn-sm dropdown-toggle show" 
                                            id="dropdownMenu" 
                                            data-toggle="dropdown" 
                                            aria-expanded="false" 
                                            name="selectKPI"
                                        >
                                        </button>
                                        <div className="dropdown-menu scrollable-dropdown-menu " aria-labelledby="dropdownMenu scrollable-dropdown-menu" >
                                            {Object.values(FilterTypes)
                                                .filter(t => t != FilterTypes.NO_FILTER && t != selectedFilterType)
                                                .map((v, i) => {
                                                    return <button key={i} className="dropdown-item" onClick={() => {setSelectedFilterType(v)}}>{v}</button>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <ShowIf if={selectedFilterType && selectedFilterType !== FilterTypes.NO_FILTER}>
                                    <section 
                                        className="ml-2 d-flex align-items-center border rounded btn-group"
                                        onClick={() => setSelectedFilterType(FilterTypes.NO_FILTER)}
                                        type={"button"}
                                    >
                                        <span 
                                            className="px-2 no-right-border-radius d-flex align-items-center border-right"
                                        >
                                            <span className="mr-1">
                                                {selectedFilterType}
                                            </span>
                                            {getSelectedFilterIcon(selectedFilterType)}
                                        </span>
                                        <button 
                                            className="btn btn-sm no-left-border-radius org-user-filter__remove-button" 
                                            type="button" 
                                            onClick={() => setSelectedFilterType(FilterTypes.NO_FILTER)}
                                        >
                                            <span className="fa fa-times" />
                                        </button>
                                    </section>
                                </ShowIf>
                            </div>
                            <section className={"d-flex justify-content-end mb-2 align-items-center"}>
                                <span className="mr-2">
                                    Showing {shownUsers.length} of {users.length}{" "}
                                </span>
                                <div>
                                    <label htmlFor="org-admin-search-input" className="org-admin-label d-flex">
                                        <input id="org-admin-search-input" className="org-admin-search-field org-admin-text-input " type="text" placeholder="Search for name, email, job title etc." value={searchText} onChange={handleSearchTextChange} />
                                        <span className="btn org-admin-search-button">
                                            <i className="fa fa-search"></i>
                                        </span>
                                    </label>
                                </div>
                            </section>
                        </section>
                        <MultiSelectBar selected={checkedRows} options={multiSelectOptions} type="user" onDeselectClick={() => {setCheckedRows([]); setSelectAll(false)}}></MultiSelectBar>
                        <section className="org-admin-card">
                            <table className="org-users-table">
                                <thead>
                                    <tr className="org-users-tr">
                                        <th className="org-users-th">
                                            <label className="org-admin-checkbox">
                                                <input className="org-admin-input" type="checkbox" checked={selectAll} onChange={(event) => handleSelectAll(event)} />
                                                <span></span>
                                            </label>
                                        </th>
                                        <th className="org-users-th" title="Sort on name" onClick={() => requestSort("firstname")}>
                                            <span className="mr-1">Name</span>
                                            <i className={getSortDirectionIcon("firstname")}></i>
                                        </th>
                                        <th className="org-users-th" title="Sort on email" onClick={() => requestSort("email")}>
                                            <span className="mr-1">Email</span>
                                            <i className={getSortDirectionIcon("email")}></i>
                                        </th>
                                        <th className="org-users-th" title="Sort on job title" onClick={() => requestSort("job_title")}>
                                            <span className="mr-1">Job Title</span>
                                            <i className={getSortDirectionIcon("job_title")}></i>
                                        </th>
                                        <th className="org-users-th" title="Sort on department" onClick={() => requestSort("department")}>
                                            <span className="mr-1">Department</span>
                                            <i className={getSortDirectionIcon("department")}></i>
                                        </th>
                                        <ShowIf if={locationFieldSettings.location_field_enabled} >
                                            <th className="org-users-th" title="Sort on location" onClick={() => requestSort("location")}>
                                                <span className="mr-1">Location</span>
                                                <i className={getSortDirectionIcon("location")}></i>
                                            </th>
                                        </ShowIf>
                                        <th className="org-users-th" title="Sort on language" onClick={() => requestSort("language")}>
                                            <span className="mr-1">Language</span>
                                            <i className={getSortDirectionIcon("language")}></i>
                                        </th>
                                        <th className="org-users-th" title="Sort on last login" onClick={() => requestSort("last_login")}>
                                            <span className="mr-1">Last Login</span>
                                            <i className={getSortDirectionIcon("last_login")}></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="org-users-tbody">
                                    {sortedTableData.map((user) => (
                                        <tr className="org-users-tr" key={user.id}>
                                            <td className="org-users-td">
                                                <label className="org-admin-checkbox">
                                                    <input className="org-admin-input" type="checkbox" checked={checkedRows.includes(user.id)} onChange={(event) => handleCheck(event, user)} />
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td className="org-users-td" onClick={() => editUserHandler(user)}>
                                                {user.firstname} {user.lastname}
                                                <ShowIf if={isUnactivatedUser(user)}>
                                                    <span 
                                                        className="org-user-category-icon-wrapper"
                                                        onClick={(e) => {e.stopPropagation(); setSelectedFilterType(FilterTypes.NOT_RECEIVED_WELCOME_EMAIL)}}
                                                    >
                                                        {getSelectedFilterIcon(FilterTypes.NOT_RECEIVED_WELCOME_EMAIL)}
                                                    </span>
                                                </ShowIf>
                                                <ShowIf if={isNewUser(user)}>
                                                    <span
                                                        className="org-user-category-icon-wrapper"
                                                        onClick={(e) => {e.stopPropagation(); setSelectedFilterType(FilterTypes.NEW_USER)}}
                                                    >
                                                        <i 
                                                            title={user.firstname + " " + user.lastname + " was created less than a month ago"}
                                                        >
                                                            {getSelectedFilterIcon(FilterTypes.NEW_USER)}
                                                        </i>
                                                    </span>
                                                </ShowIf>
                                                <ShowIf if={isReportingServiceUser(user)}>
                                                    <span
                                                        className="org-user-category-icon-wrapper"
                                                        onClick={(e) => {e.stopPropagation(); setSelectedFilterType(FilterTypes.REPORTING_SERVICE)}}
                                                    >
                                                        <i 
                                                            title={user.firstname + " " + user.lastname + " is a reporting service only user"}
                                                        >
                                                            {getSelectedFilterIcon(FilterTypes.REPORTING_SERVICE)}
                                                        </i>
                                                    </span>
                                                </ShowIf>

                                            </td>
                                            <td className="org-users-td" onClick={() => editUserHandler(user)}>
                                                {user.email}
                                            </td>
                                            <td className="org-users-td" onClick={() => editUserHandler(user)}>
                                                {user.job_title}
                                            </td>
                                            <td className="org-users-td" onClick={() => editUserHandler(user)}>
                                                {user.department}
                                            </td>
                                            <ShowIf if={locationFieldSettings.location_field_enabled} >
                                                <td className="org-users-td" onClick={() => editUserHandler(user)}>
                                                    {user.location ? user.location : "-"}
                                                </td>
                                            </ShowIf>
                                            <td className="org-users-td" onClick={() => editUserHandler(user)}>
                                                {user.language}
                                            </td>
                                            <td className="org-users-td" onClick={() => editUserHandler(user)} title={formatDateTime(user.last_login)}>
                                                {getLastLogin(user)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {shownUsers.length === 0 && <div className="text-align-center italic">There is no users to show</div>}
                        </section>
                        <button
                            className="btn btn-default btn-sm align-self-end"
                            onClick={(e) => {
                                deletedUsersDialog.current.show()
                            }}
                        >
                            Disabled users ({disabledUsers.length})
                        </button>
                    </section>
                )}
            </div>
            {showEditModal && 
                <OrganizationAdministrationEdit 
                    alertConfig={warningBanner} 
                    closeModalHandler={() => closeEditModal()} 
                    shown={showEditModal} 
                    userId={userToEdit} 
                    items={props.orgItems} 
                    departments={departments} 
                    disableUser={disableUserHandler} 
                    locationFieldSettings={locationFieldSettings}
                    locations={locations}
                    setLocations={setLocations} 
                    {...props} 
                />
            } 
            {showCreateModal && 
                <OrganizationAdministrationCreate 
                    closeModalHandler={() => closeEditModal()} 
                    shown={showCreateModal} 
                    departments={departments} 
                    items={props.orgItems} 
                    locationFieldSettings={locationFieldSettings}
                    locations={locations}
                    setLocations={setLocations}  
                    {...props} 
                />
            }
        </>
    )
}

export default connect(mapStateToProps)(OrganizationAdministration)
