import React, { useEffect, useRef, useState } from 'react'
import { getCanvasFont, getTextWidth } from '../../helpers/TextHelpers'

interface ResponsiveButtonProps {
    children: React.ReactNode,
    label: string,
    responsiveLabel: string,
    onClick: () => void,

    className?: string,
    style?: React.CSSProperties,
}

const ResponsiveButton = (props: ResponsiveButtonProps) => {
    const [showResponsive, setShowResponsive] = useState(false)
    let textRef = useRef<any>(null)
    let showTimeoutRef = useRef<any>(null)

    useEffect(() => {
        // Cleanup
        return () => {
            clearTimeout(showTimeoutRef.current)
        }
    },[])

    let onClick = () => {
        setShowResponsive(true)
        clearTimeout(showTimeoutRef.current)
        showTimeoutRef.current = setTimeout(() => {
            setShowResponsive(false)
        }, 2000)
        
        props.onClick()
    }

    let text = ""
    if (!showResponsive) {
        text = props.label
    } else {
        text = props.responsiveLabel
    }
    let textWidth = "100%";
    if (textRef.current != null) {
        textWidth = getTextWidth(text, getCanvasFont(textRef.current))
    }
    return (
        <div onClick={onClick} style={props.style ?? {}} className={`responsive-button-container abc-click ${props.className ?? ''}`}>
            {props.children}
                <span ref={textRef} className='responsive-button-label' style={{width: textWidth}}>
                    {text}
                </span>
        </div>
    )

    
}

export default ResponsiveButton