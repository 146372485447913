import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet, apiPost, apiPut, apiDelete } from '../../helpers/ApiHelpers'
import { gotHistoryData, gotAggregationData, gotMultipleAggregationsData, gotTrendsetsData, gotDiffData, gotActualGraphData, gotReportGridData, toggleFavourite, toggleFavouriteDone, gotSlicerValues, gotGroups, getReportInfo, gotAllBuckets, updatedIsPublic, gotTotalBucketData, canDeleteReportDone, reportHasErrors, jumpToCompose, gotSumViewData, gotHistoryProfitData, gotSlicerAvailableValues, getSlicerAvailableValues, toggleFavouriteReportsDone } from '../../actions/ReportActions'
import { notifySuccess } from '../../helpers/NotificationManager'
import { getFavouriteReportInfo, getHomeInfo, getLibraryInfo } from '../../actions/InfoActions'
import { notifyApiFailure, getApiErrorInfo } from '../../helpers/ErrorHelpers'
import { reportError } from '../../actions/SystemActions'


const ReportApiMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.TOGGLE_FAVOURITE:
            url = 'report/toggle_favourite'
            data = action.payload.data
            apiPost(url, data, result => {
                notifySuccess("Successfully " + (result ? "added" : "removed"))
                dispatch(toggleFavouriteDone(result))
                dispatch(getHomeInfo())
                dispatch(getFavouriteReportInfo())
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure(`toggling Insight`, 'POST', url, data, e.status))
            break
        case ActionTypes.TOGGLE_FAVOURITE_REPORTS:
            url = `favourites/update/${action.payload.userID}`
            data = action.payload.favouriteItems;
            apiPost(url, {"favourites": data}, result => {
                dispatch(toggleFavouriteReportsDone(result))
            }, e=> notifyApiFailure("updating favourite items", 'POST', url, data, e.status))
            break
        case ActionTypes.GET_HISTORY_DATA:
            url = `report/data/history/${action.payload.id}`
            data = action.payload.data
            apiPost(url, data,
                result => dispatch(gotHistoryData(action.payload.id, result)),
                e => notifyApiFailure(`getting KPI trend graph`, 'POST', url, data, e.status)
            )
            break
        case ActionTypes.GET_HISTORY_PROFIT_DATA:
            url = `report/data/history/${action.payload.id}`
            data = action.payload.data
            apiPost(url, data,
                result => dispatch(gotHistoryProfitData(action.payload.id, result, action.payload.columns)),
                e => notifyApiFailure(`getting KPI trend graph`, 'POST', url, data, e.status)
            )
            break
        case ActionTypes.GET_REPORT_AGGREGATION:
            url = `report/aggregate/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotAggregationData(action.payload.bId, action.payload.rId, result, action.payload.column, action.payload.key))
            }, reportDataRetrievalErrorCheck(`getting data aggregation`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_BUCKET_TOTAL:
            url = `report/aggregate/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotTotalBucketData(action.payload.bId, action.payload.rId, result, action.payload.column, action.payload.key))
            }, reportDataRetrievalErrorCheck(`getting data aggregation`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_REPORT_MULTIPLE_AGGREGATIONS:
            url = `report/aggregate_multiple/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotMultipleAggregationsData(result, action.payload.rId, action.payload.prefix, action.payload.key))
            }, reportDataRetrievalErrorCheck(`getting data aggregations`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_REPORT_TRENDSETS:
            url = `bucket/${action.payload.bId}/trendsets`
            apiGet(url, result => {
                dispatch(gotTrendsetsData(result, action.payload.rId, action.cb))
            }, reportDataRetrievalErrorCheck(`getting trendsets`, 'GET', url, data, dispatch))
            break
        case ActionTypes.GET_REPORT_DIFF:
            url = `report/diff/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotDiffData(action.payload.rId, result))
            }, reportDataRetrievalErrorCheck(`getting difference graph`, 'POST', url, data, dispatch))
            break
        case ActionTypes.DOWNLOAD_REPORT_DIFF_GRID_DATA:
            url = `report/download/diff/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                //do nothing, a notification should already have been sent
            }, reportDataRetrievalErrorCheck(`preparing data to download`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_ACTUAL_GRAPH_DATA:
            url = `report/graphdata/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotActualGraphData(result))
            }, reportDataRetrievalErrorCheck(`getting pareto graph`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_REPORT_GRID_DATA:
            url = `report/fetchdata/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotReportGridData(result))
            }, reportDataRetrievalErrorCheck(`getting table data`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_SUM_VIEW_DATA:
            url = `report/sum/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotSumViewData(action.payload.rId, action.payload.data.group_column, action.payload.key, result))
            }, reportDataRetrievalErrorCheck(`getting sum data`, 'POST', url, data, dispatch))
            break
        case ActionTypes.DOWNLOAD_REPORT_GRID_DATA:
            url = `report/download/fetchdata/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                //do nothing, a notification should already have been sent
            }, reportDataRetrievalErrorCheck(`preparing data to download`, 'POST', url, data, dispatch))
            break
        case ActionTypes.GET_REPORT_SLICER_VALUES:
            url = `report/column_values/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotSlicerValues(result, action.payload.data.column))
                dispatch(getSlicerAvailableValues(action.payload.bId, {column: action.payload.data.column, filter: action.payload.filter}))
            }, e => notifyApiFailure(`getting slicer options`, 'POST', url, data, e.status))
            break
        case ActionTypes.GET_REPORT_SLICER_AVAILABLE_VALUES:
            url = `report/column_values/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                dispatch(gotSlicerAvailableValues(result, action.payload.data.column))
            }, e => notifyApiFailure(`getting slicer available options`, 'POST', url, data, e.status))
            break
        case ActionTypes.CREATE_CHECKLIST_FROM_REPORT:
            url = `report/checklist/${action.payload.bId}`
            data = action.payload.data
            apiPost(url, data, result => {
                window.location.hash = `/checklist/${result.id}`
            }, e => notifyApiFailure(`creating Action list from Insight`, 'POST', url, data, e.status))
            break
        case ActionTypes.GET_REPORT_GROUPS:
            url = `report/group`
            apiGet(url, result => {
                dispatch(gotGroups(result))
            }, e => notifyApiFailure("getting Insight groups", 'GET', url, data, e.status))
            break
        case ActionTypes.UPDATE_REPORT:
            url = `report/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("Insight saved!")
                dispatch(getReportInfo(result.report_id)) // TODO: should maybe not be called FIXME: lol
                // dispatch(gotReportInfo(data))
            }, e => notifyApiFailure("saving Insight", 'PUT', url, data, e.status))
            break
        case ActionTypes.MAKE_REPORT_PUBLIC:
            url = `report/${action.payload.id}`
            data = Object.assign({}, action.payload.data)
            data.is_public = true
            apiPut(url, data, result => {
                notifySuccess("Insight is now public!")
                dispatch(updatedIsPublic(action.payload.id, action.payload.data))
            }, e => notifyApiFailure("making Insight public", 'PUT', url, data, e.status))
            break
        case ActionTypes.UPDATE_REPORT_KPIS:
            url = `report/kpis/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("KPI setup was saved!")
                dispatch(getReportInfo(result.report_id))
            }, e => notifyApiFailure("saving Insight KPIs", 'PUT', url, data, e.status))
            break
        case ActionTypes.UPDATE_VIEWSETTINGS:
            url = `report/viewSettings/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("Setting was saved")
                dispatch(getReportInfo(result.report_id))
            }, e => notifyApiFailure("saving view settings", 'PUT', url, data, e.status))
            break
        case ActionTypes.UPDATE_KPIS_VIEWSETTINGS:
            url = `report/kpisViewSettings/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("KPI setup was saved!")
                dispatch(getReportInfo(result.report_id))
            }, e => notifyApiFailure("setting KPI view settings", 'PUT', url, data, e.status))
            break
        case ActionTypes.UPDATE_REPORT_KPICONFIG:
            url = `report/kpiConfig/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("KPIs view was saved")
                dispatch(getReportInfo(result.report_id))
            }, e => notifyApiFailure("saving KPI configuration", 'PUT', url, data, e.status))
            break
        case ActionTypes.REMOVE_COLUMNS:
            url = `report/remove/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, info => {
                notifySuccess("Columns were removed from the Insight")
                switch (action.payload.data.view) {
                    case 'home':
                        dispatch(getHomeInfo())
                        break
                    case 'item':
                        dispatch(ActionTypes.packAction(ActionTypes.GET_ITEM_LINKS, { bucket: action.payload.data.bucket, item: action.payload.data.item }))
                        break
                    case 'library':
                        dispatch(getLibraryInfo())
                        break
                    default:
                        console.log("View was not found!")
                }
            }, e => notifyApiFailure("removing column from Insight", 'PUT', url, data, e.status))
            break
        case ActionTypes.REPORT_MANUAL_FIX:
            url = 'report/fix'
            data = action.payload.data
            console.log(action)
            apiPut(url, data, info => {
                notifySuccess("Insight changed successfully")
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure("fixing Insight", 'PUT', url, data, e.status))
            break
        case ActionTypes.COPY_REPORT:
            url = `report`
            let addToFavorites 
            ({addToFavorites, ...data} = action.payload.data)
            //data = action.payload.data
            apiPost(url, data, result => {
                notifySuccess('New Insight created!')
                if (action.payload.redirect) {
                    window.location.hash = `/report/${result.report_id}`
                }

                if(addToFavorites) {
                    let favdata = {
                        favourited: addToFavorites,
                        item_type: 'report',
                        item_id: result.report_id
                    }
                    dispatch(toggleFavourite(favdata))
                }
                dispatch(getLibraryInfo())
                if (action.payload.redirect){
                    dispatch(jumpToCompose(result.report_id))
                }
            }, e => notifyApiFailure(`copying Insight`, 'POST', url, data, e.status))
            break
        case ActionTypes.DELETE_REPORT:
            url = `report/${action.payload.id}`
            apiDelete(url, () => {
                notifySuccess("Insight was deleted")
                if (action.payload.fromLibrary) {
                    dispatch(getLibraryInfo())
                    window.location.hash = `/library`
                } else {
                    dispatch(getHomeInfo())
                    window.location.hash = `/`
                }
            }, e => notifyApiFailure("deleting Insight", 'DELETE', url, data, e.status))
            break
        case ActionTypes.CAN_DELETE_REPORT:
            url = `report/check/${action.payload.id}`
            apiGet(url, result => {
                dispatch(canDeleteReportDone(action.payload.id, result))
            }, e => notifyApiFailure("can delete Insight", 'GET', url, data, e.status))
            break
        case ActionTypes.MOVE_GROUP:
            url = `report/group/move/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("Group was moved!")
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure("moving Insight group", 'PUT', url, data, e.status))
            break
        case ActionTypes.ASSIGN_GROUP:
            url = `report/group/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, result => {
                notifySuccess("Insight was moved to new group!")
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure("assigning Insight to group", 'PUT', url, data, e.status))
            break
        case ActionTypes.DELETE_GROUP:
            url = `report/group/${action.payload.id}`
            apiDelete(url, () => {
                notifySuccess("Group was deleted!")
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure("deleting Insight group", 'DELETE', url, data, e.status))
            break
        case ActionTypes.CREATE_GROUP:
            url = `report/group`
            data = action.payload.data
            apiPost(url, data, result => {
                notifySuccess('New group created!')
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure(`creating Insight group`, 'POST', url, data, e.status))
            break
        case ActionTypes.EDIT_GROUP:
            url = `report/group/update/${action.payload.id}`
            data = action.payload.data
            apiPut(url, data, res => {
                notifySuccess("Group name was changed.")
                dispatch(getLibraryInfo())
            }, e => notifyApiFailure("Editing group name", "POST", url, data, e.status))
            break
        case ActionTypes.GET_ALL_BUCKETS:
            url = `bucket`
            apiGet(url, result => {
                dispatch(gotAllBuckets(result))

            }, e => notifyApiFailure("loading all data buckets", 'GET', url, data, e.status))
            break
        case ActionTypes.SAVE_REPORT_DESCRIPTION:
            url = `report/description`
            data = action.payload
            apiPost(url, data, (result) => {
                //Nothing?
            }, e => notifyApiFailure(`saving Insight description`, 'POST', url, data, e.status))
            break
        default:
            break
    }
}

const reportDataRetrievalErrorCheck = (actionText, method, url, data, dispatch) => e => {
    if (e.status === 500) {
        dispatch(reportHasErrors())
        dispatch(reportError(getApiErrorInfo(method, url, data, e.status), 1, -1))
    } else {
        notifyApiFailure(actionText, method, url, data, e.status)
    }
}

export default ReportApiMiddleware
