import React from 'react'
import '../../css/colors.css'
import ShowIf from '../Generic/ShowIf'

class ChecklistPanel extends React.Component {
    state = {
        collapsed: false
    }

    render() {
        return (
            <div className={`card abc-list-panel bg-light`}>
                <div className="card-header">
                    <h3 className="checklist-item-group-heading">
                        <ShowIf if={this.props.number > 0}>
                            <div style={{width:13, marginRight:"0.75rem", display:"inline-block", textAlign:"center"}} >
                            { this.state.collapsed ? 
                                <i className={"abc-click fa fa-chevron-right"} onClick={() => this.setState({collapsed: false})}></i>
                            :
                                <i className={"abc-click fa fa-chevron-down"} onClick={() => this.setState({collapsed: true})}></i>
                            }
                            </div>
                        </ShowIf>
                        <ShowIf  if={this.props.number == 0}>
                            <div style={{width:13, marginRight:"0.75rem", display:"inline-block", textAlign:"center"}} >
                            <i className={"fa fa-chevron-right"} style={{color:"#c9c9c9"}}></i>
                            </div>
                        </ShowIf>
                        <span className={`badge font-size-14px ${this.props.color === 'black' ? 'btn-dark' : this.props.color === 'btn-primary' ? 'btn-old-primary' : this.props.color}`}>{this.props.number}</span> {this.props.title}
                        <ShowIf if={this.props.number > 0}>
                            <button 
                                className="btn btn-default btn-xs float-right" 
                                title="Copy table to clipboard" 
                                onClick={this.props.copyToClipboard}
                            >
                                <span className="fa fa-copy" />
                            </button>
                        </ShowIf>
                    </h3>
                </div>
                <div className="card-body bg-white">
                    {this.state.collapsed ? null : this.props.children}
                </div>
            </div>                
        )
    }
}

export default ChecklistPanel