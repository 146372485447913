import React, { useEffect, useState } from 'react'
import { DashboardFull, DashboardWidget, LibraryReport, ParsedDashboardData, User } from '../../types/transfertypes'
import GenericDialog from './GenericDialog'
import ClickableDashboardTable from '../DashboardAdmin/ClickableDashboardTable'
//@ts-ignore
import Select from '../Generic/CustomSelect'
import { SelectOption } from '../../helpers/TypeHelpers'
import InfoTooltip from '../Generic/InfoTooltip'
import ResizingTextarea from '../Generic/ResizingTextarea'
import { getWidgetDefaultName } from '../../helpers/DashboardHelpers'

export interface EditWidgetDialogProps {
    show: boolean,
    widget?: DashboardWidget,
    saveWidget: (widget: DashboardWidget) => void,
    onClose: () => void,
    dashboard: DashboardFull,
    parsedDashboard: ParsedDashboardData,
    user: User,
    reports: LibraryReport[],
}


const EditWidgetDialog = (props: EditWidgetDialogProps) => {

    const [tmpLabel, setTmpLabel] = useState("")
    const [tmpRowId, setTmpRowId] = useState<string | undefined>(undefined)
    const [tmpKpiKey, setTmpKpiKey] = useState<string | undefined>(undefined)
    const [tmpTrend, setTmpTrend] = useState(0)

    useEffect(() => {
        if (!props.show) {
            // Reset when hide
            setTmpLabel("")
            setTmpRowId(undefined)
            setTmpKpiKey(undefined)
            setTmpTrend(0)
            return
        }

        // If editing an existing widget
        if (props.widget != undefined && props.widget.shown) {
            setTmpLabel(props.widget.label)
            setTmpRowId(props.widget.row_id)
            setTmpKpiKey(props.widget.kpi_key)
            setTmpTrend(props.widget.trend)
        }
    }, [props.show, props.widget])

    const saveWidget = () => {
        if (tmpRowId == undefined || tmpKpiKey == undefined || props.widget == undefined) return
        let tmp: DashboardWidget = {
            kpi_key: tmpKpiKey,
            shown: true,
            label: tmpLabel,
            trend: tmpTrend,
            row_id: tmpRowId,
            id: props.widget.id
        }
        props.saveWidget(tmp)
    }

    const disableSave = () => {
        if (tmpRowId == undefined || tmpKpiKey == undefined || props.widget == undefined) return true
        let tmp: DashboardWidget = {
            kpi_key: tmpKpiKey,
            shown: true,
            label: tmpLabel,
            trend: tmpTrend,
            row_id: tmpRowId,
            id: props.widget.id,
        }
        return tmp.kpi_key == props.widget?.kpi_key
                && tmp.trend == props.widget.trend
                && tmp.label == props.widget.label
                && tmp.row_id == props.widget.row_id
    }

    const onCellClick = (row: string, kpi: string) => {
        setTmpRowId(row)
        setTmpKpiKey(kpi)
    }

    const getButtons = () => {
        return <div>
            <button style={{ marginRight: 7 }} onClick={saveWidget} disabled={disableSave()} className='btn btn-primary'>Save</button>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    const trendOptions: SelectOption<number>[] = [
        {
            value: 0,
            label: "Neutral",
        },
        {
            value: 1,
            label: "Positive",
            extra: {up: {color: "#0FA359"}, down: {color: "#D30D2A"}}
        },
        {
            value: -1,
            label: "Negative",
            extra: {up: {color: "#D30D2A"}, down: {color: "#0FA359"}}
        }
    ]

    const formatOptionLabel = (data: SelectOption<number>) => {
//        let style = {}
//        if (tmpTrend == data.value) {
//            style = {backgroundColor: "white", borderRadius: 4, color: "black", padding: "3px 5px", width: "fit-content"}
//        }
        
        return <div className="d-flex">
            <div style={{width: 65}}>{data.label}</div>
            <i style={data.extra ? data.extra.up : {}} className="fa fa-arrow-up margin-top-2px" aria-hidden="true"></i>
            <i style={data.extra ? data.extra.down : {}} className="fa fa-arrow-down margin-top-2px ml-1" aria-hidden="true"></i>

        </div>
    }

    const colourStyles: any = {
        option: ((styles: React.CSSProperties, {isSelected}: any) => {
                     return {
                         ...styles,
                         backgroundColor: "white",
                         fontWeight: isSelected ? "bold" : "normal",
                         color: "black"
                     }
                 }),
                 control: (styles:any) => ({ 
                     ...styles,
                     ':focus-within' : { 
                         borderColor: "var(--tangerine-hover)", 
                         boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                     }
                 })
    }


    return (
        <div>
            <GenericDialog onClose={props.onClose} title='Edit widget' show={props.show} getButtons={getButtons} >
                <label htmlFor='widget-label'>Label</label>
                <button className={'btn btn-default btn-xs margin-left-5px'} value={tmpLabel} onClick={(e) => setTmpLabel(getWidgetDefaultName(tmpRowId, tmpKpiKey, props.parsedDashboard, props.reports) !== 'No table cell selected' ? getWidgetDefaultName(tmpRowId, tmpKpiKey, props.parsedDashboard, props.reports) : tmpLabel)} > set default label</button>
                <ResizingTextarea id='widget-label' placeholder={getWidgetDefaultName(tmpRowId, tmpKpiKey, props.parsedDashboard, props.reports)} className='form-control label-text-area' value={tmpLabel} onChange={(e) => setTmpLabel(e.target.value)}  />
                <label className='mt-3'> Trend <InfoTooltip text="An upwards trend should be seen as..." />
                </label>
                <Select 
                    options={trendOptions}
                    formatOptionLabel={formatOptionLabel}
                    onChange={(v : SelectOption<number>) => setTmpTrend(v.value)}
                    value={trendOptions.find(v => v.value == tmpTrend)}
                    styles={colourStyles}

                />
                <div className='kpi-table-wrapper mt-3'>
                    <ClickableDashboardTable
                        dashboard={props.dashboard}
                        parsedDashboard={props.parsedDashboard}
                        user={props.user}
                        selectedRow={tmpRowId}
                        selectedKpi={tmpKpiKey}
                        onCellClick={onCellClick}
                        reports={props.reports}
                    />
                </div>


            </GenericDialog>
        </div>
    )
}

export default EditWidgetDialog
