import * as ActionTypes from '../actions/ActionTypes'
import { SingleKeyMap } from '../helpers/Collections'

const initialState = {
    altReportInfo: new SingleKeyMap(),
    reportExists: true,
    checklistItemsBeingUpdated: false,
    reportAccessible: true,
}

const Info = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_HOME_INFO_DONE:
            return Object.assign({}, state, { info: action.payload })
        case ActionTypes.GET_LIBRARY_INFO_DONE:
            return Object.assign({}, state, { libraryInfo: action.payload })
        case ActionTypes.GET_LIBRARY_ADMIN_INFO_DONE:
            return Object.assign({}, state, { libraryAdminInfo: action.payload })
        case ActionTypes.GET_REPORT_INFO:
            return Object.assign({}, state, { reportExists: true, reportAccessible: true }) 
        case ActionTypes.GET_REPORT_INFO_NOT_FOUND:
            return Object.assign({}, state, { reportExists: false }) 
        case ActionTypes.GET_REPORT_INFO_NOT_ACCESSIBLE:
            return Object.assign({}, state, { reportAccessible: false }) 
        case ActionTypes.GET_REPORT_INFO_DONE:
            let newInfo = new SingleKeyMap()
            newInfo.set(action.payload.report.report_id, action.payload)
            return Object.assign({}, state, { reportInfo: action.payload, altReportInfo: newInfo })
            // return Object.assign({}, state, { reportInfo: action.payload})
        case ActionTypes.MAKE_REPORT_PUBLIC_DONE:
            let reportInfo = Object.assign({}, state.reportInfo)
            reportInfo.report.is_public = true
            let altInfo = new SingleKeyMap(state.altReportInfo)
            altInfo.set(action.payload.id, reportInfo)
            console.log(reportInfo)
            return Object.assign({}, state, { reportInfo: reportInfo, altReportInfo: altInfo })
        case ActionTypes.UPDATE_CHECKLIST_ITEMS_MULTIPLE:
            return Object.assign({}, state, { checklistItemsBeingUpdated: true })
        case ActionTypes.GET_HOME_INFO:
            return Object.assign({}, state, { checklistItemsBeingUpdated: false })
        default:
            return state
    }
}

export default Info