import React from 'react'
import KPIBannerBase from '../../KPIBannerBase'
import KPIElementBase from '../../KPIElementBase'
import KPIReportAddElement from '../ReportComponents/KPIReportAddElement'
import { getKpiValue, shouldReportInfoChangeUpdateData } from '../../../helpers/ReportHelpers'
import { connect } from 'react-redux'
import { getMultipleAggregationsData } from '../../../actions/ReportActions'
import { getFilter } from '../../../helpers/ReportHelpers'
import { hashNValues } from '../../../helpers/GeneralHelpers'
import LoadingIcon from '../../LoadingIcon'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import ShowIf from '../../Generic/ShowIf'

const mapStateToProps = (state, ownProps) => {
    return {
        aggregationdata: state.Report.aggregationdata,
        loading: state.Loading.loadingKPIMultiple,
    }
}

const SortableBanner = SortableContainer(({children, className}) => {
    return <div className={className}>{children}</div>
})

const SortableKPIElement = SortableElement(({kpiIndex, width, editMode, loading, aggregation, clicked, clickable, column, value, highlight}) => {
    return <KPIReportElement 
        index={kpiIndex} 
        width={width} 
        column={column}
        aggregation={aggregation} 
        value={value} 
        clicked={clicked} 
        clickable={clickable}
        editMode={editMode} 
        loading={loading}
        highlight={highlight}
    />
})


class KPIReportBanner extends KPIBannerBase {
    state = {
        kpiToEdit: -1,
    }

    extraClass() {
        return 'margin-top-8px kpi-height'
    }

    componentDidMount() {
        if (this.props.reportdata.info && this.props.slicers) {
            this.getKPIAggregationData()
        }
    }

    selectKpi(index) {
        if (this.props.editMode) {
            this.setState({ kpiToEdit: index })
        } else if(this.props.clickable) {
            let kpis = JSON.parse(this.props.reportdata.report.kpis)
            if (kpis[index]) {
                //Forward to parent
                this.props.kpiClicked(kpis[index], index)    
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.slicers) !== JSON.stringify(this.props.slicers) || shouldReportInfoChangeUpdateData(prevProps.reportdata, this.props.reportdata)) {
            this.getKPIAggregationData()
        }

        if (prevProps.editMode !== this.props.editMode && !this.props.editMode) {
            this.setState({ kpiToEdit: -1 })
        }
    }

    saveKpi(kpi, index) {
        let kpis = JSON.parse(this.props.reportdata.report.kpis)

        if (kpis[index]) {
            kpis[index] = kpi
        } else {
            kpis.push(kpi)
        }

        this.props.setData("report", "kpis", JSON.stringify(kpis), () => {
            let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if (kpis.length > 0 && (!viewSettings || !viewSettings.settings || viewSettings.settings.trend === undefined)) {
                let settings = Object.assign({}, viewSettings.settings)
                settings.trend = true
                viewSettings.settings = settings
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
            }
        })
        this.setState({ kpiToEdit: -1 })
    }

    resetKpi(index) {
        if (index !== undefined && index !== null) {
            let kpis = JSON.parse(this.props.reportdata.report.kpis)
            kpis.splice(index, 1)

            let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if (kpis.length === 0 && viewSettings.settings && viewSettings.settings.default_view === 'trend') {
                let view = this.getSectionString(viewSettings.settings)
                viewSettings.settings.default_view = view
            }
            if (kpis.length === 0 && viewSettings.settings && viewSettings.settings.trend) {
                viewSettings.settings.trend = undefined
            }
            this.props.setData("report", "kpis", JSON.stringify(kpis), () => {
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
                this.setState({ kpiToEdit: -1 })
            })
        }
    }

    getSectionString(settings) {
        for (const key in settings) {
            if (settings[key] && typeof settings[key] === 'boolean' && settings[key] !== 'trend') {
                return key
            }
        }
    }

    getDivider(index) {
        if( index > 0)
            return <div key={'divider_'+index} style={{position:"relative", width:0}}> <div className='report-kpi-element-divider'></div></div>
        else
            return <></>
    }

    cancel() {
        this.setState({ kpiToEdit: -1 })
    }

    getKPIKey() {
        let reportdata = this.props.reportdata
        let limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit
        let filter = getFilter(reportdata.report, this.props.slicers)
        return hashNValues(limit, filter)
    }

    getKPIAggregationData() {
        let { dispatch } = this.props

        let reportdata = this.props.reportdata

        let kpis = JSON.parse(reportdata.report.kpis)
        let limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit
        let filter = getFilter(reportdata.report, this.props.slicers)
        let data = {
            group_column: reportdata.info.model.categorization_name,
            filter: filter,
            sort_column: reportdata.report.sort_column === '-' ? null : reportdata.report.sort_column,
            sort_direction: reportdata.report.sort_direction === '-' ? null : reportdata.report.sort_direction,
            limit: limit,
            kpis: reportdata.report.kpis,
        }

        let queries = []

        kpis.forEach((item, index) => {
            let _data = Object.assign({}, data, { aggregate_column: item.column })
            queries.push(_data)
        })

        dispatch(getMultipleAggregationsData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, { queries: queries }, '', this.getKPIKey()))
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let kpis = JSON.parse(this.props.reportdata.report.kpis)
        kpis.splice(newIndex, 0, kpis.splice(oldIndex, 1)[0])  
        this.props.setData("report", "kpis", JSON.stringify(kpis))
    }

    content() {
        let id = this.props.reportdata.report ? this.props.reportdata.report.report_id : -1
        let kpiAggData = this.props.aggregationdata.get2(this.getKPIKey(), id)
        let kpis = this.props.reportdata.report ? JSON.parse(this.props.reportdata.report.kpis) : []
        let columns = JSON.parse(this.props.reportdata.report.columns)
        let settings = this.props.getTrendSettings()

        let width = "3";
        let total = "8";

        let boxesToShow = kpis.length;

        if(this.props.editMode){
            boxesToShow = boxesToShow +1
        }
        
        if(boxesToShow == 3){
            width = "4";
            total = "6";
        } else if(boxesToShow == 2){
            width = "6";
            total = "4";
        } else if(boxesToShow == 1){
            width = "12";
            total = "2";
        }

        columns.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        })

        return (
            <div style={{width:"100%", display: "flex", justifyContent: "center"}} >
                <div className={`col-md-${total}`}>
                    <SortableBanner className="row kpi-row" onSortEnd={this.onSortEnd} lockAxis="x" axis="x" useDragHandle>
                        {
                            kpis.map((k, index) => {
                                let value = getKpiValue(k, kpiAggData)

                                let divider = this.getDivider(index)

                                if (index === this.state.kpiToEdit) {
                                    return <React.Fragment key={index}>{divider}<KPIReportAddElement
                                        index={index}
                                        saveKpi={(kpi, index) => this.saveKpi(kpi, index)}
                                        kpi={k}
                                        columns={columns}
                                        reportdata={this.props.reportdata}
                                        width={width}
                                        selected={index === this.state.kpiToEdit}
                                        resetKpi={(i) => this.resetKpi(i)}
                                        editMode={this.props.editMode}
                                        cancel={() => this.cancel()}
                                        loading={this.props.loading}
                                    /></React.Fragment>
                                }
                                return <React.Fragment key={index}>{divider}<SortableKPIElement 
                                    index={index}
                                    kpiIndex={index}
                                    width={width}
                                    column={k.column}
                                    aggregation={k.aggregation} 
                                    editMode={this.props.editMode}
                                    clicked={(i) => this.selectKpi(i)}
                                    clickable={this.props.clickable}
                                    highlight={
                                        this.props.clickable && settings && (settings.selectedKpi1 && settings.selectedKpi1.kpi.column === k.column && settings.selectedKpi1.index === index) ? settings.selectedKpi1.color :
                                        (this.props.clickable && settings && (settings.selectedKpi2 && settings.selectedKpi2.kpi.column === k.column && settings.selectedKpi2.index === index) ? settings.selectedKpi2.color : null)
                                        }
                                    loading={this.props.loading}
                                    value={value}
                                /></React.Fragment>
                            }
                            )
                        }
                        {
                            this.state.kpiToEdit === kpis.length && kpis.length < 4 ?
                                <>{this.getDivider(this.state.kpiToEdit)}<KPIReportAddElement
                                    index={this.state.kpiToEdit}
                                    saveKpi={(kpi, index) => this.saveKpi(kpi, index)}
                                    columns={columns}
                                    reportdata={this.props.reportdata}
                                    width={width}
                                    resetKpi={(i) => this.resetKpi(i)}
                                    editMode={this.props.editMode}
                                    cancel={() => this.cancel()}
                                    loading={this.props.loading}
                                /></>
                                :
                                kpis.length === 0 || (this.props.editMode && kpis.length < 4) ?
                                <>{this.getDivider(kpis.length)}<KPIReportEmptyElement width={width} selectKpi={() => this.selectKpi(kpis.length)} editMode={this.props.editMode} /></>
                                    : null
                        }
                    </SortableBanner>

                </div>
            </div> 
        )
    }
}

export const DragHandle = SortableHandle(({className}) => <span className={`fa fa-ellipsis-v ${className}`}></span>)

class KPIReportElement extends KPIElementBase {
    extraClass() {
        if (this.props.editMode || this.props.clickable) {
            return 'report-kpi-element report-kpi-element-clickable'
        }
        return 'report-kpi-element normal-cursor'
    }

    content() {

        let bodyCss = getComputedStyle(document.body);
        let kpi1Color = bodyCss.getPropertyValue("--kpi1GraphColor");
        let kpi2Color = bodyCss.getPropertyValue("--kpi2GraphColor")

        return (
            <div>
                <ShowIf if={this.props.editMode}>
                    <DragHandle className="report-kpi-banner-drag-handle report-kpi-banner-drag-handle-left"/>
                </ShowIf>
                {
                    this.props.loading ?
                    <div className="margin-left-right-auto">
                            <LoadingIcon size="medium" center={true} color="white" loading={this.props.loading} />
                        </div>
                        : <div className={`report-kpi-inner-element`} onClick={() => this.props.clicked(this.props.index)}>
                            <span className="text-align-center">
                                <h4 className="kpiElementFontAndColorsH4">{this.props.value}</h4>
                                <p className="kpiElementFontAndColorP1">{this.props.aggregation}</p>
                                <p className="kpiElementFontAndColorP2">
                                    <ShowIf if={this.props.highlight}>
                                        <ShowIf if={this.props.highlight === "blue"}>
                                            <span className='kpiBox' style={{backgroundColor:kpi1Color}}></span>
                                        </ShowIf>
                                        <ShowIf if={this.props.highlight === "green"}>
                                            <span className='kpiBox' style={{backgroundColor:kpi2Color}}></span>
                                        </ShowIf>
                                        <span style={{fontWeight:700, fontSize:14}}>{this.props.column}</span>
                                    </ShowIf>
                                    <ShowIf if={!this.props.highlight}>
                                        {this.props.column}
                                    </ShowIf>
                                </p>
                            </span>
                        </div>
                }
                <ShowIf if={this.props.editMode}>
                    <DragHandle className="report-kpi-banner-drag-handle report-kpi-banner-drag-handle-right"/>
                </ShowIf>
            </div>
        )
    }
}

class KPIReportEmptyElement extends KPIElementBase {
    extraClass() {
        if (this.props.editMode) {
            return 'report-kpi-element abc-click'
        }
        return 'report-kpi-element normal-cursor'
    }

    content() {
        return (
            <span className="text-align-center" onClick={() => this.props.selectKpi()}>
                <div>
                    <p className="kpiElementFontAndColorP2 margin-top-25px">Add KPI</p>
                    <p className="kpiElementFontAndColorP1"><i className="font-size-12">Optional</i></p>
                </div>
            </span>
        )
    }
}



KPIReportBanner = connect(mapStateToProps, null, null, { forwardRef: true })(KPIReportBanner)

export default KPIReportBanner