import React, { useState, useEffect } from "react"
import ReactTooltip from "react-tooltip"

import { comparePermissionsToRoles, getConditionalPermissions, mapRoletoPermissions, PermissionCheckbox, getConditionalSimpleView, mapPermChecksToPermList, isOrgAdmin, isDataAdmin, mapPermListToPermChecks } from "../../helpers/PermissionHelpers"

export default function OrganizationAdministrationEditPermissions(props) {
    const roles = [
        { value: "admin", label: "Admin" },
        { value: "super_user", label: "Super User" },
        { value: "user", label: "User" },
        { value: "custom", label: "Custom" },
        {value: "reporting_service", label: "Reporting Service"}
    ]
    const permissions = {
        dashboard: {
            dashboardadmin: {text: "Administrate Dashboards", help: "can manage and edit dashboards"},
            dashboard: {text: "View Dashboards", help: "can view dashboards"},
        },
        report: {
            reportadmin:{text: "Administrate Insights", help: "can administrate Insights for the entire organisation. (Includes: Edit mode)"},
            reportcreate: {text: "Create Insights", help: "can create private Insights only for themselves." },
            report:{text: "View Insights", help: "can view Insights."}
            ,
        },
        checklists: {
            checklistsadmin: {text: "Administrate Actions", help: "administrate Action lists for the organisation. The total Actions overview for the organisation is visible. "},
            checklistscreate: {text: "Create Actions", help: "can create Action lists for themselves and assign them to people. Other people's Action lists are not visible. "},
            checklists: {text: "View Actions", help: "can view Action lists. "},
        },
        other: {
            download: {text: "Download Data", help: "can download data."},
        },
    }
    const [checkedPerms, setCheckedPerms] = useState(props.multiSelect ? mapPermListToPermChecks(props.getPermissionsHandler()) : props.defaultRole && (!props.user.permissions || props.user.permissions.length === 0) ?  mapRoletoPermissions(props.defaultRole) : mapPermListToPermChecks(props.user.permissions))
    const [activeRole, setActiveRole] = useState(Object.keys(comparePermissionsToRoles(checkedPerms))[0])
    const [simpleView, setSimpleView] = useState(props.multiSelect ? false : props.user.permissions?.includes("simple_menu") ? true : false)

    useEffect(() => {
        const permissions = mapPermChecksToPermList(checkedPerms)
        
        if(permissions.length > 0){
            permissions.push("itemaccess")
            
            if (!props.multiSelect) {
                if (isOrgAdmin(props.user.permissions)) {
                    permissions.push("orgadmin")
                }
                
                if (isDataAdmin(props.user.permissions)) {
                    permissions.push("dataadmin")
                }
            }
            
            if (simpleView) {
                permissions.push("simple_menu")
            }
        }

        props.permissionsChangedHandler(permissions)
    }, [checkedPerms])

    function formFieldChangeHandler(value) {
        const permissions = mapRoletoPermissions(value)
        setCheckedPerms(permissions)
        setActiveRole(value)
    }

    function handlePermissionCheck(event, perm) {
        let newCheckedPerms

        newCheckedPerms = getConditionalPermissions(checkedPerms, perm, simpleView)

        const role = comparePermissionsToRoles(newCheckedPerms)

        setActiveRole(Object.keys(role)[0])
        setCheckedPerms(newCheckedPerms)
    }

    function simpleViewSwitchChangeHandler(checked) {
        setSimpleView(checked)
        if (checked) {
            const newPerms = getConditionalSimpleView(checkedPerms)

            const role = comparePermissionsToRoles(newPerms)
           setActiveRole(Object.keys(role)[0])
            setCheckedPerms(newPerms)
        } else {
            const role = comparePermissionsToRoles(checkedPerms)
            let roleName = Object.keys(role)[0]
            roleName = roleName === "simple_menu" ? "user" : roleName
            let newPerms = mapRoletoPermissions(roleName)
            setActiveRole(roleName)

            newPerms = {
                ...newPerms,
                report: checkedPerms.report,
                checklists: checkedPerms.checklists,
                dashboard: checkedPerms.dashboard,
            }

            setCheckedPerms(newPerms)
        }
    }

    function addRoleTitleHandler(value, key) {
        if (key === "Enter" || key === "Tab") {
            alert(value)
            alert(key)
        } else return
    }

    return (
        <div>
            <div className="edit-user-column-wrapper">
                <div className="edit-user-column">
                    <div className="custom-control custom-switch mb-4">
                        <input type="checkbox" className="custom-control-input" checked={simpleView} id="simple-view-switch" onChange={(e) => simpleViewSwitchChangeHandler(e.target.checked)} />
                        <label className="custom-control-label" htmlFor="simple-view-switch">
                            Simple view (Home only)
                        </label>
                    </div>
                    <h2 className="edit-user-headline-2">Roles</h2>
                    {roles.map((role) => (
                        <label className="edit-user-radio-btn" key={role.value}>
                            <input className="mr-2" type="radio" name="edit-user-language" disabled={(simpleView && role.value !== "user" && activeRole === "user") || (simpleView && role.value !== "custom" && activeRole === "custom") || (!simpleView && role.value === "custom" && activeRole !== "custom")} checked={role.value === activeRole} value={activeRole} onChange={(e) => formFieldChangeHandler(role.value)} />
                            {role.value === "addRole" ? (
                                <div className="d-flex">
                                    <input onKeyPress={(e) => addRoleTitleHandler(e.target.value, e.key)} />
                                    <button className="button-default btn">
                                        <i className="fa fa-check"></i>
                                    </button>
                                    <button className="button-default btn">
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            ) : (
                                <span>{role.label}</span>
                            )}
                        </label>
                    ))}
                </div>
                <div className={`edit-user-column `}>
                    {!props.multiSelect && <h2 className="edit-user-headline-2">{props.user.firstname} can...</h2>}
                    {Object.entries(permissions).map(([k, perm], i) => (
                        <div key={k} className={`edit-user-column ${Object.keys(permissions).length > i + 1 ? "border-bottom" : ""} mb-3`}>
                            {Object.entries(perm).map(([key, value]) => (
                                <label className="org-admin-checkbox" key={key}>
                                    <input className={`org-admin-input ${checkedPerms[key] === PermissionCheckbox.PRECHECKED ? "prechecked" : ""}`} type="checkbox" checked={checkedPerms[key] === PermissionCheckbox.CHECKED || checkedPerms[key] === PermissionCheckbox.PRECHECKED ? true : false} disabled={checkedPerms[key] === PermissionCheckbox.DISABLED} onChange={(event) => handlePermissionCheck(event.target.value, key)} />
                                    <span>{value.text} <span className="fa fa-info-circle" data-tip data-for={key}></span></span> 
                                    {!props.multiSelect && <ReactTooltip id={key}>{props.user.firstname} {value.help}</ReactTooltip>}
                                    {props.multiSelect && <ReactTooltip id={key}>Selected users {value.help}</ReactTooltip>}
                                </label>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
