/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { formatValue } from '../../helpers/ReportHelpers'
import { getArrowValues, getTargetColor } from '../../helpers/DashboardHelpers'
import WidgetToolTip from './WidgetToolTip'


/* CSS */
// import '../css/dimensions.css'

const mapStateToProps = (state, ownProps) => {
    return {

    }
}
export class SmallWidget extends React.Component {
    state = {}

    componentDidUpdate(prevProps, prevState, snapshot) { }

    getHighlightedColor(id, key) {
        const { hoverReportId, hoverKpiKey } = this.props
        if (id === hoverReportId && hoverKpiKey === key) return 'dashboard-background-small-selected'

        return 'dashboard-background-small'
    }

    render() {
        const { indexJ } = this.props
        const widget = this.props.data
        if (!widget) return null

        const column = widget.column
        const name = widget.report_name
        const trend = widget.trend
        const noData = widget.data_points === null ? true : false
        const label = widget.label && widget.label !== "" ? widget.label : ""
        const dataPoints = widget.data_points ? widget.data_points : []

        const lastVal = widget.last_value || widget.last_value === 0 ? widget.last_value : -1
        const trendObj = getArrowValues(dataPoints[dataPoints.length - 2], lastVal, trend)

        const targetColor = getTargetColor(widget.percent ? widget.percent_value : lastVal, widget.target, widget.target_trend, widget.found_target)
        let formattedVal
        if (widget.percent) {
            formattedVal = lastVal !== -1 ? formatValue(true, widget.percent_value, widget.decimal) + "%" : ""
        } else {
            formattedVal = lastVal !== -1 ? formatValue(true, lastVal, widget.decimal) : ""
        }
        if (noData) formattedVal = "N/A"

        return (
            <div
                className={`${this.props.fromSecret ? "dashboard-small-secret" : "dashboard-small show-target"} dashboard-background-small inline-block ${widget.is_report ? 'abc-click' : ''} position-relative ${!this.props.fromHome ? this.getHighlightedColor(widget.row_id != '' ? widget.row_id : widget.report_id, widget.kpi_key) : null}`}
                onMouseEnter={!this.props.fromHome ? () => this.props.setHoverValues(widget.row_id, widget.kpi_key) : null}
                onMouseLeave={!this.props.fromHome ? () => this.props.setHoverValues("", "") : null}
                title={widget.is_report ? "Go to Insight" : ""}
                data-tip
                data-for={`tooltip-${widget.row_id}-${widget.kpi_key}-${indexJ}-${widget.type}`}
            >
                <WidgetToolTip index={indexJ} value={formattedVal} widget={widget} direction={trendObj.direction} differencePrecedingPlus={""} />
                <div className="container">
                    <div className="row height-20px padding-right-10px">
                        <div className="col-12 pr-0">
                            <div className="float-right pt-1">
                                <span className={`${this.props.fromSecret ? "" : "small-target"} font-size-14px`}><b>{widget.found_target && widget.target_trend === -1 ? "max." : widget.found_target && widget.target_trend === 1 ? "min." : ""} {widget.found_target ? formatValue(true, widget.target, widget.decimal) : null}{widget.found_target && widget.percent ? "%" : ""}</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-align-center zero-padding" style={{ padding: 0 }}>
                            <div className="inline-block">
                                {
                                    !noData && trend !== 0 ?
                                        <span className={`${trendObj.direction === -1 ? 'fa fa-arrow-down' : trendObj.direction === 1 ? 'fa fa-arrow-up' : ""} ${trendObj.isPositive ? 'green-arrow' : 'red-arrow'} margin-right-5px font-size-18px top-0`}></span>
                                        : !noData && trend === 0 ?
                                            <span className={`${trendObj.direction === -1 ? 'fa fa-arrow-down' : trendObj.direction === 1 ? 'fa fa-arrow-up' : ""} margin-right-5px font-size-18px top-0`}></span>
                                            : null
                                }
                                {
                                    !noData ?
                                        <span className="h1-fix-small">{formattedVal}</span>
                                        : <span className="h1-fix-small">N/A</span>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-align-center">
                            <span className="h4-fix pre-wrapper">{label !== "" ? `${label}` : `${column} - ${name}`}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 no-padding stick-to-bottom">
                            <div className={`height-14px target-color ${targetColor}`}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SmallWidget = connect(mapStateToProps)(SmallWidget)

export default SmallWidget
