import * as ActionTypes from "../actions/ActionTypes"

const defaultState = {}

const Organization = (state = defaultState, action) => {
    let id
    let index
    let updatedUser
    let updatedUsers
    let users

    switch (action.type) {
        case ActionTypes.GET_ORGANIZATION_USERS:
            return Object.assign({}, state, { id: action.payload.orgID })
        case ActionTypes.GET_ORGANIZATION_USERS_DONE:
            return Object.assign({}, state, { users: action.payload })
        case ActionTypes.GET_ORGANIZATION_USER_DONE:
            index = state.users.findIndex((u) => u.id === action.payload.user.id)
            updatedUsers = [...state.users]
            updatedUsers.splice(index, 1, action.payload.user)
            return Object.assign({}, state, { users: updatedUsers })
        case ActionTypes.UPDATE_USER_DONE:
            id = action.payload.id

            const data = action.payload

            index = state.users.findIndex((u) => u.id === id)
            const lastLogin = state.users[index].last_login;
            updatedUser = { ...state.users[index], ...data }
            updatedUser.last_login = lastLogin;

            updatedUsers = [...state.users]
            updatedUsers.splice(index, 1, updatedUser)
            return Object.assign({}, state, {
                users: updatedUsers,
            })
        case ActionTypes.TOGGLE_FAVOURITE_REPORTS_DONE:
            id = action.payload.user_id

            index = state.users.findIndex((u) => u.id === id)
            updatedUser = state.users[index]
            let fav_reports = action.payload.favourite_reports.map((f) => {
                let report = updatedUser.reports?.find((r) => r.report_id === f.item_id)
                if (!report ) report = state.items.reports?.find(r => r.report_id === f.item_id)
                return { id: f.id, user_id: id, report_id: f.item_id, report_name: report.name, report_bucket: report.bucket }
            })
            updatedUser.favourite_reports = fav_reports

            updatedUsers = [...state.users]
            updatedUsers.splice(index, 1, updatedUser)

            return Object.assign({}, state, {
                users: updatedUsers,
            })

        case ActionTypes.UPDATE_PERMISSIONS_DONE:
            const permissions = action.payload.permissions
            id = action.payload.id

            index = state.users.findIndex((u) => u.id === id)
            updatedUser = state.users[index]
            updatedUser.permissions = permissions

            updatedUsers = [...state.users]
            updatedUsers.splice(index, 1, updatedUser)

            return Object.assign({}, state, {
                users: updatedUsers,
            })
        case ActionTypes.UPDATE_MULTIPLE_USERS_PERMISSIONS_DONE:
            users = action.payload.users

            updatedUsers = [...state.users]

            for(const user of users) {
                index = state.users.findIndex((u) => u.id === user.id)
                updatedUser = state.users[index]
                updatedUser.permissions = user.permissions

                updatedUsers.splice(index, 1, updatedUser)
            }

            return Object.assign({}, state, {
                users: updatedUsers,
            })
        case ActionTypes.DISABLE_MULTIPLE_USERS_DONE:
            users = action.payload

            updatedUsers = [...state.users]

            for(const user of users) {
                index = state.users.findIndex((u) => u.id === user.id)
                updatedUser = state.users[index]
                updatedUser.deleted = true

                updatedUsers.splice(index, 1, updatedUser)
            }

            return Object.assign({}, state, {
                users: updatedUsers,
            })
        case ActionTypes.UPDATE_HOME_DASHBOARD_DONE:
            const home_dashboard = action.payload.home_dashboard
            id = action.payload.user_id

            index = state.users.findIndex((u) => u.id === id)
            updatedUser = state.users[index]
            updatedUser.home_dashboard = home_dashboard

            updatedUsers = [...state.users]
            updatedUsers.splice(index, 1, updatedUser)

            return Object.assign({}, state, {
                users: updatedUsers,
            })
        case ActionTypes.CREATE_USER_DONE:
            users = [...state.users]
            let user = action.payload;

            if (state.info.use_access_control) {
                user.dashboards = [];
                user.reports = [];
                if(user.bucket_access){
                    for (const bucket of user.bucket_access) {
                        
                        for (const d of state.items.dashboards) {
                            if (d.bucket === bucket) {
                                user.dashboards.push(d)
                            }
                        }
                        for (const r of state.items.reports) {
                            if (r.bucket === bucket) {
                                user.reports.push(r)
                            }
                        }
                    }
                }
            } else {

                user.dashboards = state.items.dashboards;
                user.reports = state.items.reports;
            }

            users.push(user)
             return Object.assign({}, state, {
                 users: users,
             })
        case ActionTypes.GET_ORGANIZATION_ITEMS_DONE:
              return Object.assign({}, state, {
                  items: action.payload.items,
              })
        case ActionTypes.GET_ORGANIZATION_LOCATIONS_DONE:
              return Object.assign({}, state, {
                  locations: action.payload.locations,
              })
        case ActionTypes.SEND_WELCOME_MAIL_DONE:

            id = action.payload.user_id;
         
            index = state.users.findIndex((u) => u.id === id)
            updatedUser = state.users[index]
            const last_welcome_mail = {
                userID: id,
                mail_type: "welcome",
                send_time: Date.now(),
            }
            updatedUser.last_welcome_mail = last_welcome_mail;
            updatedUsers = [...state.users]
            updatedUsers.splice(index, 1, updatedUser)
            return Object.assign({}, state, {
                users: updatedUsers,
            } )
        case ActionTypes.UPDATE_BUCKET_ACCESS_DONE:
            users = [...state.users]
            index = state.users.findIndex((u) => u.id === action.payload.buckets[0].user_id)
            updatedUser = users[index]
            if(action.payload.buckets[0].id === -1) {
                updatedUser.bucket_access = []
            } else {
                  updatedUser.bucket_access = action.payload.buckets.map(access => access.bucket_id)
                
            }
                
            users.splice(index, 1, updatedUser)

            return Object.assign({}, state, {
                users: users,
            })
        case ActionTypes.GET_ORGANIZATION_DONE:
            return Object.assign({}, state, { info: action.payload.organization })
        default:
            return state
    }
}

export default Organization
