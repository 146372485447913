import React from 'react'
import { connect } from 'react-redux'
import { getUserInfo, logout, loggedOut, is2FAForced, getFollowings } from '../actions/UserActions'
import { getVersion } from '../actions/SystemActions'
import { canUseReports, isChecklistAdmin, canUseDashboards, is, simplifyMenu } from '../helpers/PermissionHelpers'
import { Link } from 'react-router-dom'
import { apiPing, getApiCallCount } from '../helpers/ApiHelpers'
import { clearSessionSettings } from '../helpers/SettingsService'
import { withRouter } from 'react-router'
import { MenuItem } from './MenuItem'
import moment from 'moment'
import { getNotifications, getUnreadNotifications } from '../actions/NotificationActions'
import NavigationBarNotifications from './NavigationBarNotifications'
import SearchMenuItem from './SearchMenuItem'
import { MenuItemDropdown } from './MenuItemDropdown'
import { getHomeInfo } from '../actions/InfoActions'
import RequirePermission from './RequirePermission'
import NotificationBell from './NotificationBell'

/* CSS */
import '../css/home.css'
import '../css/loadingoverlay.css'
// import LoadingAnimation from './LoadingAnimation'

const mapStateToProps = (state, ownProps) => {
	return {
		loggedIn: state.User.loggedIn,
		userInfo: state.User.info,
		version: state.System.version,
		unreadNotifications: state.Notification.unreadNotifications,
        info: state.Info.info,
	}
}

let startUpTime = new Date()
let sessionExpiry = null
let pinger = null
let counter = null
let notificationInterval = null
class NavigationBar extends React.Component {
	state = {
		count: 0,
		notificationsOpen: false,
		sidebarOpen: false
	}


	componentDidMount() {
		startUpTime = new Date()
        this.props.dispatch(is2FAForced())
		this.props.dispatch(getUserInfo())
		this.props.dispatch(getVersion())
		this.props.dispatch(getNotifications(10, 0, "navbar"))
		this.props.dispatch(getUnreadNotifications())
		this.props.dispatch(getFollowings())
		notificationInterval = setInterval(() => { if(this.props.loggedIn) this.props.dispatch(getUnreadNotifications(true)); }, 15000)
		pinger = setInterval(() => { this.ping() }, 60000)
		sessionExpiry = setInterval(() => { this.checkSessionExpiry() },5 * 60 * 1000) // every 5 minutes
		counter = setInterval(() => { if (getApiCallCount() !== this.state.count) this.setState({count: getApiCallCount()}); }, 200)

		window.$(document).on('click', 'notification-dropdown .dropdown-menu', function (e) {
			e.stopPropagation()
		})

		if (!this.props.info) this.props.dispatch(getHomeInfo())

		document.addEventListener('mousedown', this.handleClickOutsideNotifications)

	}

	componentWillUnmount() {
		clearInterval(pinger)
		clearInterval(counter)
		clearInterval(notificationInterval)
		clearInterval(sessionExpiry)
		document.removeEventListener('mousedown', this.handleClickOutsideNotifications)

	}

	componentDidUpdate() {
		let unread = sessionStorage.getItem("unreadnotifications")

		const url = window.location.href;

		if (this.props.unreadNotifications.length > 0 && unread != this.props.unreadNotifications.length && !url.endsWith("/notifications")) {
			this.setState({notificationsOpen: true})
		}

		sessionStorage.setItem("unreadnotifications", this.props.unreadNotifications.length)
	}

	handleClickOutsideNotifications = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({notificationsOpen: false})
		}
	}

	checkSessionExpiry() {
		let hoursRunning = moment().diff(startUpTime, 'hours')

		if(hoursRunning >= 10)
		{
			let { dispatch } = this.props
			console.log("Session has been running for too long")
			let a = logout()
			a.cb = () => {}
			dispatch(loggedOut())
			dispatch(a)
			clearSessionSettings()
		}
	}

	ping() {
		let { dispatch } = this.props
		apiPing(null, err => {
			console.log(err)
			let a = logout()
			a.cb = () => {}
			dispatch(loggedOut())
			dispatch(a)
			clearSessionSettings()
		})
	}

	myLogout() {
		let a = logout()
		a.cb = () => {
			this.props.history.push("/")

		}
		this.props.dispatch(a)
		clearSessionSettings()
	}

	setWrapperRef = (node) => {
		this.wrapperRef = node
	}

	onClickSupport = () => {
		if(this.state.sidebarOpen) {
			this.setState({sidebarOpen: false})
			this.props.sidebar.hide()
		} else {
			this.setState({sidebarOpen: true})
			this.props.sidebar.show()
		}
	}

    render() {
		let brandClass = 'navbar-brand abc-brand-label'
		if (this.state.count > 0) {
			brandClass += ' abc-brand-label-loading'
		}

		let firstname = this.props.userInfo.firstname
		let organization_name = this.props.userInfo.organization_name
		let version = this.props.version
		let permissions = this.props.userInfo.access ? this.props.userInfo.access : []

		let favouriteReports = this.props.info && this.props.info.favouriteReports ? this.props.info.favouriteReports : []
		if (favouriteReports) favouriteReports.sort((a, b) => a.report.name.localeCompare(b.report.name))
		
        return (
		<nav className="navbar navbar-custom pt-0 pb-0 navbar-expand-md navbar-fixed-top">
				{/* {this.state.count > 0 ? 
				<LoadingOverlay /> : null} */}
				<div className="navbar-header">
					<button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
						<span className="sr-only">Toggle navigation</span>
						<span className="navbar-toggler-icon"></span>
					</button>
					
					<Link className={brandClass} to="/"><span/></Link>
				</div>

				<div id="navbar" className="navbar-collapse collapse">
					<ul className="nav navbar-nav">
						<MenuItem theClass={`${this.props.history.location.pathname === "/" || this.props.history.location.pathname === "//"  ? "active" : ""}`} link="/" text="Home" />
						{
							favouriteReports.length > 0 && canUseReports(permissions) && !simplifyMenu(permissions) ? <MenuItemDropdown reports={favouriteReports} theClass={`${this.props.history.location.pathname === "/library" ? "active" : ""}`} link="/library" text="Insights" /> 
							: favouriteReports.length === 0 && canUseReports(permissions) && !simplifyMenu(permissions) ? <MenuItem theClass={`${this.props.history.location.pathname === "/library"  ? "active" : ""}`} link="/library" text="Insights" />
							: null
						}
						
						{
							isChecklistAdmin(permissions) ?
							(<MenuItem theClass={`${this.props.history.location.pathname === "/status/checklists" ? "active" : ""}`} link="/status/checklists" text="Actions" />) :
							null
						}

						<RequirePermission perms={is.dataAdmin}>
							<MenuItem theClass={`${this.props.history.location.pathname === "/dataupdate" ? "active" : ""}`} link="/dataupdate" text="Update data" />
						</RequirePermission>

						{
							canUseDashboards(permissions) && !simplifyMenu(permissions) ?
							<MenuItem theClass={`${this.props.history.location.pathname === "/dashboard" ? "active" : ""}`} link="/dashboard" text="Dashboards" /> 
							: null
						}

						<RequirePermission perms={is.downloadUser}>
							<MenuItem theClass={`${this.props.history.location.pathname === "/download" ? "active" : ""}`} link="/download" text="Download" />
						</RequirePermission>
					</ul>

					<ul className="nav navbar-nav ml-auto">
						<RequirePermission perms={is.orgAdmin}>
							<li title="Admin panel " className="nav-item">
								<Link to="/administrate/organization" className="nav-link"><i className="fa fa-users" aria-hidden="true"></i>
								</Link>
							</li>
						</RequirePermission>
						<li title="Get help & support" className="nav-item">
							<span className="nav-link">
								<span className="fa fa-question-circle hover-cursor" onClick={this.onClickSupport}/>
							</span>
						</li>
						<li className={`dropdown nav-item notifications-dropdown ${this.state.notificationsOpen ? "show" : ""}`} ref={this.setWrapperRef} title={this.props.unreadNotifications.length > 0 ? "You have unread notifications" : "You have no unread notifications"}>
							<span className="nav-link" role="button" aria-expanded="false" onClick={() => this.setState({notificationsOpen: !this.state.notificationsOpen})}>
								<NotificationBell notificationsCount={this.props.unreadNotifications.length} />
							</span>
							<NavigationBarNotifications onClose={() => this.setState({notificationsOpen: false})} open={this.state.notificationsOpen}/>
						</li>
						<RequirePermission perms={is.itemAccessor}>
							<SearchMenuItem/>
						</RequirePermission>
						<li className="dropdown nav-item" title="My profile">
							<span className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
								<span>{firstname}, {organization_name}</span>&nbsp;
							</span>
							<ul className="dropdown-menu dropdown-menu-right margin-top-0" role="menu">
								<li className="dropdown-item">Version: {version}</li>	
								<RequirePermission perms={is.orgAdmin}>
									<div className="dropdown-divider"></div>
									<li className="dropdown-item abc-click" onClick={() => {
											if(window.location.href.includes("/administrate/organization")){
												this.props.history.push(`/administrate/organization?showCreate`);
												window.location.reload();
											}
											else {
												this.props.history.push(`/administrate/organization?showCreate`);
											}

										}}>
										Create new user
									</li>

									
								</RequirePermission>

								<div className="dropdown-divider"></div>
								<Link to="/user" className="cancel-a-style"><li className="dropdown-item">Settings</li></Link>
								<div className="dropdown-divider"></div>
								<li className="abc-clickable dropdown-item" onClick={() => this.myLogout()}>Logout</li>
							</ul>
						</li>
					</ul>
				</div>
		</nav>
		)
	}
}

NavigationBar = connect(mapStateToProps)(NavigationBar)

NavigationBar = withRouter(NavigationBar)

export default NavigationBar

// let LoadingOverlay = (props) => {

// 	return (
// 		<div className='loading-overlay-background'>
// 			<LoadingAnimation width={75} />
// 		</div>
// 	)
// }