import React from 'react'
import LayoutColorPicker from './LayoutColorPicker'
import ShowIf from './Generic/ShowIf'
import { SortableHandle } from 'react-sortable-hoc'
import "../css/sortableList.css"
import SortableList from './Generic/SortableList'
export const DragHandle = SortableHandle(({ className }) => <span className={`fa fa-ellipsis-h ${className}`}></span>)

const LAYOUT_COLORS = ['btn-primary', 'btn-success', 'btn-info', 'btn-warning', 'btn-danger', 'black']

//hideDeleteButtonPredicate is a provided (optional) predicate that checks if a layout element on a given index should have a delete button
class ChecklistLayoutEditor extends React.Component {
    state = {
        warnDeletion: -1
    }

    onClickCategoryDeletion(i, fromWarning) {
        if (this.props.warnCategoryDeletion && this.props.warnCategoryDeletion(i) && i !== this.state.warnDeletion) {
            this.setState({ warnDeletion: i })
        } else if (this.props.warnCategoryDeletion && this.props.warnCategoryDeletion(i) && i === this.state.warnDeletion) {
            this.setState({ warnDeletion: -1 })
        } if (this.props.onCategoryDeletion && (fromWarning || (this.props.warnCategoryDeletion && !this.props.warnCategoryDeletion(i)))) {
            this.props.onCategoryDeletion(i)
            this.setState({ warnDeletion: -1 })
        }
    }

    getListDoneItem(l, i) {
        return <>
            <div className={(this.props.hideDeleteButtonPredicate && this.props.hideDeleteButtonPredicate(i, this.props.layout)) || !l.donecount ? 'zero-height overflow-hidden' : 'd-flex flex-row align-items-center py-1'} style={{zIndex: 10000}} key={i}>
                <div className='pr-2'><DragHandle className="sortable-list-handle" /></div>
                <div><LayoutColorPicker onColorChange={(c) => this.props.onCategoryColorChange(i, c)} color={l.color} /></div>
                <div className='flex-fill pl-2'><input type="text" className="form-control" placeholder="Category name" value={l.name} onChange={event => { if (this.props.onCategoryNameChange) this.props.onCategoryNameChange(i, event.target.value) }} /></div>
               
                <div className='pl-2'>
                    <button title="Move category to count towards not completed" className="btn btn-default btn-sm fa fa-angle-up hover-cursor vertical-align-middle" style={{borderColor:'white'}} onClick={() => this.props.onUpdateDoneCount(i, false)}></button>
                </div>
                <div className='pl-2'>
                    <span className="btn btn-default fa fa-trash hover-cursor vertical-align-middle" onClick={() => this.onClickCategoryDeletion(i)}></span>
                </div>
            </div>
            <ShowIf if={this.state.warnDeletion === i && !this.props.hideDeleteButtonPredicate(i, this.props.layout) && l.donecount}>
                <div className="alert alert-danger alert-xs w-100 mb-0">
                    Deleting this category will move all items in {this.props.layout[i].name} to {this.props.layout[0].name}. Do you want to proceed? <br /><button className="btn btn-default btn-xs" onClick={() => this.onClickCategoryDeletion(i, true)}>Delete</button> <button className="btn btn-default btn-xs" onClick
                        ={() => this.setState({ warnDeletion: -1 })}>Cancel</button>
                </div>
            </ShowIf>
        </>
    }


    getListNotDoneItem(l, i) {
        return <>
            <div className={(this.props.hideDeleteButtonPredicate && this.props.hideDeleteButtonPredicate(i, this.props.layout)) || l.donecount ? 'zero-height overflow-hidden' : 'd-flex flex-row align-items-center py-1'} style={{zIndex: 10000}} key={i}>
                <div className='pr-2'><DragHandle className="sortable-list-handle" /></div>
                <div><LayoutColorPicker onColorChange={(c) => this.props.onCategoryColorChange(i, c)} color={l.color} /></div>
                <div className='flex-fill pl-2'><input type="text" className="form-control" placeholder="Category name" value={l.name} onChange={event => { if (this.props.onCategoryNameChange) this.props.onCategoryNameChange(i, event.target.value) }} /></div>
              
                <div className='pl-2'>
                    <button title="Move category to count towards completed" className="btn btn-default btn-sm fa fa-angle-down hover-cursor vertical-align-middle"  style={{borderColor:'white'}} onClick={() => this.props.onUpdateDoneCount(i, true)}></button>
                </div>
                <div  className='pl-2'>
                    <span className="btn btn-default fa fa-trash hover-cursor vertical-align-middle" onClick={() => this.onClickCategoryDeletion(i)}></span>
                </div>
            </div>
            <ShowIf if={this.state.warnDeletion === i && !this.props.hideDeleteButtonPredicate(i, this.props.layout) && !l.donecount}>
                <div className="alert alert-danger alert-xs w-100 mb-0">
                    Deleting this category will move all items in {this.props.layout[i].name} to {this.props.layout[0].name}. Do you want to proceed? <br /><button className="btn btn-default btn-xs" onClick={() => this.onClickCategoryDeletion(i, true)}>Delete</button> <button className="btn btn-default btn-xs" onClick
                        ={() => this.setState({ warnDeletion: -1 })}>Cancel</button>
                </div>
            </ShowIf>
        </>
    }

    getFixedListItem(l,i) {
        return <>
            <div className={this.props.hideDeleteButtonPredicate && this.props.hideDeleteButtonPredicate(i, this.props.layout) ? 'd-flex flex-row align-items-center py-1' : 'display-none'} style={{zIndex: 10000}} key={i}>
                <div className='pr-2'>
                    <div style={{width:'26px'}}>
                        <span className="fa fa-lock vertical-align-middle sortable-list-handle" style={{cursor: 'inherit'}}></span>
                    </div>
                </div>
                <div><LayoutColorPicker onColorChange={(c) => this.props.onCategoryColorChange(i, c)} color={l.color} /></div>
                <div className='flex-fill pl-2'><input type="text" className="form-control" placeholder="Category name" value={l.name} onChange={event => { if (this.props.onCategoryNameChange) this.props.onCategoryNameChange(i, event.target.value) }} /></div>
                <div style={{width:'79px'}}>&nbsp;</div>
            </div>
        </>
    }

    render() {
        //fix for checklists without "donecount"
        for(let i = 0; i < this.props.layout.length; i++){
            if(this.props.layout[i].donecount === undefined){
                this.props.layout[i].donecount = i > 0
            }
        }

        return (<>
            <div>
                    <span title="Category counts towards not completed" className="fa fa-clock-o hover-cursor vertical-align-middle"></span>&nbsp;<span style={{fontSize:"0.75rem"}}>Not completed</span>
                {this.props.layout && <SortableList items={this.props.layout} getItem={(l, i) => this.getFixedListItem(l, i)} disabled={true} />}
                
                {this.props.layout && <SortableList items={this.props.layout} getItem={(l, i) => this.getListNotDoneItem(l, i)} onSortEnd={(o, n) => this.props.onSortEnd(o, n)} />}
                <hr />
                
                    <span title="Category counts towards completed" className="fa fa-check hover-cursor vertical-align-middle"></span>&nbsp;<span style={{fontSize:"0.75rem"}}>Completed</span>

                {this.props.layout && <SortableList items={this.props.layout} getItem={(l, i) => this.getListDoneItem(l, i)} onSortEnd={(o, n) => this.props.onSortEnd(o, n)} />}
                <button className="btn btn-xs mt-3 btn-default center display-block margin-right-left-auto" onClick={() => { if (this.props.onAddCategory) this.props.onAddCategory({ name: "", color: LAYOUT_COLORS[this.props.layout.length % LAYOUT_COLORS.length] }) }}><span className="fa fa-plus" ></span> Add category</button>
            </div>

            </>
        )
    }
}

export default ChecklistLayoutEditor
