import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Column, DashboardKPI, DataBucket, ParsedDashboardData, ReportData } from '../../types/transfertypes'
import GenericDialog from './GenericDialog'
import { SingleKeyMap } from '../../helpers/Collections'
// @ts-ignore
import { components } from 'react-select'
import Select from '../Generic/CustomSelect'
import { AggregationType, SelectGroupOption, SelectOption, capitalizeText, stringToAggType, isBucketModelBad, generateTimestampBasedId } from '../../helpers/TypeHelpers'

import '../../css/EditDashboardKpiDialog.css'
import InfoTooltip from '../Generic/InfoTooltip'

export interface EditDashboardKpiDialogProps {
    dispatch?: any,
    show: boolean,
    reportInfo: ReportData,
    dashboard: ParsedDashboardData,
    onClose: () => void,
    saveKpi: (newKpi: DashboardKPI) => void,
    kpi?: DashboardKPI,
    buckets: SingleKeyMap<DataBucket>,

}

let EditDashboardKpiDialog = (props: EditDashboardKpiDialogProps) => {

    const [tmpColumn, setTmpColumn] = useState<SelectOption<Column> | undefined>(undefined)
    const [tmpAggregation, setTmpAggregation] = useState<SelectOption<AggregationType> | undefined>(undefined)
    const [tmpCategory, setTmpCategory] = useState<SelectOption<string>[]>([])
    const [tmpPercent, setTmpPercent] = useState(false)
    const [tmpDecimal, setTmpDecimal] = useState(false)
    const [tmpLabel, setTmpLabel] = useState('')

    const aggregationSelectRef = useRef<any>()
    const categorySelectRef = useRef<any>()

    let columnOptions: SelectGroupOption<Column>[] = useMemo(() => {
        return props.buckets.values().filter(v => !isBucketModelBad(v.info.model)).map(v => {
            return {
                label: `${v.info.name}`,
                options: v.info.model.columns.map(c => {
                    return {
                        label: c.name,
                        value: c,
                        extra: v.id
                    }
                }).sort((a, b) => a.label.localeCompare(b.label)),
                extra: v.id == props.reportInfo.info.id ? 'main' : undefined
            }
        }).sort((a, b) => {
            // Making sure the main report is at the top
            if (a.extra == "main") return -1
            if (b.extra == "main") return 1
            return a.label.localeCompare(b.label)
        })
    }, [props.buckets, props.reportInfo])

    const decimalAggregationOptions: SelectOption<AggregationType>[] = [
        {
            label: "Sum",
            value: AggregationType.SUM
        },
        {
            label: "Avg",
            value: AggregationType.AVG
        },
        {
            label: "Min",
            value: AggregationType.MIN
        },
        {
            label: "Max",
            value: AggregationType.MAX
        },
    ]

    const allAggregationOptions: SelectOption<AggregationType>[] = [
        {
            label: "Count",
            value: AggregationType.COUNT
        }
    ]

    const categoryOptions: SelectOption<string>[] = useMemo(() => {
        if (tmpColumn == undefined) return []
        let bucket = props.buckets.get(tmpColumn.extra)
        if (bucket == undefined) return []
        return bucket.info.model.categorization.map(v => { return { label: v.name, value: v.name } }).sort((a, b) => a.label.localeCompare(b.label, 'en'))
    }, [tmpColumn])

    useEffect(() => {
        if (!props.show) {
            setTmpColumn(undefined)
            setTmpAggregation(undefined)
            setTmpCategory([])
            setTmpPercent(false)
            setTmpDecimal(false)
            setTmpLabel('')
        }

        // If we are editing an existing KPI
        if (props.kpi == undefined) return
        let column = undefined
        for (let v in columnOptions) {
            column = columnOptions[v].options.find(c => c.label == props.kpi?.column)
            if (column != undefined) break
        }
        setTmpColumn(column)
        setTmpAggregation({label: capitalizeText(props.kpi.aggregation), value: stringToAggType(props.kpi.aggregation)})
        let categories = props.kpi.category_filter.map(c => {return {label: c, value: c}})
        setTmpCategory(categories ?? [])
        setTmpPercent(props.kpi.percent)
        setTmpDecimal(props.kpi.decimal)
        setTmpLabel(props.kpi.label)
    }, [props.kpi, props.show])

    let setColumnAndSelectDefaults = (column: SelectOption<Column>) => {
        setTmpColumn(column)
        clearValues()

        let col = undefined
        for (let v in columnOptions) {
            col = columnOptions[v].options.find(c => c.label == column.label)
            if (col != undefined) break
        }

        setTmpAggregation(allAggregationOptions[0])
        if (col != undefined && col.value.type == "decimal") {
            setTmpAggregation(decimalAggregationOptions[0])
        }
        
        if (col != undefined) {
            setTmpLabel(col.label)
        }
    }

    let clearValues = () => {
        aggregationSelectRef?.current?.select?.clearValue()
        categorySelectRef?.current?.select?.clearValue()
    }

    let saveKpi = () => {
        if (tmpColumn == undefined || tmpAggregation == undefined) return
        const category = tmpCategory != undefined ? tmpCategory.map(v => v.value) : []
        let kpi: DashboardKPI = {
            kpi_key: props.kpi != undefined ? props.kpi.kpi_key : generateTimestampBasedId(),
            column: tmpColumn.value.name,
            aggregation: tmpAggregation.value,
            category_filter: category,
            decimal: tmpDecimal,
            percent: tmpPercent,
            label: tmpLabel
        }

        props.saveKpi(kpi)
    }

    let disableSave = () => {
        return tmpColumn == undefined || tmpAggregation == undefined
    }

    const formatGroupLabel = (data: SelectGroupOption<Column>) => (
        <div className='edit-kpi-group'>
            <span>{data.label}</span>
            <span className='edit-kpi-badge'>
                {
                    data.extra == "main" ? <i title='Same as main Insight' style={{ marginRight: 5 }} className="fa fa-file report-icon" aria-hidden="true"></i> : null
                }

                {data.options.length}
            </span>
        </div>
    );

    const getButtons = () => {
        return <div>
            <button style={{ marginRight: 7 }} onClick={saveKpi} disabled={disableSave()} className='btn btn-primary'>Save</button>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    let aggregationOptions = allAggregationOptions
    if (tmpColumn != undefined && tmpColumn.value.type == "decimal") {
        aggregationOptions = decimalAggregationOptions
    }
    return (
        <div>
            <GenericDialog show={props.show} title={"Edit column"} onClose={props.onClose} getButtons={getButtons} style={{ width: 400 }}>
                <label className='select-label'>Column</label>
                <Select
                    isSearchable
                    options={columnOptions}
                    formatGroupLabel={formatGroupLabel}
                    value={tmpColumn}
                    onChange={(v: SelectOption<Column>) => setColumnAndSelectDefaults(v)}
                    placeholder="Select column"
                    components={{
                        GroupHeading: HideGroupHeading,
                        MenuList: HideGroupMenuList
                    }}
                    styles={{
                        groupHeading: (styles: any) => ({
                            ...styles,
                            margin: 0,
                            paddingRight: 0,
                            color: "black",
                            fontWeight: "normal",
                            fontSize: 12,
                            flex: 1
                        }),
                        control: (styles:any) => ({ 
                            ...styles,
                            ':focus-within' : { 
                                borderColor: "var(--tangerine-hover)", 
                                boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                            }
                        }),
                        option: (provided:any, state:any) => ({
                          ...provided,
                          fontWeight: state.isSelected ? "bold" : "normal",
                          color: "black",
                          backgroundColor: "white",
                        }),
                    }}
                />

                <label className='select-label mt-3'>Aggregation</label>
                <Select
                    isSearchable
                    options={aggregationOptions}
                    value={tmpAggregation}
                    onChange={(v: SelectOption<AggregationType>) => setTmpAggregation(v)}
                    isDisabled={tmpColumn == undefined}
                    placeholder="Select aggregation type"
                    ref={aggregationSelectRef}
                />

                <label className='select-label mt-3' >Categorization (optional)</label>
                <Select
                    isSearchable
                    isMulti
                    ref={categorySelectRef}
                    options={categoryOptions}
                    value={tmpCategory}
                    isClearable
                    onChange={(v: any) => setTmpCategory(v)}
                    isDisabled={tmpColumn == undefined}
                    closeMenuOnSelect={false}
                    placeholder={"Select categories"}
                />

                <div className='flex align-center mt-3 kpi-checkbox-container'>
                    <div className='mr-3'>
                        <input className='mr-1' type='checkbox' id='percent-check' checked={tmpPercent} onChange={() => setTmpPercent(v => !v)} />
                        <label htmlFor="percent-check" className=''>Percentage <InfoTooltip text="Percentage is calculated based on the value from the main Insight" /></label>
                    </div>
                    <div>
                        <input className='mr-1' type='checkbox' id='decimal-check' checked={tmpDecimal} onChange={() => setTmpDecimal(v => !v)} />
                        <label htmlFor="decimal-check" className=''>Decimal</label>
                    </div>
                </div>

                <label htmlFor='kpi-label' className='select-label mt-3'>Label</label>
                <input id='kpi-label' type="text" placeholder="Label (optional)" className="form-control" value={tmpLabel} onChange={(e) => setTmpLabel(e.target.value)} />

            </GenericDialog>
        </div>
    )
}

export default EditDashboardKpiDialog

const HideGroupHeading = (props: {
    id: any;
    data: SelectGroupOption<Column>;
}) => {
    return (
        <div
            className="collapse-group-heading"
            onClick={() => {
                document
                    ?.querySelector(`#${props.id}`)
                    ?.parentElement?.parentElement?.classList.toggle("collapsed-group");
            }}
        >
            <components.GroupHeading {...props} />
        </div>
    );
};

const HideGroupMenuList = (props: { children: any[] }) => {
    let new_props = {
        ...props,
        children: Array.isArray(props.children)
            ? props.children.map((c, idx) =>
                idx === 0
                    ? c
                    : { ...c, props: { ...c.props, className: "collapsed-group" } }
            )
            : props.children,
    };

    return <components.MenuList {...new_props} />;
};
