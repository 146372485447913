import React from 'react'
import { connect } from 'react-redux'
import SaveDialog from '../Dialogs/SaveDialog'
import ShowIf from '../Generic/ShowIf'


const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.reportInfo,
        profits: state.Bucket.profits,
    }
}

class ReactNav extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            optionNames: ['overview', 'grid', 'kpis', 'sum', 'profit', 'trend'],
        }
    }

    getSettings = () => {
        let viewSettings = this.props.getData("report", "view_settings")
        return viewSettings.settings ? viewSettings.settings : {}
    }

    setSettings = (key, value) => {
        let viewSettings = this.props.getData("report", "view_settings")
        let settings = Object.assign({}, viewSettings.settings)

        // when making a new report
        if (settings.default_view === undefined) {
            settings = {
                default_view: "overview",
                overview: settings.overview ?? true,
                grid: settings.grid ?? false,
                kpis: settings.kpis ?? false,
                sum: settings.sum ?? false,
                trend: settings.trend ?? false,
                profit: settings.profit ?? false,
                enableLabel: settings.enableLabel ?? true
            }
        }

        settings[key] = value

        // if all views get deselected and start selecting again
        if (settings.default_view === "") {
            settings.default_view = key
        }

        let dv = settings.default_view ? settings.default_view : ""
        let found = false

        if (key === settings.default_view && !value) {
            //Find a new defaut view, if the current is deselected
            [settings.overview, settings.grid, settings.kpis, settings.sum, settings.trend].forEach((s, i) => {
                let option = this.state.optionNames[i]
                if (s && option !== key && !found) {
                    found = true
                    dv = option
                }
            })
            if (!found) dv = ""
        }

        if (dv) settings.default_view = dv
        viewSettings.settings = settings
        this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
    }

    setDefaultView(view) {
        let viewSettings = this.props.getData("report", "view_settings")
        let settings = Object.assign({}, viewSettings.settings)
        settings.default_view = view
        viewSettings.settings = settings
        this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
    }


    render() {
        const { section, profit, viewSettings } = this.props
        let getActive = s => s === section ? 'active' : ''
        let kpis = JSON.parse(this.props.reportdata.report.kpis)

        let getDisplay = (view) => {
            if (view === "profit" && (profit === null || !profit?.enabled)) {
                return false
            }
            if (viewSettings && !viewSettings[view]) {
                return false
            }
            return true
        }

        if (!this.props.clickReport) {
            return (
                <>
                <div className="btn-group float-left" role="group">
                    <ShowIf if={getDisplay('overview')} >
                        <button type="button" className={`btn btn-sm btn-default ${getActive('overview')}`} style={{fontSize:12}} title="Matrix" onClick={() => this.props.changeView("overview")}> <i className="fa fa-th"></i> {viewSettings.enableLabel && <span className=" small-margin-left"> Matrix </span>} </button>
                    </ShowIf>
                    <ShowIf if={getDisplay('grid') }>
                        <button type="button" className={`btn btn-sm btn-default ${getActive('grid')}`} style={{fontSize:12}} title="Grid" onClick={() => this.props.changeView("grid")}> <i className="fa fa-list "></i> {viewSettings.enableLabel && <span className=" small-margin-left"> Grid</span>} </button>
                    </ShowIf>
                    <ShowIf if={getDisplay('kpis') }>
                        <button type="button" className={`btn btn-sm btn-default ${getActive('kpis')}`} style={{fontSize:12}}title="KPI" onClick={() => this.props.changeView("kpis")}> <i className="fa fa-window-maximize "></i>{viewSettings.enableLabel && <span className="small-margin-left"> KPI </span>} </button>
                    </ShowIf>
                    <ShowIf if={getDisplay('sum') }>
                        <button type="button" className={`btn btn-sm btn-default ${getActive('sum')}`} style={{fontSize:12}} title="Sum" onClick={() => this.props.changeView("sum")}> <i className="fa fa-table "></i> {viewSettings.enableLabel && <span className="small-margin-left"> Sum </span>} </button>
                    </ShowIf>
                    <ShowIf if={getDisplay('profit') }>
                        <button type="button" className={`btn btn-sm btn-default ${getActive('profit')}`} style={{fontSize:12}} title="Profit" onClick={() => this.props.changeView("profit")}> <i className="fa fa-calculator "></i> {viewSettings.enableLabel && <span className="small-margin-left"> Profit </span>} </button>
                    </ShowIf>
                    <ShowIf if={getDisplay('trend') }>
                        <button disabled={kpis.length === 0} type="button" className={`btn btn-sm btn-default ${getActive('trend', 'graph') || getActive('trend', 'diff')}`} style={{fontSize:12}} title={`Trend ${kpis.length === 0 ? "- you need at least one KPI to use this view" : ""}`} onClick={() => this.props.changeView("trend")}> <i className="fa fa-line-chart "></i> {viewSettings.enableLabel && <span className="small-margin-left"> Trend</span>}  </button>
                    </ShowIf>
                    
                </div>
                <div className="float-left margin-left-10px">
                    <ShowIf if={this.props.editMode} >
                        <button type="button" className={`btn btn-sm btn-primary`} title="Edit" onClick={() => this.refs.viewsEditDialog.show()} >Edit</button>
                        {/* <i className="fa fa-edit" title="Edit" onClick={() => this.refs.viewsEditDialog.show()} ></i> */}
                    </ShowIf>
                    <ViewsEditDialog ref="viewsEditDialog" title="Show/hide views" 
                        getSettings={() => this.getSettings()} 
                        setSettings={(key, value) => this.setSettings(key, value)} 
                        setDefaultView={(view) => this.setDefaultView(view)}
                        profits={ this.props.profits.get(this.props?.info?.info.id, null)} 
                        report_kpis={JSON.parse(this.props.reportdata.report.kpis)} 
                        optionNames={this.state.optionNames}
                        saveButtonDisabled={this.props.saveButtonDisabled}
                        saveSetup={this.props.saveSetup}
                        />
                </div>
                </>
            )
        }
        return (
            <div className="float-left">
                <button className="btn btn-default btn-sm" onClick={() => this.props.resetGrid()}>
                    <i className="fa fa-arrow-left"></i> Back</button>
                <p className="clickReportTitle">Fixed filter: {this.props.clickedColumn !== "" ? this.props.clickedColumn + " = " : ""} {this.props.clickedCategory}</p>
            </div>
        )
    }
}


class ViewsEditDialog extends React.Component {
    show() {
        this.refs.editDialog.show()
    }

    hide() {
        this.refs.editDialog.hide()
    }
    
    render() {

        let settings = this.props.getSettings()

        let report_kpis = this.props.report_kpis
        let defaultOptions = ['Matrix', 'Grid', 'KPI', 'Sum', 'Profit', 'Trend']
        let optionNames = this.props.optionNames

        let trendButtonDisabled = report_kpis.length === 0 && !settings.trend
        let trendEnabled = settings.trend || (report_kpis.length !== 0 && settings.trend === undefined)
        
        const profit = this.props.profits

        let getCheckIcon = (bool) => {
            if (bool) {
                return 'fa fa-check'
            }
            return 'fa fa-minus'
        }

        return (
            <SaveDialog ref="editDialog" title={this.props.title} closeHandler={() => this.hide()} saveHandler={() => {this.props.saveSetup(); this.hide();}} disableSave={this.props.saveButtonDisabled}>

            <div className="d-flex mb-2">
                <div className="mr-5 width-50p">

                    <ShowIf if={settings.overview === undefined || settings.overview}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("overview", !(settings.overview === undefined || settings.overview))}> <span className={`${getCheckIcon(settings.overview === undefined || settings.overview)}`}></span> </button>
                            <i className="fa fa-th small-margin-right"></i>
                            Matrix
                        </div>
                    </ShowIf>
                    <ShowIf if={settings.grid === undefined || settings.grid}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("grid", !(settings.grid === undefined || settings.grid))}> <span className={`${getCheckIcon(settings.grid === undefined || settings.grid)}`}></span> </button>
                            <i className="fa fa-list small-margin-right"></i>
                            Grid
                        </div>
                    </ShowIf>
                    <ShowIf if={settings.kpis === undefined || settings.kpis}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("kpis", !(settings.kpis === undefined || settings.kpis))}><span className={`${getCheckIcon(settings.kpis === undefined || settings.kpis)}`}></span></button>
                            <i className="fa fa-window-maximize small-margin-right"></i>
                            KPI
                        </div>
                    </ShowIf>
                    <ShowIf if={settings.sum === undefined || settings.sum}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("sum", !(settings.sum === undefined || settings.sum))}><span className={`${getCheckIcon(settings.sum === undefined || settings.sum)}`}></span></button>
                            <i className="fa fa-table small-margin-right"></i>
                            Sum
                        </div>
                    </ShowIf>
                    <ShowIf if={(settings.profit === undefined || settings.profit) && profit !== null && profit?.enabled}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("profit", !(settings.profit === undefined || settings.profit))}><span className={`${getCheckIcon(settings.profit === undefined || settings.profit)}`}></span></button>
                            <i className="fa fa-calculator small-margin-right"></i>
                            Profit
                        </div>
                    </ShowIf>
                    <ShowIf if={trendEnabled}>
                        <div className="mb-2">
                            <button disabled={trendButtonDisabled} title={trendButtonDisabled ? "Enable Trend: Add one or more KPIs in the blue bar of the report" : ""} className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("trend", !trendEnabled)}>
                                <span className={`${getCheckIcon(trendEnabled)}`}></span>
                            </button>
                            <i className="fa fa-line-chart small-margin-right"></i>
                            Trend
                        </div>
                    </ShowIf>
                    &nbsp;

                </div>
                <div className="mr-5 width-50p">
                <ShowIf if={settings.overview !== undefined && !settings.overview}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("overview", !(settings.overview === undefined || settings.overview))}> <span className={`${getCheckIcon(settings.overview === undefined || settings.overview)}`}></span> </button>
                            <i className="fa fa-th small-margin-right"></i>
                            Matrix
                        </div>
                    </ShowIf>
                    <ShowIf if={settings.grid !== undefined && !settings.grid}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("grid", !(settings.grid === undefined || settings.grid))}> <span className={`${getCheckIcon(settings.grid === undefined || settings.grid)}`}></span> </button>
                            <i className="fa fa-list small-margin-right"></i>
                            Grid
                        </div>
                    </ShowIf>
                    <ShowIf if={settings.kpis !== undefined && !settings.kpis}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("kpis", !(settings.kpis === undefined || settings.kpis))}><span className={`${getCheckIcon(settings.kpis === undefined || settings.kpis)}`}></span></button>
                            <i className="fa fa-window-maximize small-margin-right"></i>
                            KPI
                        </div>
                    </ShowIf>
                    <ShowIf if={settings.sum !== undefined && !settings.sum}>
                        <div className="mb-2">
                            <button className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("sum", !(settings.sum === undefined || settings.sum))}><span className={`${getCheckIcon(settings.sum === undefined || settings.sum)}`}></span></button>
                            <i className="fa fa-table small-margin-right"></i>
                            Sum
                        </div>
                    </ShowIf>
                    <ShowIf if={(settings.profit !== undefined && !settings.profit) || profit === null || !profit?.enabled}>
                        <div className="mb-2">
                            <button disabled={profit === null || !profit?.enabled} title={profit === null || !profit?.enabled ? "Profit view is not enabled in this data source" : ""} className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("profit", !(settings.profit === undefined || settings.profit))}><span className={`${getCheckIcon((settings.profit === undefined || settings.profit) && (profit !== null && profit?.enabled))}`}></span></button>
                            <i className="fa fa-calculator small-margin-right"></i>
                            Profit
                            <ShowIf if={profit === null || !profit?.enabled}>
                                <div className='small-disabled-message mb-2'>Profit View is not enabled in this data source</div>
                            </ShowIf>
                        </div>
                    </ShowIf>
                    <ShowIf if={!trendEnabled}>
                        <div className="mb-2">
                            <button disabled={trendButtonDisabled} title={trendButtonDisabled ? "Enable Trend: Add one or more KPIs in the blue bar of the report" : ""} className={`btn btn-default btn-xs margin-right-5px`} onClick={() => this.props.setSettings("trend", !trendEnabled)}>
                                <span className={`${getCheckIcon(trendEnabled)}`}></span>
                            </button>
                            <i className="fa fa-line-chart small-margin-right"></i>
                            Trend
                        </div>
                    </ShowIf>
                    &nbsp;
                </div>
            </div>

            <div className="width-100-p inline-block">
                <b id="alignfix" className="inline-block">Default view:</b>
                <div className="form-group width-150px inline-block margin-left-5px">
                    <select className="form-control form-control-sm margin-left-5px" id="sel1" value={settings.default_view} onChange={(e) => this.props.setDefaultView(e.target.value)}>
                        {
                            optionNames.map((s, index) => {
                                if (settings[s] || (settings[s] === undefined && s !== 'trend') || (s === 'trend' && trendEnabled)) {
                                    return <option key={index} value={optionNames[index]}>{defaultOptions[index]}</option>
                                }
                                return null
                            })
                        }
                    </select>
                </div>
                {
                    report_kpis.length === 0 && settings.default_view === 'trend' ?
                        <div className="danger-fix alert-danger form-control-sm inline-block margin-left-15px">
                            You cannot choose 'Trend' as default view when there are no KPIs in the Insight!
                        </div>
                        : null
                }
            </div>
            <div className="width-100-p inline-block">
                <b id="alignfix" className="inline-block" style={{fontSize:12}}>Show labels:</b>
                <div className="form-group width-150px inline-block margin-left-5px">
                        <input type='checkbox' className="vertical-align-middle" checked={settings.enableLabel ?? false} onChange={() => this.props.setSettings("enableLabel", !(settings.enableLabel === undefined || settings.enableLabel))}>
                        </input>
                </div>
            </div>
            </SaveDialog>)
    }
}

ReactNav = connect(mapStateToProps)(ReactNav)

export default ReactNav
