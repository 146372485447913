import React from 'react'

import ReactTooltip from 'react-tooltip'

interface InfoTooltipProps {
    text: string,
    style?: React.CSSProperties,
    className?: string,
    place?: "top" | "right" | "bottom" | "left" | undefined,
}

const InfoTooltip = ({text, style = {}, className = "", place = "top"}: InfoTooltipProps) => {

    return <i data-for="info-circle-tooltip" data-tip className={`fa fa-info-circle ${className}`} style={style}>
        <ReactTooltip id="info-circle-tooltip" effect="solid" place={place} >
            <div style={{maxWidth: 200}}>
                <span className="tooltip-font">
                {text}
                </span>
            </div>
        </ReactTooltip>
        </i>
}

export default InfoTooltip
