import { User } from "../types/transfertypes";
import { getLastLogin } from "./UserHelpers";


interface OrgUser extends User{
    location?: string
    job_title: string

}

const getOrgUserHTML = (users:OrgUser[], includeLocation?:boolean) => {
    const columns = [
        {name: "Name", key: "name"}, 
        {name: "Email", key: "email"}, 
        {name: "Job Title", key: "job_title"}, 
        {name: "Department", key: "department"},
        {name: "Language", key: "language"},
        {name: "Last Login", key: "last_login"}];
    if(includeLocation){
        columns.splice(4, 0, {name: "Location", key: "location"});
    }
    
    const columnHTML = '<tr><th>'  + columns.map(c => c.name).join('</th><th>') + '</th></tr>'

    let rowHTML = '<tr><td>' + users.map(u => {
        return columns.map(c => {
            if(c.key === "name"){
                return u.firstname + " " + u.lastname
            }
            if(c.key === "last_login"){
                return getLastLogin(u)
            }
            return u[c.key as keyof OrgUser]
        }).join('</td><td>')
    }).join('</td></tr><tr><td>') + '</td></tr>' //Makes outer array to HTML

    const style = '<style>th { white-space: nowrap; } td {white-space: nowrap; }</style>'
    
    return style + '<table>' + columnHTML + rowHTML + '</table>'
}

export {getOrgUserHTML}