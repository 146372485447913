import React, { useState, useEffect } from "react"
import { createUser } from "../../actions/UserActions"
import OrganizationAdministrationEditHomePageSetup from "./OrganizationAdministrationEditHomePageSetup"
import OrganizationAdministrationEditInfo from "./OrganizationAdministrationEditInfo"
import OrganizationAdministrationEditPermissions from "./OrganizationAdministrationEditPermissions"
import AlertBanner, { alertTypes } from "../Alert/AlertBanner"
import OrganizationAdministrationEditAccessControl from "./OrganizationAdministrationEditAccessControl"
import { notifyFailure } from '../../helpers/NotificationManager'

export default function OrganizationAdministrationCreate(props) {
    const [activeTab, setActiveTab] = useState(1)
    const [updatedUserInfo, setUpdatedUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        department: "",
        language: "English",
        home_dashboard: -1,
        phone_number: "",
        permissions: [],
        organization_id: props.orgID,
        favourite_reports: [],
        reports: props.organizationInfo && props.organizationInfo.use_access_control ? [] : (props.items ? props.items.reports : []),
        checklists: [],
        dashboards: props.organizationInfo && props.organizationInfo.use_access_control ? [] : (props.items ?props.items.dashboards : []),
        bucket_access: [],
    })
    
    const [infoValidate, setInfoValidate] = useState(false)
    const [showExitPrompt, setShowExitPrompt] = useState(false)
    const [canBeSaved, setCanBeSaved] = useState(false)
    const [updatedFavouriteReports, setUpdatedFavouriteReports] = useState([])
    const [userIsEmpty, setUserIsEmpty] = useState(true)
    const [totalTabs, setTotalTabs] = useState(0)
    const [alertConfig, setAlertConfig] = useState(null)
    const [tabIndex, setTabIndex] = useState([])
    
    useEffect(()=> {
        let tIndex = [
                {id: "info", tabIndex: 1},
                {id: "permissions", tabIndex: 2},
                {id: "access", tabIndex: 3},
                {id: "homepage", tabIndex: 4},
        ]

        let totalT = 4;

        if (props.organizationInfo && !props.organizationInfo.use_access_control) {
            tIndex[2].tabIndex = -1;
            tIndex[3].tabIndex = 3;
            totalT = 3;  
        }

        if(totalT != totalTabs){
            setTabIndex(tIndex)
            setTotalTabs(totalT)
        }

    }, [tabIndex, totalTabs, props.organizationInfo])

    useEffect(() => {
        let hasContent = false
        const userInfo = { ...updatedUserInfo }

        const user = {
            email: userInfo.email,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            phone_number: userInfo.phone_number,
            job_title: userInfo.job_title,
            department: userInfo.department,
            location: userInfo.location
        }

        const values = Object.values(user)
        for (const v of values) {
            if (!!v) {
                hasContent = true
            }
        }

        setUserIsEmpty(!hasContent)
    }, [updatedUserInfo])

    function userAlreadyExists() {
        notifyFailure("User with this email already exists")
    }

    function validateFields() {
        const userInfo = { ...updatedUserInfo }
        const user = {
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            email: userInfo.email,
            job_title: userInfo.job_title,
            department: userInfo.department,
            language: userInfo.language,
            location: userInfo.location
        }

        const keys = Object.keys(user)
        let canBeSaved = true
        for (const k of keys) {
            if (user[k] === "" || user[k] === null || user[k] === undefined) {
                if(k === "location" && !props.locationFieldSettings.location_field_mandatory){
                    canBeSaved = true
                } else{
                    canBeSaved = false
                }
            }
        }
        
        const phoneReg = /^\+?\d{1,3}[\s-]?\d{3,14}$/u
        const emailReg = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/u
        if (!!userInfo.phone_number && !phoneReg.test(userInfo.phone_number)) {
            canBeSaved = false
        } 
        if(!userInfo.phone_number) {
            userInfo.phone_number = "";
        }
        if (!emailReg.test(user.email)) {
            canBeSaved = false
        }
        setInfoValidate(true)
        setCanBeSaved(canBeSaved)

        if (!canBeSaved) {
            const config = {
                type: alertTypes.DANGER,
                title: "Missing field(s)",
                text: "Please provide the missing field(s)",
            }

            setAlertConfig(config)
        } else {
            setAlertConfig(null)
        }

        return canBeSaved
    }

    function permissionsChangedHandler(perms) {
        let user
        user = { ...updatedUserInfo }
        user.permissions = perms
        setUpdatedUserInfo(user)
    }

    function saveChangesHandler(sendWelcomeMail) {
        const canBeSaved = validateFields()

        if (canBeSaved) {
            const newUser = { ...updatedUserInfo }
            const updated = updatedFavouriteReports.map((fr) => fr.value).sort()
            let favourite_reports = updated.map((u) => {
                const favouriteItem = {
                    id: -1,
                    item_id: u,
                    item_type: "report",
                }

                return favouriteItem
            })
            
            newUser.sendwelcome = sendWelcomeMail

            newUser.favourite_reports = favourite_reports;

            if(newUser.location === undefined || newUser.location === null){
                newUser.location = ""
            }
            if(newUser.location !== "" && !props.locations.includes(newUser.location)){
                props.setLocations([...props.locations, {label: newUser.location, value: newUser.location}])
            }

            props.dispatch(createUser(newUser, userAlreadyExists))

            props.closeModalHandler()
        }
    }

    function homepageSetupChangeHandler(values, changedField) {
        if (changedField === "dashboard") {
            const updated = { ...updatedUserInfo, home_dashboard: values?.value ? values.value : 0 }
            setUpdatedUserInfo(updated)
        }

        if (changedField === "favouriteReports") {
            setUpdatedFavouriteReports(values)
        }
    }
    
    function userInfoChangedHandler(user) {
        const updated = { ...updatedUserInfo, ...user }
        setUpdatedUserInfo(updated)
    }

    function tabChangeHandler(value) {
        const canBeSaved = validateFields()
        if (canBeSaved) {
            setActiveTab(value)
        }
    }

    function closeEditModalHandler() {
        if (!userIsEmpty) {
            const config = {
                type: alertTypes.WARNING,
                title: "Changes will be lost",
                text: "Are you sure you want to exit?",
                dismiss: false,
                customButtons: (
                    <>
                        <button className="btn btn-outline-secondary mr-2" onClick={() => cancelExitPrompt()}>
                            Cancel
                        </button>
                        <button onClick={() => exitWithoutSavingHandler()} className="btn btn-danger">
                            Close
                        </button>
                    </>
                ),
            }
            setShowExitPrompt(true)
            setAlertConfig(config)
        } else {
            exitWithoutSavingHandler()
        }
    }

    function cancelExitPrompt() {
        setShowExitPrompt(false)
        setAlertConfig(null)
    }

    function exitWithoutSavingHandler() {
        setUpdatedUserInfo(props.user)
        setShowExitPrompt(false)
        props.closeModalHandler()
    }

    function bucketChangedHandler(buckets) {
        const updatedUser = {...updatedUserInfo}
        updatedUser.bucket_access = buckets.selected.map(b => b.id);
        const bucketIDs = buckets.selected.map(b => b.id);
        const reports = props.items.reports.filter((r) => 
           bucketIDs.includes(r.bucket))
        const dashboards = props.items.dashboards ? props.items.dashboards.filter((d) => 
          { 
            let accepted = true;
            for (const db of d.buckets) 
                { if (!bucketIDs.includes(db)) accepted = false }
                return accepted
        }) : []
        const favs = updatedFavouriteReports.map(ufr => ufr.value)
        let favourite_reports = reports.filter(fr => favs.includes(fr.report_id))
        favourite_reports = favourite_reports.filter(r => bucketIDs.includes(r.bucket))
        updatedUser.reports = reports;
        updatedUser.dashboards = dashboards;
        updatedUser.favourite_reports = favourite_reports

        setUpdatedFavouriteReports(favourite_reports.map(fr => { return {label: fr.name, value: fr.report_id}}))
        setUpdatedUserInfo(updatedUser)
    }

    return (
        <div className="">
            <div id="edit-user-modal" className={"org-admin-modal modal  " + (props.shown ? "display-block show" : "")} tabIndex="-1" role="dialog">
                {!!props.shown && (
                    <div className="modal-dialog vh-100 mt-5 modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content ">
                            <div className="modal-header edit-user-modal-header">
                                <h4 className="modal-title">Create new user</h4>
                            </div>

                            <div className="modal-body edit-user-modal-body ">
                                <section className="edit-user-tab-content">
                                    {activeTab === tabIndex[0]?.tabIndex && <OrganizationAdministrationEditInfo user={updatedUserInfo} mode="create" departments={props.departments} userInfoChangedHandler={userInfoChangedHandler} validated={infoValidate} locationFieldSettings={props.locationFieldSettings} locations={props.locations} />}
                                    {activeTab === tabIndex[1]?.tabIndex && <OrganizationAdministrationEditPermissions defaultRole={"user"} user={!!updatedUserInfo ? updatedUserInfo : props.user} departments={props.departments} permissionsChangedHandler={permissionsChangedHandler} />}
                                    {activeTab === tabIndex[2]?.tabIndex && <OrganizationAdministrationEditAccessControl user={updatedUserInfo} mode={"create"} items={props.items} departments={props.departments} bucketChangedHandler={bucketChangedHandler} />}
                                    {activeTab === tabIndex[3]?.tabIndex && <OrganizationAdministrationEditHomePageSetup dispatch={props.dispatch} favReports={updatedFavouriteReports} user={updatedUserInfo} homepageSetupChangeHandler={homepageSetupChangeHandler} />}
                                </section>
                            </div>
                            <div className="modal-footer ">
                                {showExitPrompt && alertConfig ? (
                                    <AlertBanner config={alertConfig}></AlertBanner>
                                ) : (
                                    <div className="w-100">
                                        <section className="d-flex justify-content-center flex-column w-100">
                                            <div className="align-self-center org-admin-create-indicator-group mb-2">
                                                <div className={"org-admin-create-indicator " + (activeTab >= 1 ? " bg-primary" : "bg-secondary")}>{activeTab > 1 && <i className="fa fa-check"></i>}</div>
                                                <div className={"org-admin-create-indicator " + (activeTab >= 2 ? " bg-primary" : " bg-secondary")}>{activeTab > 2 && <i className="fa fa-check"></i>}</div>
                                                <div className={"org-admin-create-indicator " + (activeTab >= 3 ? " bg-primary" : "bg-secondary")}>{activeTab > 3 && <i className="fa fa-check"></i>}</div>
                                                { props.organizationInfo && props.organizationInfo.use_access_control ? 
                                                    <div className={"org-admin-create-indicator " + (activeTab >= 4 ? " bg-primary" : "bg-secondary")}>{activeTab > 4 && <i className="fa fa-check"></i>}</div>
                                                : <></>
                                                }
                                            </div>

                                            <nav className="d-flex justify-content-between flex-column align-items-end w-100">
                                                {!!alertConfig && <AlertBanner config={alertConfig}></AlertBanner>}
                                                <ul className="pagination ml-2">
                                                    <li className="page-item ml-3">
                                                        <button className="btn btn-outline-primary" onClick={closeEditModalHandler}>
                                                            Close
                                                        </button>
                                                    </li>
                                                    <li className="page-item ml-3">
                                                        <button className="btn btn-outline-primary" disabled={activeTab === 1} onClick={() => tabChangeHandler(activeTab - 1)}>
                                                            <span aria-hidden="true">&laquo;</span> Previous
                                                        </button>
                                                    </li>
                                                    {
                                                        activeTab !== totalTabs ? 
                                                        <li className="page-item ml-3">
                                                            <button className="btn btn-primary" onClick={() => tabChangeHandler(activeTab + 1)}>
                                                                Next <span aria-hidden="true">&raquo;</span>
                                                            </button>
                                                        </li>
                                                        :
                                                        <>
                                                            <li className={"page-item ml-2 " + (!canBeSaved ? "disabled" : "")}>
                                                                <button onClick={() => saveChangesHandler(true)}  className="btn btn-primary" disabled={!canBeSaved}>
                                                                    Save &amp; Send mail
                                                                </button>
                                                            </li>
                                                            <li className={"page-item ml-2 " + (!canBeSaved ? "disabled" : "")}>
                                                                <button onClick={() => saveChangesHandler(false)} className="btn btn-primary" disabled={!canBeSaved}>
                                                                    Save
                                                                </button>
                                                            </li>
                                                            
                                                        </>
                                                    }
                                                </ul>
                                            </nav>
                                        </section>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!!props.shown && <div className="fade modal-backdrop show"></div>}
        </div>
    )
}
