import * as ActionTypes from '../../actions/ActionTypes'
import { gotFavouriteReportInfo, gotHomeInfo, gotLibraryInfo, gotLibraryAdminInfo, getLibraryInfo } from '../../actions/InfoActions'
import { apiGet, apiPut } from '../../helpers/ApiHelpers'
import { gotReportInfo, reportNotFound, reportNotAccessible } from '../../actions/ReportActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'
import { notifySuccess } from '../../helpers/NotificationManager'

const InfoApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_FAVOURITE_REPORTS_INFO:
            url = 'reports/favourites'
            apiGet(url, reports => dispatch(gotFavouriteReportInfo(reports)), e => notifyApiFailure("retrieving favorite Insights", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_HOME_INFO:
            url = 'reports/home'
            apiGet(url, info => dispatch(gotHomeInfo(info)), e => notifyApiFailure("getting home contents", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_LIBRARY_INFO:
            url = 'reports/library'
            apiGet(url, info => dispatch(gotLibraryInfo(info)), e => notifyApiFailure("getting library contents", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_LIBRARY_ADMIN_INFO:
            url = 'reports/libraryAdmin'
            apiGet(url, info => dispatch(gotLibraryAdminInfo(info)), e => notifyApiFailure("getting admin library contents", 'GET', url, data, e.status))
            break
        case ActionTypes.GET_REPORT_INFO:
            url = `report/info/${action.payload.id}`
            apiGet(url, info => dispatch(gotReportInfo(info)), e => {
                if(e.status === 404) dispatch(reportNotFound())
                else if(e.status === 403) dispatch(reportNotAccessible())
                else notifyApiFailure("getting Insight information", 'GET', url, data, e.status)
            })
            break
        case ActionTypes.MOVE_REPORTS_TO_GROUP:
            url = `library/assignGroup`
            apiPut(url, action.payload, _ => {
                dispatch(getLibraryInfo())
                notifySuccess("Successfully moved to group")
            }, e => {
                notifyApiFailure("assigning Insights to group", 'PUT', url, action.payload, e.status)
            })
            break
        case ActionTypes.ASSIGN_REPORTS_TO_OWNER:
            url = 'library/assignOwner'
            apiPut(url, action.payload, _ => {
                dispatch(getLibraryInfo())
                notifySuccess("Successfully assigned new owner")
            }, e => {
                notifyApiFailure("assigning new owner", 'PUT', url, action.payload, e.status)
            })
            break
        case ActionTypes.DELETE_MULTIPLE_REPORTS:
            url = 'library/deleteReports'
            apiPut(url, action.payload, _ => {
                dispatch(getLibraryInfo())
                notifySuccess("Successfully deleted Insights")
            }, e => {
                notifyApiFailure("deleting multiple Insights", "PUT", url, action.payload, e.status)
            })
            break
        case ActionTypes.CHANGE_MULTIPLE_REPORT_STATUS:
            url = 'library/changeStatus'
            apiPut(url, action.payload, _ => {
                dispatch(getLibraryInfo())
                notifySuccess("Successfully changed Insights")
            }, e => {
                notifyApiFailure("changing Insight status", "PUT", url, action.payload, e.status)
            })
            break
            
        default:
            break
    }
}

export default InfoApiMiddleware
