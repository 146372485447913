import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashboardWidgets from '../components/Dashboard/DashboardWidgets'
import { apiGet } from '../helpers/ApiHelpers'
import ShowIf from '../components/Generic/ShowIf'
import DashboardTable2 from '../components/Dashboard/DashboardTable2'

const DashboardSecret = () => {
    const { secret, dId, include_table} = useParams()
    const [data, setData] = useState([])
    const [hoverReportId, sethoverReportId] = useState([])
    const [hoverKpiKey, sethoverKpiKey] = useState([])

    let getWidthForPlaywright = () => {
        let widgetData = data.widget_data
        //the width value for playwright can be between 1 and 6 
        // one large widget equeals 2 small widgets. 
        let res = 0
        if(widgetData !== null){
            let largeWidgetRowLength = 0 // getting the largest row length for large widgets
            widgetData.forEach(wd => {
                if (wd.type === "large") {
                    const rowLength = wd.widgets.filter(w => w.shown).length
                    if (rowLength > largeWidgetRowLength) {
                        largeWidgetRowLength = rowLength * 2 // converting a large widget row to playwright width
                    }
                }
            })

            let smallWidgetRowLength = 0 // getting the largest row length for small widgets
            widgetData.forEach(wd => {
                if (wd.type === "small") {
                    const rowLength = wd.widgets.filter(w => w.shown).length
                    if (rowLength > largeWidgetRowLength) {
                        smallWidgetRowLength = rowLength
                    }
                }
            })

            res = largeWidgetRowLength
            if (smallWidgetRowLength > res) { // if there exists a small widget row with more widgets than the largest widget row
                res = smallWidgetRowLength
            }
        }
        res = 8 + 224 * res //actual width in pixels for playwright script)

        let _include_table = include_table === "true" || include_table === 1 || include_table === "1" || include_table === true

        if(_include_table && res < 100 + (JSON.parse(data.dashboard_data.kpis).length + 1) * 160){
            res = 100 + (JSON.parse(data.dashboard_data.kpis).length + 1) * 160
        }

        return res
    };


    let _include_table = include_table === "true" || include_table === 1 || include_table === "1" || include_table === true

    useEffect(() => {
        apiGet(`singledashboard/${dId}/${secret}`, data => {
            setData(data)
        }, error => console.log(error))
    }, [dId, secret])
    if (!secret || !dId) return null;
    if (data.length === 0) return null;

    const pdfDate = new Date().toLocaleString('da-DK').split(' ')[0].replaceAll('.', '/')

    return <div className="mt-2">
        <div style={{ display: "none" }} id={`playwrightWidth`}>{getWidthForPlaywright()}</div>
        
        <div style={{ opacity: "50%"}}>
            <div className='pt-2' style={{ float:"right", marginRight:"50px"}}>
                <img src="./img/inact_now_logo_black.svg" alt="Inact Now" style={{height: "25px"}} />
            </div>

            <div style={{ float:"left", marginLeft:"50px" }} >
                <div id="dashboardTitle" style={{ fontSize: 25 }}>{data.dashboard_data.title}</div>
                <div className=''style={{fontSize:"smaller"}}><span className=' text-muted'></span> {pdfDate}</div>
            </div>
            <div style={{ clear: "both"}}></div>
        </div>

        <ShowIf if={data.widget_data !== null}>
            <div className='mb-4' style={{ borderBottom: "1px solid rgb(229 231 235)", marginLeft: 50, marginRight:50 }}></div>
            <div className='pb-2'>
                <DashboardWidgets fromHome={true} fromSecret={true} widgetData={data.widget_data} />
            </div>
        </ShowIf>
        <ShowIf if={_include_table}>
            <div className="" style={{margin: "auto", width: "fit-content"}}>
                <div className='pb-2 secretdashboard ' style={{paddingLeft: 50, paddingRight:50, width: "fit-content" }}>
                    <DashboardTable2
                        setHoverValues={(id, key) => {sethoverReportId(id); sethoverKpiKey(key)}}
                        hoverRow={hoverReportId}
                        hoverKpi={hoverKpiKey}
                        reports={data.reports}
                        dashboard={data}
                        style={{borderTop: "1px solid #f2f2f2"}}
                        />
                </div>
            </div>
        </ShowIf>

    </div>
}

export default DashboardSecret
