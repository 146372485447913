import React from 'react'
import { deepClone } from './GeneralHelpers'
import Progress from '../components/Generic/Progress'
import { SingleKeyMap } from './Collections'
import { AggregationData, Model, ReportData, Report, Column, KPI, Slicer, Category, User, KPIConfig, DiffDataValue, ProfitabilitySection, ProfitabilitySectionTotal, BucketColumnType } from './../types/transfertypes'
import type { IndexedColumn, kpi } from './../types/types'
import { CompareType } from '../types/slicertypes'

export const getCategoryValue = (segment: string, useDecimals: boolean, usePercentage: boolean, selectedAggregation: string, aggData: AggregationData | null | undefined): string => {
    if (null !== aggData && aggData !== undefined) {
        let foundSegment = null
        if (undefined !== aggData.segments[segment]) {
            foundSegment = aggData.segments[segment]
        } else if ('' === segment && undefined !== aggData.total) {
            foundSegment = aggData.total
        }

        let totalSegment = null
        if (undefined !== aggData.total) {
            totalSegment = aggData.total
        }

        if (null !== foundSegment && null !== totalSegment) {
            let value = 0
            let totalValue = -1
            if (selectedAggregation === 'count') {
                value = foundSegment.count
                totalValue = totalSegment.count
            } else if (selectedAggregation === 'sum') {
                value = foundSegment.sum
                totalValue = totalSegment.sum
            } else if (selectedAggregation === 'min') {
                value = foundSegment.min
                totalValue = totalSegment.min
            } else if (selectedAggregation === 'max') {
                value = foundSegment.max
                totalValue = totalSegment.max
            } else if (selectedAggregation === 'avg') {
                value = foundSegment.avg
                totalValue = totalSegment.avg
            }

            if (usePercentage) {
                if (0 !== totalValue) {
                    value = value / totalValue * 100
                } else {
                    value = 0
                }
            }

            let decimalCount = useDecimals ? 2 : 0
            var valueString = value.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })

            if (usePercentage) {
                valueString = valueString + '%'
            }

            return valueString
        } else {
            return '-'
        }
    }

    return '...'
}

export const getIdColumn = (columns: Column[]): string => {
    let res = ''
    columns.forEach((item, index) => {
        if (item.type === 'id' && res === '') {
            res = item.name
        }
    })
    return res
}

export const getFilter = (report: Report, slicers: Slicer[]): Slicer[] => {
    return slicers.concat(JSON.parse(report.filter))
}

export const getCompareTypeSymbol = (ct: CompareType): JSX.Element => {
    switch (ct) {
        case CompareType.EQ: //Equals
            return <span>=</span>
        case CompareType.LEQ: //Less or equal to
            return <span>&le;</span>
        case CompareType.GEQ: //Greater or equal to
            return <span>&ge;</span>
        case CompareType.GT: //Greater than
            return <span>&gt;</span>
        case CompareType.LT: //Less than
            return <span>&lt;</span>
        case CompareType.NEQ: //Not equal to
            return <span>&ne;</span>
        case CompareType.STARTS: //starts with
            return <span>starts with</span>
        case CompareType.ENDS: //ends with
            return <span>ends with</span>
        case CompareType.CONTAINS: //Contains
            return <span>contains</span>
        case CompareType.NSTARTS: //Doesn't start with
            return <span>doesn't start with</span>
        case CompareType.NENDS: //Doesn't end with
            return <span>doesn't end with</span>
        case CompareType.NCONTAINS: //Does not contain
            return <span>doesn't contain</span>
        case CompareType.BETWEEN:
            return <span>between</span>
        case CompareType.NBETWEEN:
            return <span>not between</span>
        default:
            return <span>?</span>
    }
}

export const getHighestPos = (categories: Category[], y: number): boolean => {
    let minPos = 1000
    categories.forEach((item, index) => {
        minPos = Math.min(minPos, item.position.y)
    })

    return minPos === y
}

export const getMaxX = (categories: Category[]): number => {
    let maxX = 0
    categories.forEach((item, index) => {
        maxX = Math.max(maxX, item.position.x)
    })

    return maxX
}

export const getKpiValue = (kpi: KPI, aggData: SingleKeyMap<any> | null | undefined) => {
    if (aggData) {
        let kpiData = aggData.get(kpi.column, null)
        if (kpiData) {
            return formatValue(true, kpiData.total[kpi.aggregation], kpi.showKPIDecimals ?? false)
        }
    }
    return '-'
}

export const formatValueIntl = (isNumber: boolean, value: any, showDecimals: boolean) => {
    if (value === '-') return '-'
    let decimalCount = showDecimals ? 2 : 0
    if (isNumber && null !== value) {
        let x = parseFloat(value)
        if (isNaN(x)) {
            return '?'
        }
        return x.toLocaleString(undefined, { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })
    }
    return value
}


export const formatValue = (isNumber: boolean, value: any, showDecimals: boolean) => {
    if (value === '-') return '-'
    let decimalCount = showDecimals ? 2 : 0
    if (isNumber && null !== value) {
        let x = parseFloat(value)
        if (isNaN(x)) {
            return '?'
        }
        return x.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })
    }
    return value
}

export const isTextColumn = (name: string, model: Model) => {
    return isColumnType(name, model, ['text', 'categorization'])
}


export const isNumberColumn = (name: string, model: Model) => {
    return isColumnType(name, model, ['decimal'])
}


export const isDateColumn = (name: string, model: Model) => {
    return isColumnType(name, model, ['date'])
}


export const isIDColumn = (name: string, model: Model) => {
    return isColumnType(name, model, ['id'])
}

export const isColumnType = (name: string, model: Model, types: string[]) => {
    for (let i = 0; i < model.columns.length; i++) {
        if (model.columns[i].name === name && types.includes(model.columns[i].type)) {
            return true
        }
    }

    return false
}

export const getShownDecimals = (kpis: KPI[], column: string) => {
    let res = false
    for (let i = 0; i < kpis.length; i++) {
        const item = kpis[i]
        if (item.column === column && undefined !== item.showKPIDecimals) {
            res = item.showKPIDecimals
            break
        }

    }

    return res
}

export const getDiffColor = (total: number) => {
    if (total > 0) {
        return 'green-text'
    }

    if (total < 0) {
        return 'red-text'
    }
    return
}

export const monthValue = (type: string, val: DiffDataValue) => {
    if (type === 'start') {
        return val.status === -1 ? val.start_value : val.status === 1 ? '-' : val.start_value
    } else if (type === 'end') {
        return val.status === 1 ? val.end_value : val.status === -1 ? '-' : val.end_value
    }
}

export const getTextColumns = (columns: string[], modelColumns: Column[]) => {
    let textColumns: string[] = []
    columns.forEach((item, index) => {
        modelColumns.forEach((item2, index2) => {
            if (item === item2.name && (item2.type === 'text' || item2.type === 'categorization')) {
                textColumns.push(item)
            }
        })
    })

    return textColumns.sort()
}

export const findNumberColumns = (model: Model, columns: string[]) => {
    let foundColumns = []
    let numberColumns = getNumberColumns(model)
    for (let i = 0; i < numberColumns.length; i++) {
        for (let j = 0; j < columns.length; j++) {
            if (numberColumns[i] === columns[j]) {
                foundColumns.push(columns[j])
            }
        }
    }

    return foundColumns
}

let getNumberColumns = (model: Model) => {
    let columns: string[] = []
    if (!model.columns) return columns
    for (let i = 0; i < model.columns.length; i++) {
        if (model.columns[i].type === 'decimal') {
            columns.push(model.columns[i].name)
        }
    }

    return columns.sort()
}

export const getNumberColumnsObj = (model: Model) => {
    let columns: Column[] = []
    for (let i = 0; i < model.columns.length; i++) {
        if (model.columns[i].type === 'decimal') {
            columns.push(model.columns[i])
        }
    }

    return columns.sort(sortByNameProp)
}

export const getTextColumnsObj = (model: Model) => {
    let columns: Column[] = []
    for (let i = 0; i < model.columns.length; i++) {
        if (model.columns[i].type === 'text') {
            columns.push(model.columns[i])
        }
    }

    return columns.sort(sortByNameProp)
}

export const getTextColumnsObjWithCat = (model: Model) => {
    let columns: Column[] = []
    for (let i = 0; i < model.columns.length; i++) {
        if (model.columns[i].type === 'text' || model.columns[i].type === 'categorization') {
            columns.push(model.columns[i])
        }
    }

    return columns.sort(sortByNameProp)
}

export const sortByNameProp = (a: { name: string, [key: string]: any }, b: { name: string, [key: string]: any }) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
}

export const getColumnNames = (model: Model) => {
    let names: string[] = []
    if (!model.columns) return names
    for (var i = 0; i < model.columns.length; i++) {
        names.push(model.columns[i].name)
    }
    return names
}

export const getCateoryValueKpiView = (segment: string, item: any/*TODO: use correct type*/) => {
    if (item.data !== undefined) {
        let aggregation = item.data
        if (null !== aggregation) {
            let foundSegment = null
            if (undefined !== aggregation.segments[segment]) {
                foundSegment = aggregation.segments[segment]
            } else if ('' === segment && undefined !== aggregation.total) {
                foundSegment = aggregation.total
            }

            let totalSegment = null;
            if (undefined !== aggregation.total) {
                totalSegment = aggregation.total
            }

            if (null !== foundSegment && null != totalSegment) {
                let value = 0
                let totalValue = 1
                if (item.aggregation === 'count') {
                    value = foundSegment.count
                    totalValue = totalSegment.count
                } else if (item.aggregation === 'sum') {
                    value = foundSegment.sum
                    totalValue = totalSegment.sum;
                } else if (item.aggregation === 'min') {
                    value = foundSegment.min
                    totalValue = totalSegment.min
                } else if (item.aggregation === 'max') {
                    value = foundSegment.max
                    totalValue = totalSegment.max
                } else if (item.aggregation === 'avg') {
                    value = foundSegment.avg
                    totalValue = totalSegment.avg
                }

                if (item.showPercentages) {
                    if (0 !== totalValue) {
                        value = value / totalValue * 100
                    } else {
                        value = 0
                    }
                }

                let decimalCount = item.showDecimals ? 2 : 0;
                let stringValue = value.toLocaleString('da', { maximumFractionDigits: decimalCount, minimumFractionDigits: decimalCount })

                if (item.showPercentages) {
                    stringValue = stringValue + ' %'
                }

                return stringValue
            } else {
                return '-'
            }
        }

        return '...'
    }
    return '...'
}

export const slicerEquals = (s1: Slicer | null, s2: Slicer | null) => {
    return s1 && s2 && s1.compare_type === s2.compare_type && s1.target_values.join("-") === s2.target_values.join("-") && s1.column === s2.column && s1.id === s2.id
}

export const canKpiColumn = (agg: string, column: string, model: Model) => {
    if (('sum' === agg || 'min' === agg || 'max' === agg || 'avg' === agg) && isNumberColumn(column, model)) {
        return true
    } else if (!isNumberColumn(column, model)) {
        return 'count' === agg
    }

    return false
}

export const getColumnType = (name: string, model: Model) => {
    let type = ""

    for (let i = 0; i < model.columns.length; i++) {
        const element = model.columns[i]
        if (name === element.name) {
            type = element.type
            break
        }
    }

    return type
}

export const getReportOwner = (users: User[], userid: number) => {
    let res = null
    for (let i = 0; i < users.length; i++) {
        const element = users[i];
        if (element.id === userid) {
            res = element
            break
        }
    }

    return res
}

export const fixReportDataConsistency = (data: Report, reportColumns: string[]) => {
    data = deepClone(data)
    let columns: string[] = Object.assign([], JSON.parse(data.columns))
    if (columns) {
        columns = columns.filter(c => reportColumns.includes(c))
    }

    let kpiArr = Object.assign([], JSON.parse(data.kpis))
    if (kpiArr) {
        for (let i = kpiArr.length - 1; i >= 0; i--) {
            let k = kpiArr[i]
            if (!reportColumns.includes(k.column)) {
                kpiArr.splice(i, 1)
            }
        }
    }

    let configArr = []
    if (data.kpi_config && (data.kpi_config !== "" && data.kpi_config !== 'no config')) {
        configArr = JSON.parse(data.kpi_config)
        for (let i = configArr.length - 1; i >= 0; i--) {
            let k = configArr[i]
            if (k.column && !reportColumns.includes(k.column)) {
                configArr.splice(i, 1, { shown: false })
            }
        }
    }

    let slicers = Object.assign([], JSON.parse(data.filter))
    if (slicers) {
        for (let i = slicers.length - 1; i >= 0; i--) {
            let s = slicers[i]
            if (!reportColumns.includes(s.column)) {
                slicers.splice(i, 1)
            }
        }
    }

    let fixedSlicers = []
    if (data.fixed_slicer && data.fixed_slicer !== "") {
        try {
            fixedSlicers = JSON.parse(data.fixed_slicer)
        } catch (e) {
            if (typeof data.fixed_slicer === 'string') {
                fixedSlicers = [data.fixed_slicer]
            }
        }

        for (let i = fixedSlicers.length - 1; i >= 0; i--) {
            let s = fixedSlicers[i]
            if (!reportColumns.includes(s)) {
                fixedSlicers.splice(i, 1)
            }
        }
    }

    let sortColumn = data.sort_column
    let sortDirection = data.sort_direction
    let limit = data.limit
    if (sortColumn && sortColumn !== "") {
        if (!reportColumns.includes(data.sort_column)) {
            sortColumn = ""
            sortDirection = "desc"
            limit = -1
        }
    }

    let viewSettings = JSON.parse(data.view_settings)

    if (viewSettings.detailed_report_columns) {
        let drc = []
        drc = viewSettings.detailed_report_columns.slice()
        for (let i = drc.length - 1; i >= 0; i--) {
            let c = drc[i]
            if (!reportColumns.includes(c)) {
                drc.splice(i, 1)
            }
        }
        viewSettings.detailed_report_columns = drc
    }


    if (viewSettings.actual) {
        let actual = viewSettings.actual
        if (actual.overview && actual.overview.selected_column && !reportColumns.includes(actual.overview.selected_column)) {
            viewSettings.actual.overview = undefined
        }

        if (actual.grid && actual.grid.selected_grid_column && !reportColumns.includes(actual.grid.selected_grid_column)) {
            viewSettings.actual.grid = undefined
        }

        if (actual.graph && actual.graph.selected_graph_column && !reportColumns.includes(actual.graph.selected_graph_column)) {
            viewSettings.actual.graph = undefined
        }
    }

    if (viewSettings.trend) {
        let trend = viewSettings.trend
        if (trend.diff && trend.diff.graph_column && !reportColumns.includes(trend.diff.graph_column)) {
            viewSettings.trend.diff = undefined
        }
    }

    data.columns = JSON.stringify(columns)
    data.kpis = JSON.stringify(kpiArr)
    data.kpi_config = JSON.stringify(configArr)
    data.filter = JSON.stringify(slicers)
    data.fixed_slicer = JSON.stringify(fixedSlicers)
    data.sort_column = sortColumn
    data.sort_direction = sortDirection
    data.limit = limit
    data.view_settings = JSON.stringify(viewSettings)
    return data
}

export const getReportDataConsistencyIssues = (data: Report, reportColumns: string[]) => {
    let issues: { [key: string]: string[] } = {}

    let columns: string[] = Object.assign([], JSON.parse(data.columns))
    let columnIssues: string[] = []
    if (columns) {
        columns.forEach(c => {
            if (!reportColumns.includes(c)) {
                columnIssues.push(c)
            }
        })
    }
    issues.columns = columnIssues

    let kpiArr: KPI[] = Object.assign([], JSON.parse(data.kpis))
    let kpisIssues: string[] = []
    if (kpiArr) {
        kpiArr.forEach((k, i) => {
            if (!reportColumns.includes(k.column)) {
                kpisIssues.push(k.column)
            }
        })
    }
    issues.kpis = kpisIssues

    let configArr: KPIConfig[] = []
    let kpiConfigIssues: string[] = []
    if (data.kpi_config && (data.kpi_config !== "" && data.kpi_config !== 'no config')) {
        configArr = JSON.parse(data.kpi_config)
        configArr.forEach((k, i) => {
            if (k.column && !reportColumns.includes(k.column)) {
                kpiConfigIssues.push(k.column)
            }
        })
    }
    issues.kpi_config = kpiConfigIssues

    let filterIssues: string[] = []
    let slicers: Slicer[] = JSON.parse(data.filter)
    if (slicers) {
        slicers.forEach((s, i) => {
            if (!reportColumns.includes(s.column)) {
                filterIssues.push(s.column)
            }
        })
    }
    issues.filter = filterIssues

    let fixedSlicerIssues: string[] = []
    let fixedSlicers: string[] = []
    if (data.fixed_slicer && data.fixed_slicer !== "") {
        try {
            fixedSlicers = JSON.parse(data.fixed_slicer)
        } catch (e) {
            if (typeof data.fixed_slicer === 'string') {
                fixedSlicers = [data.fixed_slicer]
            }
        }

        fixedSlicers.forEach((s, i) => {
            if (!reportColumns.includes(s)) {
                fixedSlicerIssues.push(s)
            }
        })
    }
    issues.fixed_slicer = fixedSlicerIssues

    let sortColumnIssues = []
    let sortColumn = data.sort_column
    if (sortColumn && sortColumn !== "") {
        if (!reportColumns.includes(data.sort_column)) {
            sortColumnIssues.push(data.sort_column)
        }
    }
    issues.sort_column = sortColumnIssues

    let viewSettings = JSON.parse(data.view_settings)

    let detailedReportColumnsIssues: string[] = []
    let drc: any[] = []
    if (viewSettings.detailed_report_columns) {
        drc = viewSettings.detailed_report_columns.slice()
    }

    drc.forEach((c, i) => {
        if (c.column && c.shown && !reportColumns.includes(c.column)) { //old column object
            detailedReportColumnsIssues.push(c.column)
        } else if (!c.column && !reportColumns.includes(c)) { //new column object (just a string)
            detailedReportColumnsIssues.push(c)
        }
    })
    viewSettings.detailed_report_columns = drc
    issues.detailed_report_columns = detailedReportColumnsIssues

    let overviewColumnIssues = []
    let gridColumnIssues = []
    let graphColumnIssues = []
    if (viewSettings.actual) {
        let actual = viewSettings.actual
        if (actual.overview && actual.overview.selected_column && !reportColumns.includes(actual.overview.selected_column)) {
            overviewColumnIssues.push(actual.overview.selected_column)
        }

        if (actual.grid && actual.grid.selected_grid_column && !reportColumns.includes(actual.grid.selected_grid_column)) {
            gridColumnIssues.push(actual.grid.selected_grid_column)
        }

        if (actual.graph && actual.graph.selected_graph_column && !reportColumns.includes(actual.graph.selected_graph_column)) {
            graphColumnIssues.push(actual.graph.selected_graph_column)
        }
    }
    issues.selected_column = overviewColumnIssues
    issues.selected_grid_column = gridColumnIssues
    issues.selected_graph_column = graphColumnIssues

    let diffColumnIssues = []
    if (viewSettings.trend) {
        let trend = viewSettings.trend
        if (trend.diff && trend.diff.graph_column && !reportColumns.includes(trend.diff.graph_column)) {
            diffColumnIssues.push(trend.diff.graph_column)
        }
    }
    issues.diff_graph_column = diffColumnIssues

    return issues
}

export const hasReportDataCosistencyIssues = (issues: { [key: string]: string[] }) => {
    let hasIssues = false
    for (var key in issues) {
        if (issues.hasOwnProperty(key)) {
            let value = issues[key]
            if (value && value.length > 0) {
                hasIssues = true
                break
            }
        }
    }
    return hasIssues
}

//Function to check if KPIs changed other than their order.
//It's not concerned with showKPIDecimals, showPercentage and trend, only aggregation and column
export const shouldKPIsChangeUpdateData = (prevKPIsString: string, KPIsString: string) => {
    if (prevKPIsString === KPIsString) return false //if the objects are identical
    let prevKPIs: KPI[] = JSON.parse(prevKPIsString)
    let KPIs: KPI[] = JSON.parse(KPIsString)
    if (prevKPIs.length !== KPIs.length) return true //if a KPI was added or removed
    return prevKPIs.some(pKPI => KPIs.every(KPI => KPI.aggregation !== pKPI.aggregation || KPI.column !== pKPI.column)) //if for some previous KPI, that it is different than all current KPIs
}


//Function to check if the changes in the report info will trigger an update.
export const shouldReportInfoChangeUpdateData = (prev: ReportData, curr: ReportData) => {
    return (
        JSON.stringify(prev.info) !== JSON.stringify(curr.info) ||
        prev.report.filter !== curr.report.filter ||
        shouldKPIsChangeUpdateData(prev.report.kpis, curr.report.kpis) ||
        prev.report.kpi_config !== curr.report.kpi_config ||
        prev.report.columns !== curr.report.columns ||
        prev.report.sort_column !== curr.report.sort_column ||
        prev.report.sort_direction !== curr.report.sort_direction ||
        prev.report.view_settings !== curr.report.view_settings ||
        prev.report.fixed_slicer !== curr.report.fixed_slicer ||
        prev.report.limit !== curr.report.limit ||
        prev.report.bucket !== curr.report.bucket
    )
}

export const convertColumns = (arr: string[], reportdata: ReportData): IndexedColumn[] => {
    return arr.map((c, i) => {
        let d = reportdata.info.model.columns.find((e) => {
            return e.name.toLowerCase() === c.toLowerCase()
        })
        return { name: c, type: d ? d.type : BucketColumnType.Text, index: JSON.parse(reportdata.report.columns).findIndex((_c: string) => _c === c) }
    })
}

export const sortDataRows = (columnName: string, columnIndex: number, direction: boolean, rows: any[][], reportdata: ReportData) => {
    if (rows.length === 0 || columnIndex < 0 || columnIndex >= rows[0].length) return
    if (direction) { // desc
        if (typeof rows[0][columnIndex] === "number") { // if the it's a number column
            rows.sort((a, b) => {
                return b[columnIndex] - a[columnIndex]
            })
        } else {
            const col = reportdata ? reportdata.info.model.columns.find(v => { if (v.name === columnName) { return v } return undefined }) : undefined
            if (col && col.type === 'categorization') {
                rows.sort((a, b) => {
                    return reportdata.info.model.categorization.findIndex(e => e.name === a[columnIndex]) - reportdata.info.model.categorization.findIndex(e => e.name === b[columnIndex])
                })
            } else {
                rows.sort((a, b) => {
                    return b[columnIndex].localeCompare(a[columnIndex]) // if it's a string column
                })
            }
        }
    } else { // asc
        if (typeof rows[0][columnIndex] === "number") {
            rows.sort((a, b) => {
                return a[columnIndex] - b[columnIndex]
            })
        } else {
            const col = reportdata ? reportdata.info.model.columns.find(v => { if (v.name === columnName) { return v } return undefined }) : undefined
            if (col && col.type === 'categorization') {
                rows.sort((a, b) => {
                    return reportdata.info.model.categorization.findIndex(e => e.name === b[columnIndex]) - reportdata.info.model.categorization.findIndex(e => e.name === a[columnIndex])
                })
            } else {
                rows.sort((a, b) => {
                    return a[columnIndex].localeCompare(b[columnIndex])
                })
            }
        }
    }
}

export const getProgressBarJSX = (absmax: number) => (value: any, rawValue: number) => {
    //absmax = absolute maximum
    return <Progress type={"margin-right-0px"} current={Math.abs(rawValue)} min={0} max={absmax} color={rawValue < 0 ? 'danger' : 'success'} />
}

export const getDataDescriptionDialogDescription = (place: string) => (limit: number, direction: string, column: string, filterCount: number) => {
    //place parameter is e.g. "report" or "checklist"
    let filterDescription = `The ${place} contains rows where:`
    if (limit && limit > 0) {
        filterDescription = `The top ${place} contains the ${limit} rows with the ${direction === "asc" ? "lowest" : "highest"} ${column}`
    }
    if (limit && limit > 0 && filterCount > 0) filterDescription += ", and:"
    return filterDescription
}

export const getColumnsForProfit = (profit: any) => {
    const sections:ProfitabilitySection[] = JSON.parse(profit.sections)
    const total_row:ProfitabilitySectionTotal = JSON.parse(profit.total_row)
    let columns:kpi[] = []

    sections.forEach(s => {
        s.rows.forEach(r => {
            r.columns.forEach(c => columns.push({ aggregation: "sum", showPercentage: false, column: c }))
        })

        if (s.total_row?.use_columns) {
            s.total_row.columns.forEach(c => columns.push({ aggregation: "sum", showPercentage: false, column: c }))
        }
    })

    if (total_row.use_columns) {
        total_row.columns.forEach(c => columns.push({ aggregation: "sum", showPercentage: false, column: c }))
    }

    return columns
}

export const getShownColumns = (modelColumns: Column[], setup: any): Column[] => {
    return modelColumns.filter(c => !setup.hidden_columns.includes(c))
}

export const getReportUpdateData = (changes: any, reportdata: any) => {
    let data = {
        name: reportdata.report.name,
        bucket: reportdata.info.id,
        columns: JSON.parse(reportdata.report.columns),
        filter: JSON.parse(reportdata.report.filter),
        sort_column: reportdata.report.sort_column,
        sort_direction: reportdata.report.sort_direction,
        limit: reportdata.report.limit,
        kpis: reportdata.report.kpis,
        fixed_slicer: reportdata.report.fixed_slicer,
        view_settings: reportdata.report.view_settings,
        kpi_config: reportdata.report.kpi_config,
        user_id: reportdata.report.user_id,
        group_id: reportdata.report.group_id,
        is_public: reportdata.report.is_public,
        description: reportdata.report.description
    }
    return Object.assign(data, changes)
}
