import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { apiGet } from '../helpers/ApiHelpers'
import DashboardTable2 from '../components/Dashboard/DashboardTable2'

const DashboardTableSecret = () => {
    const { secret, dId} = useParams()
    const [data, setData] = useState([])
    const [hoverReportId, sethoverReportId] = useState([])
    const [hoverKpiKey, sethoverKpiKey] = useState([])

    let getWidthForPlaywright = () => {
       
        let calcLength =  100 + (JSON.parse(data.dashboard_data.kpis).length +1) * 160
        if(calcLength < 800) {
            return 800
        }
        else {
            return calcLength
        }
    };


    useEffect(() => {
        apiGet(`singledashboard/${dId}/${secret}`, data => {
            setData(data)
        }, error => console.log(error))
    }, [dId, secret])
    if (!secret || !dId) return null;
    if (data.length === 0) return null;

    const pdfDate = new Date().toLocaleString('da-DK').split(' ')[0].replaceAll('.', '/')

    return <div className="mt-2">
        <div style={{ display: "none" }} id={`playwrightWidth`}>{getWidthForPlaywright()}</div>
                
        <div style={{ opacity: "50%"}}>
            <div className='pt-2' style={{ float:"right", marginRight:"50px"}}>
                <img src="./img/inact_now_logo_black.svg" alt="Inact Now" style={{height: "25px"}} />
            </div>

            <div style={{ float:"left", marginLeft:"50px" }} >
                <div id="dashboardTitle" style={{ fontSize: 25 }}>{data.dashboard_data.title}</div>
                <div className=''style={{fontSize:"smaller"}}><span className=' text-muted'></span> {pdfDate}</div>
            </div>
            <div style={{ clear: "both"}}></div>
        </div>

            <div className='pb-2' style={{paddingLeft: 50, paddingRight:50 }}>
        {/*<DashboardTable dashboardId={dId}  dashboardData={data} 
                setHoverValues={(id, key) => {sethoverReportId(id); sethoverKpiKey(key)}}
                hoverReportId={hoverReportId}
                hoverKpiKey={hoverKpiKey}
                />*/}
                <DashboardTable2 
                    setHoverValues={(id, key) => {sethoverReportId(id); sethoverKpiKey(key)}}
                    hoverRow={hoverReportId}
                    hoverKpi={hoverKpiKey}
                    dashboard={data}
                    reports={data.reports}
                    />
            </div>

    </div>
}

export default DashboardTableSecret
