/* REACT */
import React from 'react'
import Overview from './ReportComponents/Overview'
import Gridview from './ReportComponents/Gridview'
import KpiView from './ReportComponents/KpiView'
import SumView from './ReportComponents/SumView'
import { packAction } from '../../actions/ActionTypes'
import * as ActionTypes from '../../actions/ActionTypes'
import { connect } from 'react-redux'
import KPIReportBanner from './ReportComponents/KPIReportBanner'
import { getFilter, getIdColumn, getNumberColumnsObj } from '../../helpers/ReportHelpers'
import { withSettingsPropagation } from '../../helpers/SettingsService'
import { is } from '../../helpers/PermissionHelpers'
import RequirePermission from '../RequirePermission'
import { putSetting } from '../../helpers/SingleSettingService'
import { hashNValues } from '../../helpers/GeneralHelpers'
import CreateChecklistButton from './ReportComponents/CreateChecklistButton'
import LoadingIcon from '../LoadingIcon'
import ReportNav from './ReportNav'
import Trend from './Trend'
import SaveDialog from '../Dialogs/SaveDialog'
import ProfitReportViewContainer from './ReportComponents/ProfitReportViewContainer'
import { setDiffViewSelectedItems } from '../../helpers/DiffViewManager'

const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.reportInfo,
        users: state.System.users,
        userInfo: state.User.info,
        altReportInfo: state.Info.altReportInfo,
        favourites: state.Report.favourites,
        toggleFav: state.Report.toggleFav,
        aggregationdata: state.Report.aggregationdata,
        loadingGridData: state.Loading.loadingGridData,
        profits: state.Bucket.profits,
    }
}

class ReportContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedItems: [],
            tmpChecklistLimit: -1,
            tmpChecklistSortDirection: "desc",
            tmpChecklistSortColumn: "",
            tmpChecklistName: "",
            tmpChecklistHasLimit: false
        }
        this.grid = React.createRef()
        this.trend = React.createRef()
        this.topChecklistDialog = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.section !== prevProps.section) {
            this.setState({ selectedItems: [] })
        }
    }

    getSelectedItems = () => {
        return this.state.selectedItems
    }

    setSelectedItems = (ids) => {
        this.setState({selectedItems: ids})
        setDiffViewSelectedItems(ids)
    }

    deselectItems = (ids) => {
        this.grid.current.deselectMultiple(ids)
        this.trend.current.deselectMultiple(ids)
    }

    legacyColumnCheck(columns) {
        if (columns.some(c => c.column)) {
            return columns.reduce((arr, col) => {
                if (col.shown) {
                    arr.push(col.column)
                }
                return arr
            }, [])
        } else {
            return columns
        }
    }

    getKPIKey() {
        let reportdata = this.props.reportdata
        let limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit
        let filter = getFilter(reportdata.report, this.props.slicers)
        return hashNValues(limit, filter)
    }

    promptCreateChecklist = () => {
        const reportdata = this.props.reportdata
            let newState = {
                tmpChecklistLimit: 50,
                tmpChecklistName: ``,
                tmpChecklistSortColumn: "",
                tmpChecklistSortDirection: "desc"
            }
            if (reportdata.report.limit && reportdata.report.limit !== -1) {
                newState = {
                    tmpChecklistLimit: reportdata.report.limit,
                    tmpChecklistName: ``,
                    tmpChecklistSortColumn: reportdata.report.sort_column,
                    tmpChecklistSortDirection: reportdata.report.sort_direction ?? "desc"
                }
            } else if(reportdata.report.sort_direction) {
                newState.tmpChecklistSortDirection = reportdata.report.sort_direction
            }
            this.setState(newState, () => {
                this.topChecklistDialog.current.show()
            })
    }

    saveTopChecklist = () => {
        let limit = this.state.tmpChecklistHasLimit ? this.state.tmpChecklistLimit : -1
        let sortColumn = this.state.tmpChecklistSortColumn
        if (!this.state.tmpChecklistSortColumn){
            let numberColumns = getNumberColumnsObj(this.props.reportdata.info.model).map(c => c.name)
            if (numberColumns.length < 1) return
            sortColumn = numberColumns[0]
        }
        this.createChecklist(this.state.tmpChecklistName, limit, sortColumn, this.state.tmpChecklistSortDirection)
        this.setState({
            tmpChecklistLimit: -1,
            tmpChecklistSortDirection: "desc",
            tmpChecklistSortColumn: "",
            tmpChecklistName: "",
            tmpChecklistHasLimit: false
        })
    }

    createChecklist = (name, limit, sortColumn, sortDirection) => {
        let reportdata = this.props.reportdata
        let shownColumns = this.props.shownGridColumns.map(c => c.name)
        let payload = {
            bId: reportdata.info.id,
            data: {
                query: {
                    name: name,
                    query: {
                        sort_column: sortColumn,
                        sort_direction: sortDirection,
                        filter: this.state.selectedItems.length >= 1 && this.state.selectedItems.length <= 200 ? [{ compare_type: 1, target_values: this.state.selectedItems, column: getIdColumn(this.props.reportdata.info.model.columns) }] : getFilter(reportdata.report, this.props.slicers),
                        limit: (this.props.limit || this.props.limit === 0) && !(this.props.limit === -1 || (limit > 0 && limit < this.props.limit)) ? this.props.limit : limit,
                        offset: -1,
                        column_filter: JSON.parse(reportdata.report.columns)
                    },
                },
                shown_columns: JSON.stringify(shownColumns),
                sort_column: sortColumn ? sortColumn : this.grid.current.getSortColumn(reportdata.report, false),
                sort_direction: sortColumn ? sortDirection : (this.grid.current.getDirection(false) ? 'desc' : 'asc'),
                limit: limit,
                click_report_category: this.props.clickedCategory
            }
        }
        this.props.dispatch(packAction(ActionTypes.CREATE_CHECKLIST_FROM_REPORT, payload))
        putSetting("fromReport", this.props.reportdata.report.report_id)
    }

    customAddSlicer(column, category, limit) {
        if (typeof limit === "string") { // this seems wrong - does it only work for "danish"?
            if (limit.indexOf('.') > -1) {
                limit = limit.replace('.', '')
            }
        }

        let convLimit = parseInt(limit, 10)
        let res = {
            column: column,
            compare_type: 1,
            target_values: [category]
        }
        if (category === 'Total') {
            this.props.addClickSlicer(category, true, convLimit)
        } else {
            this.props.addClickSlicer(res, true, convLimit)
        }
    }

    doAddClickSlicer(column, value, limit) {
        if (typeof limit === "string") { // this seems wrong - does it only work for "danish"?
            if (limit.indexOf('.') > -1) {
                limit = limit.replace('.', '')
            }
        }
        this.props.addClickSlicer({
            column: column,
            compare_type: 1,
            target_values: [value]
        }, true, parseInt(limit, 10))
    }

    customRemoveSlicer() {
        this.props.removeClickSlicer()
    }

    showTotal() {
        this.props.changeView('grid')
    }

    getViewName(secname) {
        const actualNames = ["Matrix", "Grid", "KPI", "Sum", "Trend"]
        const views = ["overview", "grid", "kpis", "sum", "trend"]
        return actualNames[views.indexOf(secname)]
    }

    hideSelectedRows = () => {
        this.props.hideMultipleRows(this.state.selectedItems.slice())
    }

    setHasLimit = (value) => {
        this.setState({tmpChecklistHasLimit: value})
    }

    kpiClicked = (kpi, index) => {
        //forward to trendview
        this.trend.current.kpiClicked(kpi, index)
    }

    getTrendSettings = () => {
        if(this.trend && this.trend.current)
            return this.trend.current.getTrendSettings();
        else
            return null
    }

    render() {
        let section = this.props.section
        let kpis = JSON.parse(this.props.reportdata.report.kpis)
        let kpiItems = this.props.reportdata.report.kpi_config && this.props.reportdata.report.kpi_config !== 'no config' ? JSON.parse(this.props.reportdata.report.kpi_config) : [{ shown: false }, { shown: false }, { shown: false }]
        const profit = this.props.profits.get(this.props.reportdata.info.id, null)

        let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
        //Assume that all views are accessible, if it's not defined in view settings
        let showView = Object.assign({ trend: true, grid: true, kpis: true, sum: true, profit: true, overview: true }, viewSettings.settings)
        let idColumn = getIdColumn(this.props.reportdata.info.model.columns)
        let idAggr = this.props.aggregationdata.get("idColumn", this.props.reportdata.report.report_id, idColumn)
        let numberColumns = getNumberColumnsObj(this.props.reportdata.info.model).map(c => c.name)

        let getEnabled = (view) => {
            if (view === "profit" && (profit === null || !profit?.enabled)) {
                return false
            }
            if (showView && !showView[view]) {
                return false
            }
            if (view === 'grid' && this.props.clickReport){
                return true
            }
            return  !this.props.clickReport
        }



        return (
            <div>
                <div className="actual-top-row">
                    <ReportNav
                        clickReport={this.props.clickReport}
                        clickedCategory={this.props.clickedCategory}
                        clickedColumn={this.props.clickedColumn}
                        section={this.props.section}
                        reportdata={this.props.reportdata}
                        changeView={this.props.changeView}
                        resetGrid={() => this.grid.current.reset()}
                        profit={profit}
                        viewSettings={showView}
                        editMode={this.props.editMode}
                        setData={this.props.setData}
                        getData={this.props.getData}
                        saveButtonDisabled={this.props.saveButtonDisabled}
                        saveSetup={this.props.saveSetup}

                    />
                    <div className="inline-block margin-left-5px margin-top-2px">
                        <div>
                            <LoadingIcon loading={this.props.loadingGridData} size={'small'} />
                        </div>
                    </div>
                    <RequirePermission perms={is.checklistsCreator}>
                        <CreateChecklistButton
                            createChecklist={() => this.promptCreateChecklist()}
                            amount={this.state.selectedItems.length}
                            aggrData={idAggr}
                            columns={this.props.info.info.model.columns}
                            id={this.props.reportdata.report ? this.props.reportdata.report.report_id : -1}
                        />
                    </RequirePermission>
                </div>
                {
                    kpis.length > 0 || this.props.editMode ?
                        <KPIReportBanner
                            reportdata={this.props.reportdata}
                            limit={this.props.limit}
                            slicers={this.props.slicers}
                            setData={this.props.setData}
                            editMode={this.props.editMode}
                            clickable={section === 'trend'}
                            kpiClicked={(kpi, index) => this.kpiClicked(kpi, index)}
                            getTrendSettings={() => this.getTrendSettings()}
                        />
                        : <div className="mt-1" />
                }
                <Overview
                    show={section === 'overview' && showView.overview}
                    enabled={getEnabled('overview')}
                    settingsParent={this}
                    reportdata={this.props.reportdata}
                    addClickSlicer={(column, category, limit) => this.customAddSlicer(column, category, limit)}
                    slicers={this.props.slicers}
                    clickReport={this.props.clickReport}
                    showTotal={() => this.showTotal()}
                    limit={this.props.limit}
                    setData={this.props.setData}
                    editMode={this.props.editMode}
                    clickableCategories={this.props.clickableCategories}
                />
                <Gridview
                    show={section === 'grid' && (showView.grid || this.props.clickReport)}
                    enabled={getEnabled('grid')}
                    settingsParent={this}
                    ref={this.grid}
                    reportdata={this.props.reportdata}
                    slicers={this.props.slicers}
                    clickReport={this.props.clickReport}
                    removeClickSlicer={() => this.customRemoveSlicer()}
                    limit={this.props.limit}
                    hideRow={(bId, id) => this.props.hideRow(bId, id)}
                    hideSelectedRows={this.hideSelectedRows}
                    editMode={this.props.editMode}
                    shownColumns={this.props.shownGridColumns}
                    setShownColumns={this.props.setShownGridColumns}
                    setSelectedItems={this.setSelectedItems}
                    selectedItemsLength={this.state.selectedItems.length}
                    resetSelectedItems={() => this.setSelectedItems([])}
                    getCommentContextPath={this.props.getCommentContextPath}
                    setData={this.props.setData}
                    makeReportPublic={this.props.makeReportPublic}
                    reportID={this.props.reportID}
                    columnDescriptions={this.props.columnDescriptions}
                />
                <KpiView
                    show={section === 'kpis' && showView.kpis}
                    enabled={getEnabled('kpis')}
                    settingsParent={this}
                    settings={this.props.settings ? this.props.settings.kpi : null}
                    onSettingUpdate={(settings) => this.onSettingUpdate({ kpi: settings })}
                    reportdata={this.props.reportdata}
                    slicers={this.props.slicers}
                    editMode={this.props.editMode}
                    limit={this.props.limit}
                    setData={this.props.setData}
                    kpiItems={kpiItems}
                    addClickSlicer={(column, category, limit) => this.customAddSlicer(column, category, limit)}
                    changeView={this.props.changeView}
                    clickableCategories={this.props.clickableCategories}
                />
                <SumView
                    show={section === 'sum' && showView.sum}
                    enabled={getEnabled('sum')}
                    settingsParent={this}
                    reportdata={this.props.reportdata}
                    slicers={this.props.slicers}
                    editMode={this.props.editMode}
                    limit={this.props.limit}
                    setData={this.props.setData}
                    changeView={this.props.changeView}
                    fixedSlicerColumns={this.props.fixedSlicerColumns}
                    clickReport={(column, value, limit) => this.doAddClickSlicer(column, value, limit)}
                    columnDescriptions={this.props.columnDescriptions}
                />

                <ProfitReportViewContainer 
                    show={section === "profit" && showView.profit}
                    enabled={getEnabled('profit')}
                    settingsParent={this}
                    editMode={this.props.editMode}
                    limit={this.props.limit}
                    profit={profit}
                    reportdata={this.props.reportdata}
                    slicers={this.props.slicers}
                    changeView={this.props.changeView}
                />

                <Trend
                    show={(section === "trend" || section === "diff") && showView.trend}
                    enabled={getEnabled('trend')}
                    wrappedComponentRef={this.trend}
                    settingsParent={this}
                    section={this.props.section}
                    reportdata={this.props.reportdata}
                    slicers={this.props.slicers}
                    clickReport={this.props.clickReport}
                    addSlicer={this.props.addSlicer}
                    changeView={this.props.changeView}
                    removeSlicer={this.props.removeSlicer}
                    editMode={this.props.editMode}
                    setData={this.props.setData}
                    hideRow={this.props.hideRow}
                    clickableCategories={this.props.clickableCategories}
                    setSelectedItems={this.setSelectedItems}
                    selectedItemsLength={this.state.selectedItems.length}
                    forPrint={false}
                    promptCreateChecklist={this.promptCreateChecklist}
                    disableSave={this.props.disableSave}
                    getDisabledText={this.props.getDisabledText}
                    promptExitEditMode={this.props.promptExitEditMode}
                    enterEditMode={this.props.enterEditMode}
                />

                <SaveDialog ref={this.topChecklistDialog} title="Create Action list" saveHandler={this.saveTopChecklist} closeHandler={this.cancelTopChecklist} disableSave={(!this.state.tmpChecklistSortColumn && this.state.tmpChecklistHasLimit) || this.state.tmpChecklistName === ""} focus="watwat">
                    <div>Name:</div>
                    <input id="watwat" className="form-control form-control-sm" placeholder="Name" value={this.state.tmpChecklistName} onChange={(e) => { this.setState({ tmpChecklistName: e.target.value }) }}  />
                    <br/>
                    <div>Limit:</div>
                    <input type="checkbox" value={this.state.tmpChecklistHasLimit} checked={this.state.tmpChecklistHasLimit} onChange={(e) => this.setHasLimit(!this.state.tmpChecklistHasLimit)} />
                    <div style={{display: "inline-block"}} className={this.state.tmpChecklistHasLimit ? "" : "half-opacity"}>
                    <select className="form-control margin-left-5px width-100px form-control-sm inline-block" value={this.state.tmpChecklistSortDirection} onChange={(e) => this.setState({ tmpChecklistSortDirection: e.target.value })} disabled={!this.state.tmpChecklistHasLimit} >
                        <option value={"desc"}>Top</option>
                        <option value={"asc"}>Bottom</option>
                    </select>
                    <select className="form-control width-100px form-control-sm margin-left-5px inline-block" value={this.state.tmpChecklistLimit} onChange={(e) => this.setState({ tmpChecklistLimit: parseInt(e.target.value, 10) })} disabled={!this.state.tmpChecklistHasLimit}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select>
                    <select className={`form-control form-control-sm inline-block margin-left-5px`} value={this.state.tmpChecklistSortColumn} onChange={(e) => { this.setState({ tmpChecklistSortColumn: e.target.value }) }} style={{width: 235}} disabled={!this.state.tmpChecklistHasLimit}>
                        <option value={""} style={{display: "none"}}>Select column</option>
                        {
                            numberColumns.map((c, index) => {
                                return <option value={c} key={index}>{c}</option>
                            })
                        }
                    </select>
                    </div>
                </SaveDialog>
            </div>
        )
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return false
}

const getSettingsKeys = (state) => {
    return []
}

ReportContent = withSettingsPropagation(ReportContent, getSettingsKeys, shouldComponentUpdateSettings, null, null, "content")

ReportContent = connect(mapStateToProps, null, null, { forwardRef : true })(ReportContent)

export default ReportContent
