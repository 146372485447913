import React, { useEffect, useState } from 'react'
import ShowIf from '../Generic/ShowIf';
import { connect } from "react-redux";
// @ts-ignore
import Select from '../Generic/CustomSelect'


import '../../css/genericModal.css'
import '../../css/FixReportDialog.css'
import DividerText from '../Generic/DividerText';
import { ColumnPointer, LibraryReport, ReportManualFix } from '../../types/transfertypes';
import { packAction, REPORT_MANUAL_FIX } from '../../actions/ActionTypes';

interface FixReportDialogProps {
    data: LibraryReport,
    show: boolean,
    onClose: () => void,
    dispatch: (pack: any) => void,
}

let mapStateToProps = (state: any, ownProps: FixReportDialogProps) => {
    return {}
}

let FixReportDialog = (props: FixReportDialogProps) => {

    const [tmpSort, setTmpSort] = useState<ColumnPointer>({from: "", to: ""})
    const [tmpFilters, setTmpFilters] = useState<ColumnPointer[]>([])

    useEffect(() => {
        if (props.show && props.data.missing && props.data.critical) {
            setTmpSort({from: sortColumn != null ? sortColumn.column : '', to: ''})
            setTmpFilters(filterColumns.map((e: any) => {return {from: e.column, to: ''}}))
        }
    }, [props.show])

    if (!props.data.missing || !props.data.critical) {
        return <></>
    }

    let filterColumns = props.data.critical.filter((c: any) => c.type == 'filter')
    let sortColumn = props.data.critical.find((c: any) => c.type == 'sort')

    

    let cancelEdit = () => {
        props.onClose()
    }

    let checkDisableSave = () => {
        let shouldDisable = false
        if (tmpFilters.length > 0) {
            shouldDisable = shouldDisable || tmpFilters.some((e) => e.to == '')
        }
        if (sortColumn != null) {
            shouldDisable = shouldDisable || tmpSort.to == ''
        }
        return shouldDisable
    }

    let save = () => {
        let filters = tmpFilters
        let sort = tmpSort

        for (let i = 0; i < filters.length; i++){
            if (filters[i].to == "remove-column"){
                filters[i].to = ""
            }
        }

        if (sort.to == "remove-column") {
            sort.to = ""
        }

        let data: ReportManualFix = {
            report_id: props.data.report.report_id, 
            // @ts-ignore
            to_delete: props.data.missing,
            filters: filters,
            sort: sort
        }
        props.dispatch(packAction(REPORT_MANUAL_FIX, {data}))
        props.onClose()
    }

    let setFilter = (index: number, value: string) => {
        let filters = [...tmpFilters]
        filters[index].to = value
        setTmpFilters(filters)
    }
    

    let selectColumns = props.data.info.model.columns.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((e: any, i: number) => {
        return {value: e.name, label: e.name, style: () => {return {color: "#000000"}}}
    })
    selectColumns.unshift({
        value: "remove-column",
        label: "Remove",
        // @ts-ignore
        style: (dark: boolean) => {return {
            backgroundColor: dark ? "rgba(255, 86, 48, 0.2)" : "rgba(255, 86, 48, 0.1)",
            color: "rgb(255, 86, 48)",
        }}
    })

    
    return (
        <ShowIf if={props.show && props.data.missing && props.data.critical}>
            <div className="generic-modal-backdrop" onClick={cancelEdit}>
                <div className="generic-modal-popup" style={{width: 500}} onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header sticky-modal-header" >
                        <h4 className="modal-title">Fix report</h4>
                        <button type="button" className="close" ><span onClick={cancelEdit}>&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <ShowIf if={filterColumns.length > 0}>
                            <div>
                                <DividerText label='Filters'/>
                                {tmpFilters.map((fp, i) => {
                                    return (
                                    <div key={i} className="fix-select-row">
                                        <span className='vertical-align-middle'>{fp.from}</span>
                                        <div>
                                            <span >Replaced by</span>
                                            <Select isClearable 
                                            options={selectColumns}
                                            className="fix-select"
                                            onChange={(e: any) => {setFilter(i, e != null ? e.value : "")}}
                                            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),option: (styles: any, {data, isFocused, isSelected}: any) => {
                                                return {...styles, ...data.style(isFocused || isSelected)}
                                            },
                                            control: (styles:any) => ({ 
                                                ...styles,
                                                ':focus-within' : { 
                                                    borderColor: "var(--tangerine-hover)", 
                                                    boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                                                }
                                            })
                                            }}
                                            menuPortalTarget={document.body}
                                            />
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </ShowIf>
                        <ShowIf if={sortColumn != null}>
                            <div>
                                <DividerText label='Sort' />
                                <div className='fix-select-row'>
                                    <span className='vertical-align-middle'>{tmpSort.from}</span>
                                    <div>
                                        <span>Replaced by</span>
                                        <Select isClearable 
                                            className="fix-select"
                                            options={selectColumns}
                                            onChange={(e: any) => {setTmpSort({from: tmpSort.from, to: e != null ? e.value : ""})}}
                                            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),option: (styles: any, {data, isFocused, isSelected}: any) => {
                                                return {...styles, ...data.style(isFocused || isSelected)}
                                            },
                                            control: (styles:any) => ({ 
                                                ...styles,
                                                ':focus-within' : { 
                                                    borderColor: "var(--tangerine-hover)", 
                                                    boxShadow: `0 0 0 1px var(--tangerine-hover)`,  
                                                }
                                            })}}
                                            menuPortalTarget={document.body}
                                            />
                                    </div>
                                </div>
                            </div>
                        </ShowIf>
                        <hr className='hr-color'/>
                        <div>
                            <span>The following columns will be removed:</span>
                            <ul>
                                {props.data.missing.map((e: string, i: number) => {
                                    return <li key={i}>{e}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="modal-footer sticky-modal-footer">
                        <div>
                            <button onClick={cancelEdit} type="button" className="btn btn-default margin-right-10px" >Cancel</button>
                            <button onClick={() => save()} type="button" className="btn btn-primary margin-right-10px" disabled={checkDisableSave()}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </ShowIf>
    )
}


// @ts-ignore
FixReportDialog = connect(mapStateToProps)(FixReportDialog)
export default FixReportDialog
