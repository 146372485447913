import React, { useEffect, useState } from 'react'
import { Group, LibraryReport, User, FullSlicer } from '../../types/transfertypes'
import { connect } from 'react-redux'
// @ts-ignore
import { Link } from 'react-router-dom'

import '../../css/LibraryItem.css'
import { getFullName } from '../../helpers/UserHelpers'
import ResponsiveToggle from '../Generic/ResponsiveToggle'
import HomeIcon from '../icons/HomeIcon'
import { removeColumns, toggleFavourite } from '../../actions/ReportActions'
import ReactTooltip from 'react-tooltip'
import ReportDescription from '../ReportDescription'
import { getDataDescriptionDialogDescription } from '../../helpers/ReportHelpers'
import ShowIf from '../Generic/ShowIf'
import { LibraryDropdownFunctions } from '../../views/Library2'
import { isReportAdmin } from '../../helpers/PermissionHelpers'
import { fromNow } from '../../helpers/TimeHelpers'

interface LibraryItemProps {
    report: LibraryReport,
    manualFixReport: (report: LibraryReport) => void,
    users?: User[],
    user?: User,
    className?: string,
    style?: React.CSSProperties,
    disableHome?: boolean,
    dropFuncs: LibraryDropdownFunctions,
    group: Group,
    dispatch: any,
    toggleSelect: (report: LibraryReport) => void,
    selectedReports: LibraryReport[]
}

let mapStateToProps = (state: any, ownProps: LibraryItemProps) => {
    return {
        users: state.System.users,
        // reportsInDashboards: state.Report.reportsInDashboards,
        user: state.User.info
    }
}

let LibraryItem = (props: LibraryItemProps) => {
    const [isFavourite, setIsFavourite] = useState(false)

    useEffect(() => {
        setIsFavourite(props.report.isFav)

    }, [props.report])

    let toggleFav = () => {
        let { dispatch } = props
        let reportdata = props.report

        let data = {
            favourited: !isFavourite,
            item_type: 'report',
            item_id: reportdata.report.report_id
        }
        dispatch(toggleFavourite(data))
        setIsFavourite(!isFavourite)
    }

    if (props.users == null || props.report == null) {
        return (<div></div>)
    }

    let getIcon = () => {
        if (props.report.report.is_public) {
            return <img className='library-item-icon' src='img/report.svg' alt="Report" />
        }
        return <img className='library-item-icon' src='img/report-private.svg' alt="Private report"/>
    }

    let getExtraClass = () => {
        if (props.report.critical != null || props.report.missing != null) return 'library-item-error'
        if (isFavourite) return 'library-item-favourite'
    }

    let getReportDescription = () => {
        let reportFilters: FullSlicer[] = JSON.parse(props.report.report.filter)
        let columns: string[] = JSON.parse(props.report.report.columns)
        return (
            <div>
                <ReportDescription description={props.report.report.description} filterDescription={getDataDescriptionDialogDescription("Insight")(props.report.report.limit, props.report.report.sort_direction, props.report.report.sort_column, reportFilters.length)} filter={reportFilters} showColumnDescription={false} title={"About this Insight"} columns={columns} columnDescription={props.report.info && props.report.info.setup ? props.report.info.setup.descriptions : []} />
                {props.report.inDashboard ? <b>** This Insight is used in a dashboard **</b>: null}
            </div>
        )
    }

    let fixReport = () => {
        const critical = props.report?.critical != null
        if (!critical) {
            const { dispatch, report } = props
            const loc = "library"
            dispatch(removeColumns(report?.report.report_id, { view: loc }))
        } else {
            props.manualFixReport(props.report)
        }
    }

    if (props.user == null) {
        return <div></div>
    }

    let error =  props.report.missing != null || props.report.critical != null
    let critical = props.report.critical != null

    let disableHome = props.disableHome ?? false
    let isAdmin = isReportAdmin(props.user.access)

    let isChecked = props.selectedReports.some(v => v.report.report_id == props.report.report.report_id)

    let olderThan24Hours = (((new Date()).getTime() / 1000) - props.report.info.last_update) > 86_400
    return (
        <div style={props.style ?? {}} className={`library-item-container ${props.className ?? ''}`}>
            <div className={`library-item-rect ${getExtraClass()}`} />
            <div className={`library-item-content ${getExtraClass()}`} >
                <div className='library-item-content-left'>
                    <ShowIf if={isAdmin}>
                        <input className="" type="checkbox" checked={isChecked} onChange={() => props.toggleSelect(props.report)} />
                    </ShowIf>
                    {getIcon()}
                    <div className='library-item-title abc-click' onClick={(e) => {
                        if (error) {
                            e.preventDefault()
                            e.stopPropagation()
                            fixReport()
                        }
                    }}>
                        <Link style={error ? {pointerEvents: "none"} : {}} to={`/report/${props.report.report.report_id}`}>
                            <span className='library-item-title-top abc-click' title={props.report.report.name}>{props.report.report.name.length > 45 ? props.report.report.name.slice(0,42) + '...' : props.report.report.name}</span> <i data-tip data-for={`tooltip-${props.report.report.report_id}`} style={{display:"inline-block"}} className='fa fa-info-circle glyphicon-info-gray'></i>
                        </Link>
                        <ReactTooltip id={`tooltip-${props.report.report.report_id}`} effect='solid' place='bottom'>
                            <p style={{textDecoration: `underline`}} className={`margin-top-0 ${olderThan24Hours ? 'library-item-red' : ''}`}>Data updated: <span title="">{fromNow(props.report.info.last_update)}</span></p>
                            {getReportDescription()}
                        </ReactTooltip>
                        <span className='library-item-title-bottom'>{getFullName(props.users, props.report.report.user_id)}</span>
                    </div>
                    {error ? <div>
                        <i onClick={fixReport} style={{color: "#D53939", marginLeft: 10, fontSize: 20}} className="fa fa-exclamation-triangle abc-click" aria-hidden="true" data-tip data-for={`tooltip-${props.report.report.report_id}-error`}></i>
                        <ReactTooltip id={`tooltip-${props.report.report.report_id}-error`} effect='solid' place='bottom'>
                            {critical ? <span>This Insight contains columns that are no longer available. <br/> Click to fix the Insight.</span> : <span>This Insight contains columns that are no longer available. <br/> Click to remove these columns from the Insight</span>}
                        </ReactTooltip>
                    </div> : null}
                </div>
                <div className='library-item-content-right'>
                    <ShowIf if={!disableHome}>
                        <ResponsiveToggle style={{marginRight: 5}} onChange={() => {toggleFav()}} checked={isFavourite} label='Add'>
                                <HomeIcon width={10} height={10} add={!isFavourite}/>
                        </ResponsiveToggle>
                    </ShowIf>
                    
                    <ShowIf if={isAdmin}>
                        <div className="btn-group width-100-p margin-left-5px" role="group" aria-label="...">
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li className='abc-click'><span onClick={() => props.dropFuncs.onEdit(props.report)}>Edit Insight</span></li>
                                    <ShowIf if={props.report.report.is_public}>
                                        <li className='abc-click'><span onClick={() => props.dropFuncs.onMoveTo(props.report, props.group)}>Move to group</span></li>
                                    </ShowIf>
                                    <li className='abc-click'><span onClick={() => props.dropFuncs.onChangeOwner(props.report)}>Change owner</span></li>
                                    <li className='abc-click'><span onClick={() => props.dropFuncs.onTogglePrivate(props.report)}>
                                        {props.report.report.is_public ? "Make private" : "Make public"}</span>
                                    </li>
                                    <li className='abc-click'><span onClick={() => props.dropFuncs.onCopy(props.report)}>Copy Insight</span></li>
                                    <li className='abc-click'><span onClick={() => props.dropFuncs.onDelete(props.report)}>Delete</span></li>
                                </ul>
                            </div>
                        </div>
                    </ShowIf>
                </div>
            </div>
        </div>
    )
}

// @ts-ignore
LibraryItem = connect(mapStateToProps)(LibraryItem)

export default LibraryItem
