import { Permissions } from "../helpers/PermissionHelpers"
import { CompareType } from "./slicertypes"

export interface User {
    access: Permissions[]
    deleted: boolean
    email: string
    firstname: string
    id: number
    is_consultant: boolean
    lastname: string
    organization_id: number
    user_id: number
}

export interface LoginSession {
    access: Permissions[]
    user_id: number
    session_key: string
    remote_address: string
    firstname: string
    lastname: string
    email: string
    organization: number
    organization_name: string
    login_time: number
    last_request: number
    is_admin_login: boolean
    is_consultant_login: boolean
}

export interface AggregationDataContainer {
    aggregation: AggregationData
    found: boolean
    label: string
}

export interface AggregationData {
    segments: AggregationSegmentMap
    total?: AggregationSegment
}

export interface AggregationSegmentMap {
    [key:string]: AggregationSegment;
}

export interface AggregationSegment {
    avg: number
    count: number
    item_count: number
    max: number
    min: number
    sum: number
}

export enum BucketColumnType {
    Text = "text",
    ID = "id",
    Decimal = "decimal",
    Date = "date",
    Categorization = "categorization",
}

export interface Column {
    name: string
    type: BucketColumnType
}

export interface Slicer {
    column: string
    compare_type: number
    target_values: (number|string)[]
    id?: any
}

export interface Category {
    color: Color
    name: string
    position: Position
}

export interface Color {
    r: number
    g: number
    b: number
}

export interface Position {
    x: number
    y: number
}

export interface KPI {
    aggregation: string
    column: string
    showKPIDecimals?: boolean
    data?: AggregationData|null
}

export interface Model {
    categorization: Category[]
    categorization_name: string
    columns: Column[]
    formula_columns: FormulaColumn[]
}

export interface FormulaColumn {
    formula: string
    name: string
    per_category: boolean
    target_column: string
    value_formulas: any //TODO: use correct type
}

export interface KPIConfig {
    aggregation: string
    column: string
    showDecimals: boolean
    shown: boolean
}

export interface ReportData {
    count: number
    report: Report
    info: BucketInfo
}

export interface DashboardReportShortInfo {
    report_id: number,
    name: string,
    is_public: boolean,
    user_id: number,
    filter: string,
    bucket: string,
}

export interface BucketInfo {
    has_data: boolean
    id: string
    last_update: number
    model: Model
    name: string
    setup: any /* TODO: use correct type */
}

export interface DataBucket {
    id: string,
    info: BucketInfo,
}

export interface DiffDataValue {
    end_value: number
    start_value: number
    status: number
    // description: string
    // diff: number
    // item_id: string
    // secondary_description: string
}

export interface ColumnHistory {
    column: string
    data: ColumnHistoryMonth[]
    item_id: string
}

export interface ColumnHistoryMonth {
    label: string
    value: number
    found: boolean
}

export interface ProfitabilityHeading {
    name: string
    type: string
}

export interface ProfitabilitySection {
    name: string
    rows: ProfitabilitySectionRow[],
    total_row: ProfitabilitySectionTotal
}

export interface ProfitabilitySectionRow {
    columns: string[]
    label: string
    subtract: boolean
}

export interface ProfitabilitySectionTotal {
    columns: string[]
    label: string
    use_columns: boolean
}

export interface ErrorReport {
    error_type: number
    url: string
    comment: string
    time: number
    message: string
    http_method: string
    http_code: number
    endpoint: string
    data: string
    error_name: string
    report_settings: string
    stack_trace: string
}

export interface DashboardFull {
    dashboard_data: DashboardData,
    table_data: DashboardTableData,
    widget_data: DashboardWidgetDataFull[],
}

export interface DashboardData {
    description: string,
    hide_table: boolean,
    id: number,
    kpi_targets: string,
    kpis: string,
    main_report: string,
    multiple_targets: string,
    org_id: number,
    reports: string,
    restricted: boolean,
    simple_targets: string,
    title: string,
    user_whitelist: string,
    widgets: string,
    rows: string,
    main_row: string,
    version: number,
}

export interface ParsedDashboardData {
    description: string,
    hide_table: boolean,
    id: number,
    kpi_targets: string,
    kpis: DashboardKPI[],
    main_report: string,
    multiple_targets: string,
    org_id: number,
    reports: string,
    restricted: boolean,
    simple_targets: string,
    title: string,
    user_whitelist: string,
    widgets: DashboardWidgetData[],
    rows: DashboardRow[],
    main_row: DashboardRow,
    version: number,
}

export interface DashboardWidgetData {
    type: string,
    widgets: DashboardWidget[],
}

export interface DashboardWidgetDataFull {
    type: "large" | "small",
    widgets: DashboardWidgetFull[]
}

export interface DashboardWidgetFull {
    aggregation: string,
    column: string,
    data_points: number[],
    decimal: false,
    difference: number,
    difference_percent: number,
    first_data_time: number,
    first_value: number,
    found_target: boolean,
    kpi_key: string,
    label: string,
    last_data_time: number,
    last_value: number,
    percent: boolean,
    percent_value: number,
    report_id: number,
    report_name: string,
    shown: boolean,
    target: number,
    target_trend: number,
    trend: number,
    row_id: string,
    is_report: boolean
}

export interface DashboardWidget {
    report_id?: number,
    kpi_key: string,
    shown: boolean,
    label: string,
    trend: number, 
    row_id: string,
    id: string,
}

export interface DashboardTargetNew {
	// If the target is enabled
    enabled: boolean,

	// If the target is a KPI
    is_kpi_target: boolean,

	// Used if is_kpi_target is false
    value: number,
    
	// Used if IsKpiTarget is true
    kpi_key: string,
    row_id: string,

	// Can be -1, 0 or 1
	// -1: Target is not met if value is above target
	//  0: Neutral
	//  1: Target is not met if value is below target
    trend: number,
    
    has_deadline: boolean,
    deadline: string,
}

export interface DashboardSimpleTarget {
	kpi_key: string
	trend: number
	target: DashboardTarget
}

export interface DashboardMultipleTarget {
	kpi_key: string
	trend: number
	targets: DashboardTarget[]
}

export interface DashboardKPITarget {
    kpi: string
    report: number
    target_kpi: string
    target_kpi_report: number
}

export interface DashboardTarget {
	deadline: string
	show_in_table: boolean
	values: {[key:string]:number}
	label: string
}

export interface DashboardReport {
	report_id: number
	label: string
	name: string
	is_public: boolean
	user_id: number
	hidden?: boolean
}

export interface DashboardKPI {
	kpi_key: string
	column: string
	aggregation: string
	category_filter: string[]
	decimal: boolean
	percent: boolean
	label: string
}

export interface DashboardKPIChange {
	first_data_time: number
	last_data_time: number
	first: number
	last: number
	difference: number
	difference_percent: number
	column: string
	aggregation: string
	decimal: boolean
	has_error: boolean
}

export interface DashboardReportRow {
    report_id: number,
    label: string,
    hidden: boolean,
}


export type DashboardRow = {
    // Is dependent on type
    row: DashboardQuery | DashboardReportRow,
    type: RowType,
    row_id: string,
    hidden: boolean,
    targets: {[key:string]: DashboardTargetNew[] | undefined}
}

export enum RowType {
    Report = 0,
    Filter = 1
}

export type DashboardTableData = {[kpiKey:string]: {[rowId: string]: DashboardKPIChange | undefined} | undefined}
export type DashboardKPISimpleTargets = {[key:string]: DashboardSimpleTarget}
export type DashboardKPIMultipleTargets = {[key:string]: DashboardMultipleTarget}
export type DashboardKPITargets = {[key:string] : DashboardKPITarget}

export interface DashboardOverview {
    description: string,
    id: number,
    organization_id: number,
    title: string,
}

export interface DashboardQuery {
    label: string,
    filter: QueryCriterion[],
    id: string,
}

export interface DataQuery {
    sort_column: string,
    sort_direction: string,
    limit: number,
    offset: number,
    column_filter: string[],
    filter: QueryCriterion[]
}

export interface QueryCriterion {
    column: string,
    compare_type: CompareType,
    target_values: any[],
    is_column: boolean,
}

export interface Checklist {
	id: number
	name: string
	description: string
	user_id: number
	data_columns: string
	upload_time: number
	touch_time: number
	archived: boolean
	assignee: number
	deadline: string
	has_deadline: boolean
	completed: boolean
	shown_columns: string
	layout: string
	bucket: string
	filter: string
	sort_column: string
	sort_direction: string
	limit: number
	click_report_category: string
    automatic_update: boolean
}

export interface ChecklistStatus {
    id: number
    count: {[key:string]:number}
    list: Checklist
}

export interface ChecklistColumn {
    Name: string,
    ColumnType: number
}

export interface ReportManualFix {
    report_id: number,
    to_delete: string[],
    filters: ColumnPointer[],
    sort: ColumnPointer

}

export interface ColumnPointer {
    from: string,
    to: string
}

export interface Report {
    bucket: string,
    columns: string,
    description: string,
    filter: string,
    fixed_slicer: string,
    group_id: number,
    is_public: boolean,
    kpi_config: string,
    kpis: string,
    limit: number,
    name: string,
    organization_id: number,
    report_id: number,
    sort_column: string,
    sort_direction: string,
    user_id: number,
    view_settings: string,
}
export interface LibraryReport {
    count: number,
    isFav: boolean,
    info: BucketInfo,
    report: Report,
    inDashboard: boolean,
    missing?: string[],
    critical?: Critical[]
}

interface Critical {
    column: string,
    type: string
}

export interface LibraryGroup {
    reports: LibraryReport[] | null,
    group: Group
}

export interface Group {
    group_id: number,
    is_unsorted: boolean,
    name: string,
    organization_id: number,
    position: number
}

export interface Favourite {
    id: number,
    item_id: number,
    item_type: string,
    user_id: number
}

export interface Filter {
    column: string,
    compare_type: number,
    target_values: any[]
}

export type FullSlicer = QueryCriterion & {isFixed?: boolean, fromClickReport?: boolean, disabled?: boolean, rowId?: string, kpi_key?:string}

