/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getReportGridData, downloadReportGridData } from '../../../actions/ReportActions'
import { getFilter, findNumberColumns, getIdColumn, shouldReportInfoChangeUpdateData, sortDataRows } from '../../../helpers/ReportHelpers'
import { getItemsComments } from '../../../actions/ItemActions'
import DataGrid from '../../DataGrid'
import ColumnSelectionDialog from '../../Dialogs/ColumnSelectionDialog'
import { copyToClipboard } from '../../../helpers/GeneralHelpers'
import { notifySuccess } from '../../../helpers/NotificationManager'
import { withSettingsPropagation } from '../../../helpers/SettingsService'
import ShowIf from '../../Generic/ShowIf'
import SaveDialog from '../../Dialogs/SaveDialog'
import InfiniteScroll from "react-infinite-scroll-component";

const mapStateToProps = (state, ownProps) => {
    return {
        griddata: state.Report.griddata,
        comments: state.Item.comments,
    }
}

class Gridview extends React.Component {
    constructor(props) {
        super(props)
        this.downloadDialogExcel = React.createRef()
        this.downloadDialog = React.createRef()
    }

    state = {
        direction: null, // asc = false, desc = true
        selectedGridColumn: null,
        selectedGridIndex: -1,
        shownColumnCount: 8,
        showDecimals: null,
        offset: 0,
        hasMoreData: true,
        currentData: null,
        limit: 100,
        shouldSort: true,
        initialLoad: true,
        autoFitArr: [],
        loadMoreWasClicked: false,
        wrapHeaderText: true,
    }

    dataGrid = React.createRef()

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { dispatch } = this.props
        let wasUpdated = false
        if (
            ((((this.state.direction !== prevState.direction ||
            this.state.selectedGridColumn !== prevState.selectedGridColumn ||
            this.state.offset !== prevState.offset || // if the load more button was pressed
            this.state.limit !== prevState.limit || // if limit (click report) was changed
            (this.props.clickReport && !prevProps.clickReport) || // if the user pressed a click report on Overview.js
            JSON.stringify(prevProps.slicers) !== JSON.stringify(this.props.slicers) || //if slicers was changed
            shouldReportInfoChangeUpdateData(prevProps.reportdata, this.props.reportdata)) &&//if report was updated  
            this.props.show) || (this.props.show && !prevProps.show))) || //and report must be shown or just changed to be not shown
            this.state.initialLoad //or in general if it's the first load of the page
        ) {
            this.setState({initialLoad: false})
            if (prevProps.reportdata !== this.props.reportdata) {
                wasUpdated = true // when the report is saved and reportdata is returning new data 'this.state.selectedGridColumn' still has the old value because it's set on local state.
                // therefore we get an error if we do not make sure to not return this old value - otherwise we use a sort_column that is not in the bucket
            }
            // let limit = this.props.limit !== -1 && this.props.limit <= 100 ? this.props.limit : this.props.reportdata.report.limit

            let limit = this.getViewLimit()

            const sortColumn = this.getSortColumn(this.props.reportdata.report, wasUpdated)
            const direction = this.getDirection(wasUpdated)
            let data = {
                sort_column: this.props.reportdata.report.limit > -1 ? this.props.reportdata.report.sort_column : sortColumn,
                sort_direction: this.props.reportdata.report.limit > -1 ? this.props.reportdata.report.sort_direction : (direction ? 'desc' : 'asc'),
                filter: getFilter(this.props.reportdata.report, this.props.slicers),
                column_filter: JSON.parse(this.props.reportdata.report.columns),
                //If the offset changes, then the "load more" button is clicked. 
                //If the "load more" button is clicked, we want to load data from the offset to the limit (the limit is relative to the offset).
                //If the "load more" button is not clicked (i.e. changing slicers, etc.), we want to load data from 0 to offset+limit
                offset: this.state.offset !== prevState.offset ? this.state.offset : 0, 
                limit: this.state.offset !== prevState.offset ? limit : limit + this.state.offset,
            }

            if(this.props.shownColumns.length > 0 && this.props.enabled) dispatch(getReportGridData(this.props.reportdata.info.id, data))

            if(this.state.offset !== prevState.offset) {
                this.setState({ loadMoreWasClicked: true })        
            }

            const columns = JSON.parse(this.props.reportdata.report.columns)
            if (this.state.selectedGridColumn === null || !columns.includes(this.state.selectedGridColumn) ) {
                const index = columns.indexOf(sortColumn)
                this.setState({ selectedGridColumn: sortColumn ? sortColumn : null, selectedGridIndex: index })        
            }
            if (this.state.direction === null) {
                this.setState({ direction: direction })
            }
            const viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if(viewSettings.actual && viewSettings.actual.grid) {
                if (viewSettings.actual.grid.autoFitArr && viewSettings.actual.grid.autoFitArr.length > 0 && this.state.autoFitArr.length === 0) {
                    this.setState({ autoFitArr: viewSettings.actual.grid.autoFitArr })
                }

                if(viewSettings.actual.grid.show_decimals !== undefined && this.state.showDecimals === null) {
                    this.setState({ showDecimals: viewSettings.actual.grid.show_decimals })
                }
            }

        }
        if (this.props.griddata.get("data", []) !== prevProps.griddata.get("data", [])) {
            let griddata = this.props.griddata.get("data", [])
            if (griddata.data) {
                let hasMoreData = this.state.loadMoreWasClicked ? griddata.data.length >= 100 : griddata.data.length >= this.state.offset+100
                if (this.state.currentData && this.state.currentData.data.length > 99 && this.state.offset > 99 && this.state.loadMoreWasClicked) {
                    let innerData = this.state.currentData.data
                    innerData = innerData.concat(griddata.data) 
                    griddata.data = innerData
                }

                let idIndex = this.getIdColumn().index
                let ids = []
                griddata.data.forEach((row, index) => {
                    ids.push(row[idIndex])
                })

                dispatch(getItemsComments(this.props.reportdata.info.id, { item_ids: ids }))

                this.setState({ currentData: griddata, hasMoreData: hasMoreData, shouldSort: true, loadMoreWasClicked: false })
            } else if (this.state.currentData && this.state.currentData.data.length > 0 && this.state.offset === 0) {
                this.setState({ currentData: { data: [] }, loadMoreWasClicked: false })
            }

            if (!griddata.data && this.state.hasMoreData) {
                this.setState({ hasMoreData: false, loadMoreWasClicked: false })
            }
        }

        if(this.state.shouldSort) {
            this.setState({shouldSort: false})
        }

        // resetting currentData when leaving diff-view if the user has pressed the "load more data"-button
        if (prevProps.show && !this.props.show && this.state.offset !== 0) {
            console.log("resetting")
            this.setState({ currentData: null, offset: 0, limit: 100 })
        }

        // resetting if the user has clicked on clickReport and if the back-button was NOT used.
        if (prevProps.show && !this.props.show && this.props.clickReport) {
            this.reset()
        }
    }

    getViewLimit() {
        let limit = this.getLimit()
        //If there's a limit from above, use the smallest of that and the limit created by the "load more" button.
        limit = limit !== -1 ? Math.min(limit, this.state.limit) : this.state.limit
        return limit
    }

    getLimit() {
        //If there's a limit from the matrix, we want to use this. Otherwise use the report limit
        let limit = (this.props.limit && this.props.limit !== -1) || this.props.limit === 0 ? this.props.limit : this.props.reportdata.report.limit
        return limit
    }

    reset() {
        this.setState({ currentData: null, offset: 0, limit: 100 })
        this.props.removeClickSlicer()
    }

    getSortColumn(report, wasUpdated) {
        // The priority of the settings goes as follows when the view is loaded the first time: 
        // - EditMode settings
        // - Default graph setting on bucket
        // - Session settings
        // - Find first number column
        // - Find first column
        // When the view is first loaded 'this.state.selectedGridColumn' will be null and we will enter the first else-statement. If the user selects their own column the session settings will be used.
        let res = ""
        const columns = JSON.parse(report.columns)
        if (this.state.selectedGridColumn !== null && !wasUpdated) {
            res = this.state.selectedGridColumn
        } else {
            const viewSettings = JSON.parse(report.view_settings)
            if (!viewSettings.actual) viewSettings.actual = {}
            const editModeColumn = viewSettings.actual.grid ? viewSettings.actual.grid.selected_grid_column : ""
            const defaultBucketColumn = this.props.reportdata && this.props.reportdata.info ? this.props.reportdata.info.setup.default_graph_column : null
            if (editModeColumn && editModeColumn !== "") {
                res = editModeColumn
            } else if (defaultBucketColumn && defaultBucketColumn !== "" && columns.includes(defaultBucketColumn)) {
                res = defaultBucketColumn
            } else if(columns.length > 0) {
                res = this.findFirstNumberColumn(columns)
                if(!res) {
                    res = columns[0]
                }
            }
        }
        const index = columns.indexOf(res)
        if (index === -1) { // if we have a column that is no longer in the report because of bucket change
            res = this.findFirstNumberColumn(columns)
        } 
        return res
    }

    getDirection(wasUpdated) {
        let res = true
        if (this.state.direction !== null && !wasUpdated) {
            res = this.state.direction
        } else {
            const viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
            if (!viewSettings.actual) viewSettings.actual = {}
            if (viewSettings.actual.grid) {
                res = viewSettings.actual.grid.grid_direction
            }
        }
        return res
    }

    findFirstNumberColumn() {
        const graphColumn = findNumberColumns(this.props.reportdata.info.model, JSON.parse(this.props.reportdata.report.columns))[0]
        return graphColumn 
    }

    setSortColumn(column, direction) {
        this.setState({ selectedGridColumn: 
                        column.name, 
                        selectedGridIndex: 
                        column.index, 
                        direction: direction,
                        shouldSort: true }, 
                        () => {
                            if (this.props.editMode) {
                                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                                if (!viewSettings.actual) viewSettings.actual = {}
                                viewSettings.actual.grid = this.getGridViewSettings()
                                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
                            }
                        }
        )
    }

    setDecimals(bool) {
        this.setState({ showDecimals: bool }, () => {
            if (this.props.editMode) {
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                if (!viewSettings.actual) viewSettings.actual = {}
                viewSettings.actual.grid = this.getGridViewSettings()
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
            }
        })
    }

    setAutoFitArr(arr) {
        this.setState({ autoFitArr: arr }, () => {
            if (this.props.editMode) {
                let viewSettings = JSON.parse(this.props.reportdata.report.view_settings)
                if (!viewSettings.actual) viewSettings.actual = {}
                viewSettings.actual.grid = this.getGridViewSettings()
                this.props.setData("report", "view_settings", JSON.stringify(viewSettings))
            }
        })
    }

    getGridViewSettings() {
        return {
            autoFitArr: this.state.autoFitArr,
            selected_grid_column: this.state.selectedGridColumn,
            grid_direction: this.state.direction,
            show_decimals: this.state.showDecimals
        }
    }

    fetchMoreData = () => {this.setState({
        offset: this.state.offset + 100
      })
    };

    getColumns = () => { //Mapping column name to {name, type, index} object
        if (!this.state.currentData) return []
        let reportColumns = JSON.parse(this.props.reportdata.report.columns)
        return this.state.currentData.columns
                .filter(c => reportColumns.includes(c))
                .map((c, i) => {
                    let d = this.props.reportdata.info.model.columns.find((e) => {
                        return e.name.toLowerCase() === c.toLowerCase()
                    })
                    return { name: c, type: d.type, index: i }
                })
    }

    getIdColumn() {
        let idColumn = getIdColumn(this.props.reportdata.info.model.columns)
        let index = JSON.parse(this.props.reportdata.report.columns).findIndex(c => c === idColumn)
        return { name: idColumn, index: index }
    }

    hideRow(bId, id) {
        this.props.hideRow(bId, id)
        this.dataGrid.current.deselect(id)
    }

    hideSelectedRows() {
        this.props.hideSelectedRows()
        this.dataGrid.current.deselectAll()
    }

    deselectMultiple = ids => {
        if(this.dataGrid.current)
            this.dataGrid.current.deselectMultiple(ids)
    }

    setShownColumns(sc) {
        let columns = []
        sc.forEach((c, i) => {
            columns.push(c.name)
        })
        this.props.setShownColumns(sc)
    }

    itemSelection(ids, itemId) {
        this.props.setSelectedItems(ids, itemId)
    }

    promptDownloadGridData = () => {
        this.downloadDialog.current.show()
    }

    promptDownloadGridDataExcel = () => {
        this.downloadDialogExcel.current.show()
    }

    downloadGridDataExcel = () => {
        this.downloadGridDataCommon('excel')
    }

    downloadGridData = () => {
        this.downloadGridDataCommon('csv')
    }

    downloadGridDataCommon(type) {
        const { reportdata, slicers, dispatch } = this.props;

        const selectedRows = this.dataGrid.current.state.selectedRows;
        let slicer;
        //if any rows are selected, uses this slicer to filter query
        if(selectedRows.length !== 0){
            let idColumn = this.getIdColumn()
            slicer = {
                column: idColumn.name, 
                target_values: selectedRows,
                //1 is compare type Equal
                compare_type: 1
            }
        }

        const sortColumn = this.getSortColumn(reportdata.report, false)
        const direction = this.getDirection(false)

        const shownColumns = this.props.shownColumns.map(c => c.name)
        // the shown columns goes first, all other afterwards:
        //const columns = shownColumns.concat(reportColumns.filter(c => !shownColumns.includes(c)))

        const data = {
            name: reportdata.report.name,
            type: type,
            query: {
                sort_column: reportdata.report.limit > -1 ? reportdata.report.sort_column : sortColumn,
                sort_direction: reportdata.report.limit > -1 ? reportdata.report.sort_direction : (direction ? 'desc' : 'asc'),
                filter: getFilter(reportdata.report, [...slicers, slicer]),
                column_filter: shownColumns,
                offset: 0, 
                limit: this.getLimit(),
            },
            include_comments: true,
        }

        dispatch(downloadReportGridData(reportdata.info.id, data))
    }

    render() {
        if(!this.props.show) return null

        let gridData = this.state.currentData ? this.state.currentData : { data: [], columns: [], total: 0 }
        let bucketId = this.props.reportdata.info.id
        let comments = this.props.comments.get1(this.props.reportdata.info.id)
        let idColumn = this.getIdColumn()
        let idColumnIndex = idColumn.index
        const reportLimit = this.props.reportdata.report.limit

        let getButtonColor = (bool) => {
            if (bool) {
                return 'btn-default active'
            } else {
                return 'btn-default'
            }
        }

        let getLatestComment = id => comments.get(id) && comments.get(id).length > 0 ? {comment: comments.get(id)[0].comment, count: comments.get(id).length} : null

        if (this.state.shouldSort && this.props.show && this.props.shownColumns.length > 0) {
            if (gridData.data && gridData.data.length > 0) {
                sortDataRows(this.state.selectedGridColumn, this.state.selectedGridIndex, this.state.direction, gridData.data, this.props.reportdata)
            }
        }

        let copyReportDataToClipboard = () => {
            copyToClipboard(this.dataGrid.current.getHTML(true, true))
            const rowAmount = this.props.selectedItemsLength === 0 ? gridData.data.length : this.props.selectedItemsLength;
            notifySuccess(rowAmount + ' rows has been copied to the clipboard')
        }

      
        return (
            <div className="col-md-12 no-padding">
                
                <div className="float-right abc-grid-controls">
                    <ShowIf if={this.props.selectedItemsLength > 0}>
                        <button className="btn btn-default btn-sm margin-right-5px" onClick={() => this.hideSelectedRows()}>{this.props.editMode ? "Exclude" : "Hide"} items ({this.props.selectedItemsLength})</button>
                        <button className="btn btn-default btn-sm margin-right-5px" onClick={() => this.dataGrid.current.deselectAll()}>Deselect items ({this.props.selectedItemsLength})</button>
                    </ShowIf>
                    <button title="Wrap text" className={`btn btn-default btn-sm ${this.state.wrapHeaderText ? 'active' : ''}`} onClick={() => this.setState({ wrapHeaderText: !this.state.wrapHeaderText })}><i className="fa fa-expand"></i></button>
                    <button title="Show/hide decimals" className={`btn btn-default btn-sm margin-left-5px ${getButtonColor(this.state.showDecimals)}`} onClick={() => this.setDecimals(!this.state.showDecimals)}>0,0</button>
                    <div className="btn-group inline-block margin-left-5px">
                        <button 
                            title={this.props.selectedItemsLength != 0 ?"Copy " + this.props.selectedItemsLength + " rows to clipboard" : "Copy to clipboard"} 
                            className="btn btn-default btn-sm" 
                            onClick={() => copyReportDataToClipboard()}
                        >
                            <i className="fa fa-copy"></i> {this.props.selectedItemsLength != 0 ? `(${this.props.selectedItemsLength})` : ""}
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <ShowIf if={gridData.total * this.props.shownColumns.length < 1000000}>
                                <li className="abc-click" onClick={this.promptDownloadGridDataExcel}><span>Download data (Excel)</span></li>
                            </ShowIf>
                            <ShowIf if={gridData.total * this.props.shownColumns.length >= 1000000}>
                                <li><span title="Data set is too big"><i>Download data (Excel)</i></span></li>
                            </ShowIf>
                            <li className="abc-click" onClick={this.promptDownloadGridData}><span>Download data (CSV)</span></li>
                        </ul>
                        <button 
                            title={this.props.selectedItemsLength != 0 ?"Download " + this.props.selectedItemsLength + " rows" : "Download"} 
                            type="button" 
                            className="btn btn-default btn-sm" 
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false"
                        >
                            <i className="fa fa-download"></i>
                            <span className="sr-only">Toggle Dropdown</span> {this.props.selectedItemsLength != 0 ? `(${this.props.selectedItemsLength})` : ""}
                        </button>
                        
                    </div>
                    <button title="Edit column layout" className="btn btn-default btn-sm margin-left-5px" onClick={() => this.refs.columnSelect.show(this.props.shownColumns, this.getColumns(), this.props.columnDescriptions)}><i className="fa fa-columns"></i></button>
                </div>

                <ShowIf if={this.props.shownColumns.length === 0}>
                    <div className="alert alert-warning report-view-alert"><i className="fa fa-exclamation-triangle"/> You need at least one column to use this view!</div>
                </ShowIf>


               
                <DataGrid
                    ref={this.dataGrid}
                    id={0}
                    bucketId={bucketId}
                    columns={this.props.shownColumns.slice()}
                    getLatestComment={id => getLatestComment(id)}
                    rows={gridData.data.map(r => { return { data: r, id: r[idColumnIndex] } })}
                    onSort={(col, direction) => this.setSortColumn(col, direction)}
                    defaultSortColumn={this.state.selectedGridColumn}
                    defaultSortDirection={this.state.direction}
                    showDecimals={this.state.showDecimals}
                    onSelect={(ids, itemId) => this.itemSelection(ids, itemId)}
                    autoFitArr={this.state.autoFitArr}
                    setAutoFitArr={(arr) => this.setAutoFitArr(arr)}
                    show={this.props.show}
                    sticky={49}
                    wrapHeaderText={this.state.wrapHeaderText}
                    showOpenButton
                    model={this.props.reportdata.info.model}
                    activeCategoryColumn={this.props.reportdata.info.model.categorization_name}
                    columnDescriptions={this.props.columnDescriptions}
                    editMode={this.props.editMode}
                />
 <InfiniteScroll
          dataLength={gridData.data.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMoreData && reportLimit === -1}
          loader={<span>Loading...</span>}></InfiniteScroll>

                <ColumnSelectionDialog ref="columnSelect" saveHandler={sc => this.setShownColumns(sc)} activeCategoryColumn={this.props.reportdata.info.model.categorization_name} />

                <SaveDialog ref={this.downloadDialog} title="Download data (CSV)" saveText="OK" closeText="Cancel" saveHandler={this.downloadGridData}>
                    Your data is being prepared for download - you will get a notification when it is ready!
                </SaveDialog>
                <SaveDialog ref={this.downloadDialogExcel} title="Download data (Excel)" saveText="OK" closeText="Cancel" saveHandler={this.downloadGridDataExcel}>
                    Your data is being prepared for download - you will get a notification when it is ready!
                </SaveDialog>
            </div>
        )

    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (prevState.direction !== curState.direction ||
            prevState.selectedGridColumn !== curState.selectedGridColumn ||
            prevState.selectedGridIndex !== curState.selectedGridIndex ||
            prevState.limit !== curState.limit ||
            prevState.showDecimals !== curState.showDecimals ||
            prevState.autoFitArr !== curState.autoFitArr ||
            prevState.wrapHeaderText !== curState.wrapHeaderText)
}

const getSettingsKeys = (state) => {
    let { currentData, shouldSort, initialLoad, ...settings } = state
    return Object.keys(settings)
}

const settingsDidApply = (_this, prevSettings, invalidSettings) => {
    if (_this.dataGrid.current) {
        _this.dataGrid.current.setSorting(_this.state.direction, _this.state.selectedGridColumn)
        _this.dataGrid.current.setAutoArr(_this.state.autoFitArr)
    }

    //If the bucket has changed such that the column that we want to sort by has moved
    const column = _this.props.shownColumns.find(c => c.name === _this.state.selectedGridColumn)
    if(column && _this.state.selectedGridIndex !== column.index) {
        _this.setState({selectedGridIndex: column.index})
    }
}

Gridview = withSettingsPropagation(Gridview, getSettingsKeys, shouldComponentUpdateSettings, null, settingsDidApply, "grid")

Gridview = connect(mapStateToProps, null, null, { forwardRef: true })(Gridview)

export default Gridview
