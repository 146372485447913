/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2'
import { formatValue } from '../../helpers/ReportHelpers'
import { getArrowValues, bigWidgetOptions, getTargetColor } from '../../helpers/DashboardHelpers'
import WidgetToolTip from './WidgetToolTip'


const mapStateToProps = (state, ownProps) => {
    return {

    }
}
export class BigWidget extends React.Component {
    getHighlightedColor(id, key) {
        const { hoverReportId, hoverKpiKey } = this.props
        if (id == hoverReportId && hoverKpiKey === key) return 'dashboard-background-selected'

        return 'dashboard-background'
    }

    render() {
        const { indexJ } = this.props
        const options = bigWidgetOptions
        const widget = this.props.data
        if (!widget) return null

        const dataPoints = widget.data_points ? widget.data_points : []
        let labels = []
        dataPoints.forEach((item) => {
            labels.push(item + "")
        })
        const noData = widget.data_points === null ? true : false

        const column = widget.column
        const name = widget.report_name
        const label = widget.label && widget.label !== "" ? widget.label : ""

        let bodyCss = getComputedStyle(document.body);
        let targetLine = bodyCss.getPropertyValue("--tangerine");
        let lineColor = bodyCss.getPropertyValue("--dark-forest");


        const data = (canvas) => {
            // const ctx = canvas.getContext("2d")
            // let _stroke = ctx.stroke;
            // ctx.stroke = function () {
            //     ctx.save()
            //     ctx.shadowColor = '#4096C6'
            //     ctx.shadowBlur = 10
            //     ctx.shadowOffsetX = 0
            //     ctx.shadowOffsetY = 5
            //     _stroke.apply(this, arguments)
            //     ctx.restore()
            // }

            let datasets = []
            datasets.push({
                label: "datapoints",
                data: dataPoints,
                fill: false,
                borderColor: lineColor,
                borderWidth: 4,
                pointRadius: 0,
                pointHitRadius: 0,
            })

            if (widget.found_target) {
                let data = []
                for (let i = 0; i < 12; i++) {
                    data.push(widget.target)
                }
                datasets.push({
                    label: "target",
                    data: data,
                    fill: false,
                    borderColor: targetLine,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHitRadius: 0,
                })
            }

            return {
                labels: labels,
                datasets: datasets,
            }
        }
        const lastVal = widget.last_value || widget.last_value === 0 ? widget.last_value : -1
        const trendObj = getArrowValues(dataPoints[dataPoints.length - 2], lastVal, widget.trend)


        const targetColor = getTargetColor(widget.percent ? widget.percent_value : lastVal, widget.target, widget.target_trend, widget.found_target)
        let formattedVal
        if (widget.percent) {
            formattedVal = lastVal !== -1 ? formatValue(true, widget.percent_value, widget.decimal) + "%" : "N/A"
        } else {
            formattedVal = lastVal !== -1 ? formatValue(true, lastVal, widget.decimal) : "N/A"
        }
        if (noData) formattedVal = "N/A"

        return (
            <div
                className={`${this.props.fromSecret ? "dashboard-large-secret" : "dashboard-large"} inline-block pos-rel ${widget.is_report ? 'abc-click' : ''} ${!this.props.home ? this.getHighlightedColor(widget.row_id != '' ? widget.row_id : widget.report_id, widget.kpi_key) : "dashboard-background"}`}
                onMouseEnter={!this.props.fromHome ? () => this.props.setHoverValues(widget.row_id, widget.kpi_key) : null}
                onMouseLeave={!this.props.fromHome ? () => this.props.setHoverValues("", "") : null}
                title={widget.is_report ? "Go to Insight" : ""}
                data-tip
                data-for={`tooltip-${widget.row_id}-${widget.kpi_key}-${indexJ}-${widget.type}`}
            >
                <WidgetToolTip index={indexJ} value={formattedVal} widget={widget} direction={trendObj.direction} differencePrecedingPlus={""} />
                <div className="height-100px container">
                    <div className="row height-20px font-size-14px padding-right-10px">
                        <div className="col-12 pr-0">
                            <div className="float-right pt-1">
                                <span><b>{widget.found_target && widget.target_trend === -1 ? "max." : widget.found_target && widget.target_trend === 1 ? "min." : ""} {widget.found_target ? formatValue(true, widget.target, widget.decimal) : null}{widget.found_target && widget.percent ? "%" : ""}</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-center height-60px">
                        <div className="col-12">
                            {
                                !noData && widget.trend !== 0 ?
                                    <i className={`${trendObj.direction === -1 ? 'fa fa-arrow-down' : trendObj.direction === 1 ? 'fa fa-arrow-up' : ""} ${trendObj.isPositive ? 'green-arrow' : 'red-arrow'} widget-arrow-size arrow-fix `}></i>
                                    : !noData && widget.trend === 0 ?
                                        <i className={`${trendObj.direction === -1 ? 'fa fa-arrow-down' : trendObj.direction === 1 ? 'fa fa-arrow-up' : ""} widget-arrow-size arrow-fix `}></i>
                                        : null
                            }
                            {
                                !noData ?
                                    <span className="h1-fix">
                                        {formattedVal}
                                    </span>
                                    : <span className="h1-fix">N/A</span>
                            }
                        </div>
                    </div>
                    <div className="row text-align-center height-20px z-index-2 position-relative">
                        <div className="col-12">
                            <span className="h4-fix pre-wrapper">{label !== "" ? `${label}` : `${column} - ${name}`}</span>
                        </div>
                    </div>
                </div>
                <div className="widget-container z-index-1">
                    <Line
                        className="margin-right-10px"
                        height={52}
                        redraw={true}
                        data={data}
                        options={options}
                    />
                    <div className="">
                        <div className={`height-14px target-color ${targetColor}`}></div>
                    </div>
                </div>
            </div>
        )
    }
}

BigWidget = connect(mapStateToProps)(BigWidget)

export default BigWidget
