/* REACT */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { getDownloadInfo, requestDownloadFile } from '../actions/DownloadActions'
import { downloadReportGridData, getGroups } from '../actions/ReportActions'
import { getUserInfo } from '../actions/UserActions'
import BgColor from '../components/Generic/BgColor'
import ShowIf from '../components/Generic/ShowIf'
import { getUsers } from '../actions/SystemActions'
import { Link } from 'react-router-dom'
import { getFilter } from '../helpers/ReportHelpers'
import { notifySuccess } from '../helpers/NotificationManager'
import DataSize from '../components/Generic/DataSize'
import { getAddress } from '../helpers/ApiHelpers'
import { copyToClipboard } from '../helpers/GeneralHelpers'

const mapStateToProps = (state, ownProps) => {
    return {
        buckets: state.Bucket.bucketDetails,
        userInfo: state.User.info,
        downloadInfo: state.Download.info,
        users: state.System.users,
        groups: state.Report.groups,
    }
}

class Download extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            selectedBucketId: null
        }
    }

    componentDidMount() {
        let { dispatch } = this.props
        dispatch(getDownloadInfo())
        dispatch(getUserInfo())
        dispatch(getGroups())
        if(!this.props.users)
            dispatch(getUsers())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
    }

    downloadReportData = report => {
        const data = {
            name: report.name,
            type: 'csv',
            query: {
                sort_column: report.sort_column,
                sort_direction: report.sort_direction,
                filter: getFilter(report, []),
                column_filter: JSON.parse(report.columns),
                offset: 0, 
                limit: report.limit,
            },
            include_comments: false,
        }

        notifySuccess("You will get a notification when your download is ready")

        this.props.dispatch(downloadReportGridData(report.bucket, data))
    }

    downloadFile = file => {
        const { downloadInfo} = this.props
        if(!downloadInfo) return;

        notifySuccess("You will get a notification when your download is ready")
        const bucketIds = Object.keys(downloadInfo.bucket_download_info).sort((a, b) => downloadInfo.bucket_download_info[a].bucket_name > downloadInfo.bucket_download_info[b].bucket_name ? 1 : -1)
        const selectedBucketId = this.state.selectedBucketId ?? (bucketIds.length > 0 ? bucketIds[0] : "")
        this.props.dispatch(requestDownloadFile(selectedBucketId, file.name))
    }

    render() {
        const { downloadInfo, groups, users } = this.props
        if(!downloadInfo) return null
        const reportGroups = groups?.data?.groups ?? []
        reportGroups.push({group_id: 0, name: "Private"})

        const bucketIds = Object.keys(downloadInfo.bucket_download_info).sort((a, b) => downloadInfo.bucket_download_info[a].bucket_name > downloadInfo.bucket_download_info[b].bucket_name ? 1 : -1)
        const selectedBucketId = this.state.selectedBucketId ?? (bucketIds.length > 0 ? bucketIds[0] : "")
        const selectedBucket = downloadInfo.bucket_download_info[selectedBucketId]
        
        const staticLink = selectedBucket ? getAddress(`download/${selectedBucket.static_link_token}`) : ""
        return (
            <BgColor bgClass="background">
                 <div className="container-fluid">
                    <h1 className="mb-0 pt-3">Download</h1>
                    <hr className="full-width-hr hr-color margin-top-5px"/>
                    <div className="width-100p padding-top-20px">
                        Download from &nbsp;
                        <select className="form-control form-control-sm inline-block auto-width" value={selectedBucketId} onChange={e => this.setState({selectedBucketId: e.target.value})}>
                            {
                                bucketIds.map(id => <option key={id} value={id}>{downloadInfo.bucket_download_info[id].bucket_name}</option>)
                            }
                        </select>
                        &nbsp;{selectedBucket ? `Last updated: ${moment.unix(selectedBucket.last_update).fromNow()}` : ""}
                        <div className="compose-navigation margin-top-15px">
                            <div className={`compose-tab ${this.state.tab === 0 ? "active-compose-tab" : ""}`} onClick={() => this.setState({ tab: 0 })}><span>Data</span></div>
                            <ShowIf if={selectedBucket && selectedBucket.static_link_token}>
                                <div className={`compose-tab ${this.state.tab === 1 ? "active-compose-tab" : ""}`} onClick={() => this.setState({ tab: 1 })}><span>Link</span></div>
                            </ShowIf>
                            <div className={`compose-tab ${this.state.tab === 2 ? "active-compose-tab" : ""}`} onClick={() => this.setState({ tab: 2 })}><span>Insights</span></div>
                        </div>
                        <div className="bg-white width-100p height-100-p padding-10px">
                            <ShowIf if={!selectedBucket}>
                                <p className="alert alert-warning">
                                    It seems like you don't have access to any buckets. If you believe this is an error, please contact <a href="mailto:support@inact.io">support@inact.io</a>.
                                </p>
                            </ShowIf>
                            <ShowIf if={selectedBucket && this.state.tab === 0 && (!selectedBucket.downloadable_files || selectedBucket.downloadable_files.length === 0)}>
                                <i>You currently have no files available for download.</i>
                            </ShowIf>
                            <ShowIf if={selectedBucket && this.state.tab === 0 && selectedBucket.downloadable_files && selectedBucket.downloadable_files.length > 0}>
                                <table className="table table-condensed table-bordered table-hover table-striped no-margin">
                                    <tbody>
                                        <tr>
                                            <th>File name</th>
                                            <th>Size</th>
                                            <th>Action</th>
                                        </tr>
                                        {
                                            !selectedBucket || !selectedBucket.downloadable_files ? null : selectedBucket.downloadable_files.map(f => {
                                                return (
                                                    <tr key={f.name}>
                                                        <td>{f.name}</td>
                                                        <td><DataSize size={f.size} /></td>
                                                        <td>
                                                            <i className="fa fa-cloud-download icon-gray abc-click" title="Download file" onClick={() => this.downloadFile(f)}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </ShowIf>
                            <ShowIf if={selectedBucket && this.state.tab === 1}>
                                Embed Inact Now data in all of your systems. <br/><br/>
                                <button className="btn btn-default btn-sm" onClick={() => copyToClipboard(staticLink)}><i className="fa fa-link"/> Copy link</button>&nbsp;{staticLink}
                            </ShowIf>
                            <ShowIf if={selectedBucket && this.state.tab === 2 && (!selectedBucket.reports || selectedBucket.reports.length === 0)}>
                                <i>You currently have no Insights available for download.</i>
                            </ShowIf>
                            <ShowIf if={selectedBucket && this.state.tab === 2 && selectedBucket.reports && selectedBucket.reports.length > 0}>
                                <table className="table table-condensed table-bordered table-hover table-striped no-margin">
                                    <tbody>
                                        <tr>
                                            <th className="width-30"></th>
                                            <th>Insight name</th>
                                            <th>Group</th>
                                            <th>Author</th>
                                            <th>Action</th>
                                        </tr>
                                        {
                                            !selectedBucket || !selectedBucket.reports ? null : selectedBucket.reports.map(r => {
                                                const groupName = reportGroups.find(g => g.group_id === r.group_id)?.name ?? r.group_id
                                                const author = users?.find(u => u.id === r.user_id)
                                                const authorName = author ? `${author.firstname} ${author.lastname}` : r.user_id
                                                return (
                                                    <tr key={r.report_id}>
                                                        <td className="width-30 text-align-center">
                                                            <Link to={`report/${r.report_id}`} className="cancel-a-style">
                                                                <i className="fa fa-external-link icon-gray"/>
                                                            </Link>
                                                        </td>
                                                        <td>{r.name}</td>
                                                        <td>{groupName}</td>
                                                        <td>{authorName}</td>
                                                        <td>
                                                            <i className="fa fa-cloud-download icon-gray abc-click" title="Download report data" onClick={() => this.downloadReportData(r)}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </ShowIf>
                        </div>
                    </div>
                </div>
            </BgColor>
        )
    }
}
        
Download = connect(mapStateToProps)(Download)

export default Download