import React from 'react'
import '../css/singleChecklist.css'
import '../css/misc.css'

class LayoutColorPicker extends React.Component {

    render() {
        return <>
            <div className="dropdown">
                {/* Transition property is set to "none" in order to disable transitions from class "btn" which results in color flicker if enabled */}
                <button tabIndex="-1" style={{ transitionProperty: "none" }} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" className={`btn btn-sm ${this.props.color === 'black' ? 'btn-dark' : this.props.color === 'btn-primary' ? 'btn-old-primary' : this.props.color}`}>&nbsp;&nbsp;&nbsp;</button>
                <ul className="dropdown-menu">
                    <li className="display-table-cell">
                        <div className="btn-old-primary abc-click color-box-settings" onClick={() => { if (this.props.onColorChange) this.props.onColorChange('btn-primary') }}></div>
                    </li>
                    <li className="display-table-cell">
                        <div className="btn-success abc-click color-box-settings" onClick={() => { if (this.props.onColorChange) this.props.onColorChange('btn-success') }}></div>
                    </li>
                    <li className="display-table-cell">
                        <div className="btn-info abc-click color-box-settings" onClick={() => { if (this.props.onColorChange) this.props.onColorChange('btn-info') }}></div>
                    </li>
                    <li className="display-table-cell">
                        <div className="btn-warning abc-click color-box-settings" onClick={() => { if (this.props.onColorChange) this.props.onColorChange('btn-warning') }}></div>
                    </li>
                    <li className="display-table-cell">
                        <div className="btn-danger abc-click color-box-settings" onClick={() => { if (this.props.onColorChange) this.props.onColorChange('btn-danger') }}></div>
                    </li>
                    <li className="display-table-cell">
                        <div className="btn-dark abc-click color-box-settings" onClick={() => { if (this.props.onColorChange) this.props.onColorChange('black') }}></div>
                    </li>
                </ul>
            </div>
        </>
    }
}

export default LayoutColorPicker