import React, { Component } from 'react'
import { connect } from 'react-redux'
import Protector from './components/Protector'
import NavigationBar from './components/NavigationBar'
import { Route, Switch, Redirect } from 'react-router-dom'
import ChecklistStatus from './views/ChecklistStatus'
import Home from './views/Home'
import User from './views/User'
import Report from './views/Report'
import SingleChecklist from './views/SingleChecklist'
import SplitChecklist from './views/SplitChecklist'
import ManageLayouts from './views/ManageLayouts'
import Paste from './views/Paste'
import NewReport from './views/NewReport'
import ErrorBoundary from './components/Generic/ErrorBoundary'
import Notifier from './components/Notifications/Notifier'
import { setInstanceRef } from './helpers/NotificationManager'
import UpdateData from './components/updatedata/UpdateData'
import Notifications from './views/Notifications'
import Download from './views/Download'
import CreateUser from './views/CreateUser'
import { reportHashMatchPath, reportMatchPath, checklistMatchPath } from './helpers/PathHelpers'
import SwitchWrapper from './SwitchWrapper'
import SupportSideBar from './components/SideBar/SupportSideBar'
import { setMasterInstanceRef } from './helpers/MasterDataManager'
import MasterDataDialog from './components/Dialogs/MasterDataDialog'
import { setNpsRef } from './helpers/NpsManager'
import NpsDialog from './components/Dialogs/NpsDialog'
import { setDiffViewRef } from './helpers/DiffViewManager'
import DiffViewDialog from './components/Dialogs/DiffViewDialog'
import Offline from './views/Offline'
import Library2 from './views/Library2'
import Library3 from './views/Library3'
import OrganizationAdministration from './components/OrganizationAdministration/OrganizationAdministration'
import DashboardAdmin from './views/DashboardAdmin'
import DashboardOverview2 from './views/DashboardOverview2'
import Dashboard2 from './views/Dashboard2'

import './css/common.css'

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.User.info,
        errors: state.System.errors
	}
}

class App extends Component {
	masterDataRef = React.createRef()
	notificationsRef = React.createRef()
	npsRef = React.createRef()
	diffViewRef = React.createRef()
	supportSideBarRef = React.createRef()
	
	componentDidMount() {
		setInstanceRef(this.notificationsRef)
		setMasterInstanceRef(this.masterDataRef)
		setNpsRef(this.npsRef)
		setDiffViewRef(this.diffViewRef)

		// Gør at når flere modals overlapper hinanden, så overlapper deres backdrop også korrekt.
		// $(document).on('show.bs.modal', '.modal', function() {
		// 	const zIndex = 1040 + 10 * $('.modal:visible').length;
		// 	//$(this).css('z-index', zIndex);
		// 	setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
		// });
	}

	render() {
		return (
			<React.Fragment>
				<Notifier ref={this.notificationsRef}/>
				<Protector>
					<ErrorBoundary users={this.props.users} errors={this.props.errors}>
						<MasterDataDialog ref={this.masterDataRef} />
						<NpsDialog ref={this.npsRef} />
						<DiffViewDialog ref={this.diffViewRef} />
						<NavigationBar sidebar={this.supportSideBarRef.current} />
						<SwitchWrapper>
							<SupportSideBar ref={this.supportSideBarRef}/>
							<Switch>
								<Redirect from="/checklists/paste" to="/paste" />
								<Route path="/user" component={User} />
								<Route path="/status/checklists" component={ChecklistStatus} />
								<Route path="/dashboardadmin/:dID" component={DashboardAdmin} />
								<Route path="/dashboardadmin" component={DashboardAdmin} />
								<Route path="/dashboard/:dID" component={Dashboard2} />
								<Route path="/dashboard" component={DashboardOverview2} />
								<Route path="/library" component={Library3} />
								<Route path="/libraryold2" component={Library2} />
								<Route path="/download" component={Download} />
								<Route path="/paste" component={Paste} />
								<Route path="/newreport" component={NewReport} />
								<Route path="/dataupdate" component={UpdateData} />
								<Route path={reportHashMatchPath} component={Report} />
								<Route path={reportMatchPath} component={Report} />
								<Redirect from="/report/:repId" to="/report/:repId/overview" />
								<Route path="/split/:checklistID" component={SplitChecklist} />
								<Route path={checklistMatchPath} component={SingleChecklist} />
								<Route path={"/administrate/organization"} component={OrganizationAdministration}/>
								<Route path="/notifications" component={Notifications} />
								<Route path="/create_user" component={CreateUser} />
								<Route path="/layouts" component={ManageLayouts} />
								<Route path="/offline" component={Offline} />
								<Route path="/" component={Home} />
								
							</Switch>

							<div id="for-dynamic-cell-width-measurement"/>
						</SwitchWrapper>
					</ErrorBoundary>
				</Protector>
			</React.Fragment>
		)
	}
}

App = connect(mapStateToProps)(App)

export default App
